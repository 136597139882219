import cancelButton from '../assets/images/cancel-x.png';
import styled from "styled-components";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";

import myNEARWalletIcon from '../assets/images/my-near-wallet-icon.png';
import senderWalletIcon from '../assets/images/sender-icon.png';
import { login } from '../assets/near/utils';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/GlobalProvider';

const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px; 
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-title-wrap {
        .title {
          color: #FFF;
          font-family: Open Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 155%;
        }
      }
      
      .cancel-icon {
        width: 24px;
        height: 24px;
      }
    }

    .selector-wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 24px;

      .wallet-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .wallet-image {
          width: 64px;
          height: 64px;
        }

        .wallet-name {
          color: #FFF;
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 155%;
          margin-top: 8px;
        }
      }
    }
    

    .modal-footer {
      justify-content: center;
    }
  `;


export default function WalletSelector({handleClose}) {
  const [senderWalletEnabled, setSenderWalletEnabled] = useState(false);

  const {openWalletSelector} = useGlobalContext();

  useEffect(() => {
    console.log('window.sender', window.sender);
    if (typeof window.sender !== 'undefined' && window.sender.near) {
      setSenderWalletEnabled(true);
    }
  }, []);

  const selectWallet = (wallet) => {
    localStorage.setItem('wallet-selector', wallet);
    login(wallet);
  }

  return (
    <Dialog open={openWalletSelector} show={handleClose} className="bg-transparent shadow-none" size="xs" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="modal-title-wrap">
            <p className="title">Login</p>
            <p className="subtitle">Select a wallet</p>
          </div>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="selector-wrap">
            <div className="wallet-wrap" onClick={() => selectWallet('mynear')}>
              <img className="wallet-image" src={myNEARWalletIcon} alt="my near wallet" />
              <p className="wallet-name">My NEAR wallet</p>
            </div>
            {
              senderWalletEnabled &&
              <div className="wallet-wrap" onClick={() => selectWallet('sender')}>
                <img className="wallet-image" src={senderWalletIcon} alt="sender wallet" />
                <p className="wallet-name">Sender wallet</p>
              </div>
            }
          </div>
          <button className="btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
        </DialogBody>
      </ModalWrapper>
    </Dialog>
  )
}