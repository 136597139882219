import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useState } from "react";
import { peerConfig } from "../inc/constants";
import styled from "styled-components";
import { io } from "socket.io-client";
import cancelButton from '../assets/images/cancel-x.png';
import { useUserFilesContext } from "../context/UserFilesProvider";
import { useAnonymousTransferFileContext } from "../context/AnonymousTransferFileProvider";

const ModalWrapper = styled.div`
  background: var(--BG_8, #283134);
  box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
  border-radius: 32px;
  padding: 24px; 
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

      .title {
        color: #FFF;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }
      .cancel-icon {
        width: 24px;
        height: 24px;
      }
  }

  .input-label {
    color: #F1F3F5;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; 
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    background: #FFF;
    gap: 8px;
  }

  .subtitle {
    color: #FFF;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .status-message {
    color: var(--Red_3, #FA9C9C);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-top: 8px;
  }

  .message {
    color: #30BD5C;
    margin-bottom: 24px;
  }

  .modal-footer {
    justify-content: center;
  }
`;


export default function AnonymousEmailModal({handleClose, fileHash}) {
  const [emailValue, setEmailValue] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const { peerInstanceReceiver } = useUserFilesContext();

  const { setAnonymousFileCid, setAnonymousEmail, setAnonymousFile, setSocket } = useAnonymousTransferFileContext();


  const submitEmail = () => {
    setStatusMessage('');
    console.log('submit email', emailValue);
    if(String(emailValue).length === 64 || emailValue.includes('.testnet') || emailValue.includes('.near') || String(emailValue).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      console.log('valid email');
      setStatusMessage('Checking file access...');
      fetch(process.env.REACT_APP_SERVER_URL + '/users/check-file-encryption',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({hash: fileHash, email: emailValue})
      }).then((res) => {
        return res.json();
      }).then((res) => {
        console.log(res);
        //If file owner is online, init socket and P2P connection
        if(res.ownerOnline) {
          const initSocket = io.connect(process.env.REACT_APP_SERVER_SOCKET_URL, {
            path: process.env.REACT_APP_SOCKET_PATH,
            transports: ["websocket"],
            removeTrailingSlashes: true,
            query: {
              token: res.jwt,
              isAnonymous: true,
            }
          });
          localStorage.setItem('anyonmousToken', res.jwt);
          localStorage.setItem('anonymousEmail', emailValue);

          initSocket.on("exception", (err) => {
            console.log('SOCKET ERROR: ', err); // prints the message associated with the error
          });

          initSocket.on("connect", () => {
            console.log('socket connected', initSocket.id);
            const peer = new window.SimplePeer({
              initiator: false,
              trickle: false,
              config: peerConfig
            });
            peerInstanceReceiver.current[res.fileInfo.cid] = peer;
            console.log('init_download anonymous', {fileId: res.fileInfo.cid, username: emailValue, isPdf: false, socketId: initSocket.id});

            let progress = {};
            progress['user'] = res.owner;
            progress['percentage'] = 0;
            progress['downloading'] = true;
            progress['verified'] = false;
            progress['fileName'] = res.fileName;
            progress['fileId'] = res.fileInfo.cid;

            setAnonymousFile([progress]);

            // setOpenDownloadModal(true);
            // setAnimateFlip(true);
            initSocket.emit('init_download', {fileId: res.fileInfo.cid, username: emailValue, isPdf: false, socketId: initSocket.id});
            handleClose();
          });
          setSocket(initSocket);
        }else {
          if(res.decryptedFileInfo) {
            setAnonymousFileCid(res.decryptedFileInfo)
            setAnonymousEmail({email: emailValue, owner: res.owner});
            handleClose();
            // setOpenDownloadModal(true);
            // setAnimateFlip(true);
          }else {
            setStatusMessage('You do not have access to this file or file doesn\'t exist');
          }
        }
      });
    } else {
      setStatusMessage('Invalid email address or wallet');
    }
  }

  const submitValue = (e) => {
    if(e.key === 'Enter') {
      submitEmail();
    }
  }

  
  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="md" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <p className="title">Enter you email or wallet address:</p>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="body-wrap">
            <div className="content-wrap">
              <p className="input-label">Enter you email or wallet address:</p>
              <input type="text" className="email-input" value={emailValue} onKeyDown={(e) => submitValue(e)} onChange={(e) => setEmailValue(e.target.value)}  />
            </div>
            <p className="status-message">{statusMessage}</p>
          </div>
        </DialogBody>
        <DialogFooter>
          <button className="btn-primary" onClick={() => submitEmail() }>Continue</button>
        </DialogFooter>
      </ModalWrapper>
    </Dialog>
  )
}