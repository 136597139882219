import styled from "styled-components";
import currentDownloads from "../assets/images/current-download.png";
import checkFile from "../assets/images/check-file.png";
import history from "../assets/images/history.png";
import inbox from "../assets/images/inbox.png";
import help from "../assets/images/help.png";
import currentTransfersImg from "../assets/images/current-transfer-icon.png";
import uploads from "../assets/images/uploads.png";
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { useEffect, useState } from "react";

import composeBtn from "../assets/images/compose.png";
import drafts from "../assets/images/drafts.png";
import { useUserFilesContext } from "../context/UserFilesProvider";
import { useGlobalContext } from "../context/GlobalProvider";
import { db } from "../inc/database";
import { dbEmails } from "../mails/utility/Utility";

const SidebarWrapper = styled.div`
  padding-top: 40px;
  width: 100%;

  .main-navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-right: 15px;

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5px;
      cursor: pointer;
      padding: 0px 10px 0px 24px;
      min-height: 68px;

      &.active {
        border-radius: 8px;
        background: var(--BG_8, #283134);
        
      }

      .nav-icon {
        width: 35px;
        height: 35px;
        margin-right: 20px;
      }

      .link-text {
        color: rgba(255, 255, 255, 0.80);
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
      }
    }
  }

  .navigation-accordion {
    display: flex;
    justify-content: flex-start;
    border-bottom: 0;
    flex-direction: column;
    width: 100%;

    .inbox-wrap {
      min-height: 68px;
      display: flex;
      align-items: center;
    }
  }

  .sub-navigation {
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      padding: 10px 10px 10px 0;
      padding-left: 24px;

      li {
        color: rgba(255, 255, 255, 0.80);
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
      }
    }


  }
`;

export default function Sidebar({ currentActivePage, setCurrentActivePage}) {

  const [open, setOpen] = useState(0);
  const { currentTransfers } = useUserFilesContext();
  const [currentTransfersCount, setCurrentTransfersCount] = useState(0);
  const { newEmailCount, setNewEmailCount } = useGlobalContext();

  useEffect(() => {
    if(currentTransfers) {
      setCurrentTransfersCount(currentTransfers.length);
    }
  }, [currentTransfers]);

  useEffect(() => {
    console.log('newEmailCount', newEmailCount);
  }, [newEmailCount]);
 
  const handleOpenNav = (value) => {
    setCurrentActivePage('inbox');
    setOpen(open === value ? 0 : value)
  };

  useEffect(() => {
    window.emailContract.get_recieved_emails({account_id: window.accountId, pagination_params: {page_size: 100000, page_number: 1}}).then(async (emailThreadsBC) => {
       //Get read emails from indexedDB
      const readEmails = await dbEmails.readEmails.toArray();
      let count = 0;
      //Set new email count
      emailThreadsBC.threads.map((thread) => {
        const matchingItem = readEmails.find(item => item.cid === thread.email && item.owner === window.accountId);
        if(matchingItem) {
          count++;
        }
      });

      setNewEmailCount(emailThreadsBC.total - count);
    });
  }, []);

  const switchPage = (page) => {
    const checkbox = document.querySelector(".menu-checkbox");
    checkbox.checked = !checkbox.checked;

    setCurrentActivePage(page);
  }

  return (
    <SidebarWrapper className="">
      <ul className="main-navigation">
        <li className={currentActivePage === 'current-transfers' ? "nav-item active" : "nav-item"} onClick={() => switchPage('current-transfers')} >
          <img className="nav-icon" src={currentTransfersImg} alt="current-transfers" />
          <p className="link-text">Current transfers</p>
          {
            currentTransfersCount !== 0 && (
              <p className="notification-bubble">{currentTransfersCount}</p>
            )
          }
        </li>
        <li className={currentActivePage === 'uploads' ? "nav-item active" : "nav-item"} onClick={() => switchPage('uploads')} >
          <img className="nav-icon" src={uploads} alt="upload" />
          <p className="link-text">Uploads</p>
        </li>
        <li className={currentActivePage === 'downloads' ? "nav-item active" : "nav-item"} onClick={() => switchPage('downloads')} >
          <img className="nav-icon" src={currentDownloads} alt="download" />
          <p className="link-text">Downloads</p>
        </li>
        <li className={currentActivePage === 'check-file' ? "nav-item active" : "nav-item"} onClick={() => switchPage('check-file')} >
          <img className="nav-icon" src={checkFile} alt="check-file" />
          <p className="link-text">Check File</p>
        </li>
        <li className={currentActivePage === 'history' ? "nav-item active" : "nav-item"} onClick={() => switchPage('history')} >
          <img className="nav-icon" src={history} alt="history" />
          <p className="link-text">History</p>
        </li>
        <li className={currentActivePage === 'inbox' || currentActivePage === 'drafts' || currentActivePage === 'compose' ? "nav-item active" : "nav-item"} >
        {/* <Accordion open={open === 1} >
          <AccordionHeader onClick={() => handleOpenNav(1)} className="navigation-accordion">
            <img className="nav-icon" src={inbox} alt="inbox" />
            <p className="link-text">Inbox</p>
            {
              newEmailCount !== 0 && (
                <p className="notification-bubble">{newEmailCount}</p>
              )
            }
          </AccordionHeader>
          <AccordionBody>
            <ul className="sub-navigation">
              <li onClick={() => setCurrentActivePage('compose')}>
                <img className="nav-icon" src={composeBtn} alt="compose" />
                <p className="link-text">Compose</p>
              </li>
              <li onClick={() => setCurrentActivePage('drafts')}>
              <img className="nav-icon" src={drafts} alt="drafts" />
              <p className="link-text">Drafts</p>
              </li>
            </ul>
          </AccordionBody>
        </Accordion> */}

          <div className="navigation-accordion">
            <div className="inbox-wrap" onClick={() =>  setCurrentActivePage('inbox')}>
              <img className="nav-icon" src={inbox} alt="inbox" />
              <p className="link-text">Inbox</p>
              {
                newEmailCount !== 0 && (
                  <p className="notification-bubble">{newEmailCount}</p>
                )
              }
            </div>
            <ul className="sub-navigation">
              <li onClick={() => setCurrentActivePage('compose')}>
                <img className="nav-icon" src={composeBtn} alt="compose" />
                <p className="link-text">Compose</p>
              </li>
              <li onClick={() => setCurrentActivePage('drafts')}>
              <img className="nav-icon" src={drafts} alt="drafts" />
              <p className="link-text">Drafts</p>
              </li>
            </ul>
          </div>
        </li>
        {/* <li className={currentActivePage === 'help' ? "nav-item active" : "nav-item"} onClick={() => setCurrentActivePage('help')} >
          <img className="nav-icon" src={help} alt="help" />
          <p className="link-text">Help</p>
        </li> */}
      </ul>
    </SidebarWrapper>
  );
}