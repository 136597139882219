import React, { useEffect, useRef, useState } from 'react';
import ExcelViewer from 'excel-viewer';

import { Dialog, DialogBody } from '@material-tailwind/react';
import cancelButton from '../../assets/images/cancel-x.png';
import { blurPage, disablePrint, disableRightClick, toBlur, toClear } from '../../inc/viewerFunctions';
import styled from 'styled-components';


const ViewerWrapper = styled.div`

  .viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--BG_8, #283134);
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #FFF;

    .close-btn {
      width: 24px;
      height: 24px;
    }
  }

  .react-pdf__Document {
    max-height: 700px;
    overflow: auto;
  }

  #image-img {
    max-width: 100%;
    max-height: 100%;
  }

  .excel-viewer {
    overflow-x: auto;
    max-height: 80vh;
  }

  .x-spreadsheet-toolbar {
    display: none !important;
  }

  .x-spreadsheet-selector {
    display: none !important;
  }

  .x-spreadsheet-menu>li .x-spreadsheet-icon {
    display: none !important;
  }
  .x-spreadsheet-contextmenu {
    display: none !important;
  }

  .x-spreadsheet-sheet {
    max-width: 100% !important;
    overflow-x: auto !important;
  }
`;

export default function ExcelFileViewer({file, handleClose}) {
  const excelRef = useRef(null);
  const [fileTitle, setFileTitle] = useState('');

  useEffect(() => {
    disableRightClick();
    disablePrint();
    blurPage();
    console.log('REF', excelRef.current);
    setFileTitle(file.file.name);
    showExcel(file.file);
    
  }, [excelRef]);


  const showExcel = async (file) => {
    console.log('REF', excelRef.current);
    new ExcelViewer('#excel-view', await file.arrayBuffer(), {theme: "light", themeBtn: false});
  }

  return (
    <Dialog open={true} size="lg" className="bg-transparent shadow-none file-viewer" onMouseEnter={() => toClear()} onMouseLeave={() => toBlur()}>
      <ViewerWrapper>
        <DialogBody>
          <div className="viewer-header">
            <p className="file-title">{fileTitle}</p>
            <button className="close-btn" onClick={() => handleClose()} >
              <img className="cancel-icon" src={cancelButton} alt="cancel button" />
            </button>
          </div>
          <div id="excel-view" className="excel-viewer" ref={excelRef} />
        </DialogBody>
      </ViewerWrapper>
    </Dialog>
  );
}