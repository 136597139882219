import React, { useEffect, useState } from "react";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { db } from "../inc/database";
import styled from "styled-components";

import cancelButton from '../assets/images/cancel-x.png';

const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px; 
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        color: #FFF;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }
      .cancel-icon {
        width: 24px;
        height: 24px;
      }
    }
    .subtitle {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }

    .modal-footer {
      justify-content: center;
    }

    .file-id {
      color: #FFF;
      font-weight: 500;
      background: var(--BG_8, #283134);
      border: 1px solid #06C5C5;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
    }
  `;
  
export default function GenerateShareLinkModal({handleClose, fileId}) {

  const [fileHash, setFileHash] = useState('');
  const [hash, setHash] = useState('');

  const [emailRestriction, setEmailRestriction] = useState(false);
  const [addededEmails, setAddededEmails] = useState([]);
  const [emailValue, setEmailValue] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    generateShareHash().then((fileHash) => {
      setFileHash(fileHash.substring(0, 20) + '...');
      setHash(fileHash);
    });

  }, []);

  const generateShareHash = async () => {
    const transferFile = await db.files.where({fileId: fileId}).first();
    console.log('transferFile', transferFile);
    const data = {
      cid: transferFile.cid_public,
      key: transferFile.key,
      nonce: transferFile.nonce
    };
    const url = await fetch(process.env.REACT_APP_SERVER_URL + '/users/hash-file',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
        },
        body: JSON.stringify(data)
      }
    ).then((res) => {
      return res.json();
    }).then((res) => {
      console.log(res);
      return process.env.REACT_APP_SHARE_URL + '/' + encodeURIComponent(res.hash);
    });
    
    console.log(url);
    return url;
  };

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  //COPY FILE ID TO CLIPBOARD
  const handleCopyClick = async (appType = 'desktop') => {
    //TODO CHECK IF NEEDED TO GET URL
    // let copyText = '';
    // if(appType === 'desktop') {
    //   copyText = fileId;
    // } else {
    //   copyText = process.env.REACT_APP_SHARE_URL + '?fileId=' + fileId;
    // }
    
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(hash)
      .then(() => {
        // If successful, update the isCopied state value
        handleClose()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEnter = (e) => {
    if(e.key === 'Enter') {
      setAddededEmails([...addededEmails, e.target.value]);
      setEmailValue('');
    }
  }

  const generateEmailLink = () => {
    if(addededEmails.length === 0) {
      setStatusMessage('Please add at least one email');
    }
    // if(addededEmails.length > 0) {
    //   const emailString = addededEmails.join(',');
    //   const url = process.env.REACT_APP_SHARE_URL + '?cid=' + encodeURIComponent(fileHash) + '&key=' + encodeURIComponent(hash) + '&nonce=' + encodeURIComponent(emailString);
    //   console.log(url);
    // }
  }

  

  return (
    <Dialog open={true} handler={handleClose} className="bg-transparent shadow-none" size="xs">
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <p className="title"> Generate share Link</p>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="body-wrap">
          <p className="subtitle">Create a unique link for your file and share it with users outside the Pathfinder ecosystem.</p>
            {/* {
              !emailRestriction &&
                <React.Fragment>
                  <div className="content-wrap">
                    <p className="file-id">Generate share Link:</p>
                    <button variant="secondary" className="copy-btn" onClick={() => handleCopyClick() }>GENERATE</Button>
                  </div>
                </React.Fragment>
            } */}
            <div className="content-wrap">
              <input type="text" className="file-id" value={hash} readOnly/>
            </div>
            {
              emailRestriction &&
                <div className="email-access-wrap">
                  <div className="added-emails-wrap">
                    <p className="added-emails-title">Currently added emails:</p>
                    {
                      addededEmails.length > 0 ?
                        addededEmails.map((email, index) => {
                          return (
                            <div className="added-email-wrap">
                              <p className="email">{email}</p>
                              <p className="remove-email">Remove</p>
                            </div>
                          );
                        })
                      :
                        <p className="no-emails">No emails added</p>
                    }
                  </div>
                  <p className="email-title">Add emails:</p>
                  <div className="email-input-wrap">
                    <input type="text" className="emails-input" value={emailValue} onKeyDown={(e) => handleEnter(e)} onChange={(e) => setEmailValue(e.target.value)}/>
                    <button variant="secondary" className="copy-btn" onClick={() => {
                      setAddededEmails([...addededEmails, emailValue ]) 
                      setEmailValue('');
                    }} >ADD EMAIL</button>
                  </div>
                    <button variant="secondary" className="copy-btn" onClick={() => generateEmailLink() }>GENERATE LINK</button>
                    <p className="status-message">{statusMessage}</p>
                </div>
            }
          </div>
        </DialogBody>
        <DialogFooter className="modal-footer">
          <button  className="btn-primary" onClick={() => handleCopyClick()}>
            Copy link to clipboard
          </button>
        </DialogFooter>
      </ModalWrapper>
    </Dialog>
  )
}