import styled from "styled-components";

import leftBackground from "../../assets/images/left-background.png";
import rightBackground from "../../assets/images/right-background.png";


const InfoSectionDiv = styled.div`

background: #042C25;
padding: 123px 0;
position: relative;

@media (max-width: 768px) {
  display: none;
}

.background-info-img {
  position: absolute;
  width: auto;
  max-height: 200px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &.right {
    right: 0;
    left: auto;
  }
}
.info-content {
  position: relative;
  z-index: 2;

  .info-title {
    color: #FFF;
    text-align: center;
    font-family: Nexa;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }

  .info-content {
    color: #FFF;
    text-align: center;
    font-family: Nexa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    padding: 0 10px;
    margin-top: 24px;
  }
}
`;

export default function InfoSection() {

  return (
    <InfoSectionDiv>
      <img src={leftBackground} alt="Path pattern" className="background-info-img" />
      <div className="container mx-auto grid grid-cols-12 gap-3">
        <div className="col-span-8 col-start-3 row-start-1 info-content">
          <h2 className="info-title">Secure, Decentralized, and Empowering file sharing</h2>
          <p className="info-content">
            Pathfinder enables you <b>the secure way to send your files</b> while remaining in <b>full control</b> and <b>ownership</b> over your them. Built on the powerful NEAR protocol blockchain, it gives you <b>free, encrypted</b> and <b>simple</b> peer-to-peer based file sharing. 
          </p>
        </div>
      </div>
      <img src={rightBackground} alt="Path pattern" className="background-info-img right" />
    </InfoSectionDiv>
  );
}