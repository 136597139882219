import React, { useEffect, useRef } from 'react';
import * as modelPlayer from 'js-3d-model-viewer';
import { Dialog, DialogBody } from '@material-tailwind/react';
import cancelButton from '../../assets/images/cancel-x.png';
import styled from 'styled-components';

const ViewerWrapper = styled.div`

  .viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--BG_8, #283134);
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #FFF;

    .close-btn {
      width: 24px;
      height: 24px;
    }
  }

  .react-pdf__Document {
    max-height: 700px;
    overflow: auto;
  }
`;

export default function ModelViewer({file, handleClose}) {
  const modelRef = useRef(null);
  const opts = {
    grid: false,
    trackball: false
  }

  useEffect(() => {
    if(modelRef.current) {
      showModel(file.file);
    }

    return () => {
      modelPlayer.clearScene(modelRef.current);
    }
    
  }, [modelRef]);


  const showModel = async (file) => {
    
    const fileExtension = file.name.split('.').pop();
    const scene = modelPlayer.prepareScene(modelRef.current, opts);
    if(fileExtension === 'obj') {
      modelPlayer.loadObject(scene, URL.createObjectURL(file));
    } else if(fileExtension === 'glb') {
      modelPlayer.loadGlb(scene, URL.createObjectURL(file));
    }
  }

  return (
    <Dialog open={true} size="lg" className="bg-transparent shadow-none file-viewer">
      <ViewerWrapper>
        <DialogBody>
          <div className="viewer-header">
            <p className="file-title">{file.file.name}</p>
            <button className="close-btn" onClick={() => handleClose()} >
              <img className="cancel-icon" src={cancelButton} alt="cancel button" />
            </button>
          </div>
          <div id="model-viewer" ref={modelRef} />
        </DialogBody>
      </ViewerWrapper>
    </Dialog>
  );
}