import styled from "styled-components";
import { Fragment, useState } from "react";
import { PDFDocument, PDFName } from "pdf-lib";
import Dropzone, { useDropzone } from "react-dropzone";
import uploadIcon from "../../assets/images/upload-icon.png";

const CheckFileWrap = styled.div`

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .title {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 32px;
  }

  .subtitle {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%; /* 18.6px */
    margin-bottom: 40px;
  }

  .dropzone {
    display: flex;
    padding: 48px 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px dashed #4C585F;
    cursor: pointer;
    margin-bottom: 58px;
  }

  .text-wrapper {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #353C40;
    margin-bottom: 24px;
    min-width: 355px;
    min-height: 145px;

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  .upload-icon {
    margin-bottom: 8px;
    width: 30px;
    height: 30px;
  }

  .owner-info {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 16px;
  }

  .recipient-info {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
    margin-bottom: 20px;
    font-style: italic;
    
    span {
      font-weight: 700;
      font-style: normal;
    }
    
  }

  .input-text {
    text-align: center;
    color: #FFF;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    margin-bottom: 8px;

    b {
      font-weight: 700;
    }
  }
`;

export default function CheckFile() {
  const [fileName, setFileName] = useState(null);
  const [fileOwner, setFileOwner] = useState(null);
  const [fileRecipient, setFileRecipient] = useState(null);
  const [file, setFile] = useState(null);

  const checkPdf = async (pdfFile) => {
    const pdfBuffer = await pdfFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBuffer, { 
      updateMetadata: false 
    })

    const fileUser = pdfDoc['getInfoDict']().lookup(PDFName.of('pathFinderMetadataUser'));
    if(!fileUser) {
      setFileRecipient('User not found or file wasn\'t tracked Pathfinder');
      setFileOwner('User not found');
      return;
    }

    const fileUserName = fileUser.decodeText();
    console.log('fileUserName', fileUserName);
    getUserWallet(fileUserName);
    
    const fileOwner = pdfDoc['getInfoDict']().lookup(PDFName.of('pathFinderMetadataOwner'));
    const fileOwnerName = fileOwner.decodeText();
    console.log('fileOwnerName', fileOwnerName);
    const owner = fileOwnerName.length >= 32 ? fileOwnerName.slice(0,6) + '...' + fileOwnerName.slice(-4) : fileOwnerName;
    setFileOwner(owner);
  }

  const onFileDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    setFile(acceptedFiles[0])
    setFileName(acceptedFiles[0].name);
  }

  const uploadFile = () => {
    console.log('file', file);
    if(file.type === 'application/pdf') {
      checkPdf(file);
    }else {
      setFileRecipient('User not found or file wasn\'t tracked Pathfinder');
      setFileOwner('User not found');
      return;
    }
  }

  const getUserWallet = async (userPublicKey) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/users/get-wallet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
      },
      body: JSON.stringify({publicKey: userPublicKey})
    })
    .then(res => res.json())
    .then(data => {
      console.log('data', data);
      if(data.username) {
        const username = data.username.length >= 32 ? data.username.slice(0,6) + '...' + data.username.slice(-4) : data.username;
        setFileRecipient(username);
      }else {
        setFileRecipient('User not found');
      }
    });
  };

  return (
    <CheckFileWrap>
      <h2 className="title row-start-1">Check File Ownership</h2>
      <p className="subtitle">Verify ownership and check file recipient of files shared through Pathfinder.</p>
      <section className="dropzone">
        <Dropzone onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="text-wrapper">
                <input {...getInputProps()} />
                <img className="upload-icon" src={uploadIcon} alt="Upload icon" />
                {
                  fileName ?
                  <p className="input-text"><b>{fileName}</b></p>
                  :
                  <Fragment>
                    <p className="input-text"><b>Upload file</b></p>
                    <p className="input-text">Drag'n'drop file here or click to upload.</p>
                  </Fragment>
                }
              </div>
          )}
        </Dropzone>
        {
          fileName &&
            <button className="btn-primary" onClick={() => uploadFile()}>Upload</button>
        }
        
      </section>
      <div className="checkfile-info">
        <p className="owner-info"><span>File name:</span> {fileName}</p>
        <p className="recipient-info" style={fileOwner ? {fontStyle: "normal"} : {fontStyle: 'italic'}}><span>File owner:</span> {fileOwner ? fileOwner : 'Here you will see wallet of file owner'}</p>
        <p className="recipient-info" style={fileRecipient ? {fontStyle: "normal"} : {fontStyle: 'italic'}}><span>File recipient:</span> {fileRecipient ? fileRecipient : 'Here you will see recipient of file'}</p>
      </div>
    </CheckFileWrap>
  )
}