import styled from "styled-components";
import AnonymousDownload from "../components/AnonymousDownload";
import InfoContent from "../components/InfoContent";
import AnonymousTransferFileProvider from "../context/AnonymousTransferFileProvider";

import heroBackground from "../assets/images/hero-background.png";

import Navbar from "../layout/Navbar";

import Footer from "../components/landing/Footer";
import BookADemoWidget from "../components/BookADemoWidget";
import BookADemo from "../components/landing/BookADemo";
import Choice from "../components/landing/Choice";
import Infographic from "../components/landing/Infographic";
import InfoSection from "../components/landing/Info";

const LandingPageWrapper = styled.div`

  background-color: #042C25;

  .page-background {
    background: linear-gradient(180deg, rgba(4, 44, 37, 0.00) 50%, #042C25 100%), url(${heroBackground});
    background-size: 100% 100%;

    @media (max-width: 1024px) {
      padding: 0 16px;
      background-size: cover;
    }
  }

  .hero-section {
    padding-top: 68px;
    padding-bottom: 79px;

    @media (max-width: 1024px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
`;

export default function LandingPage() {

  return (
    <LandingPageWrapper>
      <div className="page-background relative">
        <Navbar />
        <div className="container hero-section mx-auto grid grid-cols-12 gap-3">
          <div className="col-span-12 md:row-start-1 md:col-span-6 grid grid-flow-row">
            <InfoContent />
          </div>
          <div className="col-span-12 row-start-2 md:row-start-1 mb-10 md:mb-0 md:col-span-6 ">
            <div className="row-start-1 mb-5">
              <AnonymousTransferFileProvider>
                <AnonymousDownload />
              </AnonymousTransferFileProvider>
            </div>
            <div className="row-start-3">
              <BookADemoWidget />
            </div>
          </div>
        </div>
      </div>
      <InfoSection />
      <Infographic />
      <Choice />
      <BookADemo />
      <Footer />
    </LandingPageWrapper>
  );
}