
export const peerConfig = {
  iceServers: [
    {
      urls: "stun:stun.relay.metered.ca:80",
    },
    {
      urls: "turn:a.relay.metered.ca:80",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
    {
      urls: "turn:a.relay.metered.ca:80?transport=tcp",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
    {
      urls: "turn:a.relay.metered.ca:443",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
    {
      urls: "turn:a.relay.metered.ca:443?transport=tcp",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
  ],
};

//Format of files that cannot be viewed
export const rawFormats = ['zip', 'csv', 'txt', 'blend'];

export const officeRestrictions = ['docx', 'pptx'];

export const viewerEnabledFormats = [ 'pdf', 'image', 'video', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'csv']
export const enableTrackingFormats = [ 'pdf', 'docx', 'doc', 'ppt', 'pptx'];