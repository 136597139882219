import React, { createContext, useContext, useEffect, useState } from 'react';
import * as bs58 from 'bs58';
import * as nacl from 'tweetnacl';
import { signMessage } from '../inc/functions';
import { register } from '../serviceWorkerRegistration';
import { subscribeUser } from '../subscription';
import { checkIfSignedIn, initContract } from '../assets/near/utils';
import { initMailContract } from '../mails/assets/near/utils';
import getConfig from '../assets/near/config';
import { Contract } from 'near-api-js';
import getEmailConfig from '../mails/assets/near/config';
nacl.util = require('tweetnacl-util');

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [socket, setSocket] = useState(null); // Initialize socket as null
  const [myUsername, setMyUsername] = useState(null); // Initialize username as null
  const [newEmailCount, setNewEmailCount] = useState(0);

  const [encryptionKey, setEncryptionKey] = useState(null);
  const [oldPrivateKey, setOldPrivateKey] = useState(null); // Initialize username as null
  const [keyPassword, setKeyPassword] = useState(sessionStorage.getItem('keyPassword'));
  const [openKeyModal, setOpenKeyModal] = useState(true);
  const [isKeyError, setIsKeyError] = useState(false);

  const [openWalletSelector, setOpenWalletSelector] = useState(true);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);

  useEffect(() => {
    //INIT NEAR CONTRACTS
    const nearConfig = getConfig(process.env.REACT_APP_NETWORK_ID);
    console.log('init NEAR contracts');
    initContract(nearConfig).then(() => {
      initMailContract().then(() => {
        //Event listener for wallet sign in (Sender wallet is not using redirection)
        if(!checkIfSignedIn()) {
          if(window.sender) {
            window.sender.near.on("signIn", (async () => {
              console.log('TRIGGERED signIn event SENDER', checkIfSignedIn());
              await new Promise(resolve => setTimeout(resolve, 1500)); // Wait for 1 seconds - Sender wallet needs time to populate objects
              if(checkIfSignedIn()) {
                console.log('PASSED INTO LISTENER');
                window.walletConnection = window.near;
                window.account = window.near.account();
                window.accountId = window.near.getAccountId();
                window.contract = new Contract(window.near.account(), nearConfig.contractName, {
                  viewMethods: ['get_ipfs_for_wallet','file_access_by_user','get_files_by_user']
                });
                const nearEmailConfig = getEmailConfig(process.env.REACT_APP_NETWORK_ID);
                window.emailContract = new Contract(window.account, nearEmailConfig.contractName, {
                  viewMethods: ['get_recieved_emails', 'get_sent_emails']
                });
                setOpenWalletSelector(false);
                initAuthAndSockets();
              }
            }));

            window.sender.near.on("signOut", (async () => {
              localStorage.removeItem('username');
              localStorage.removeItem('jwtToken');
              localStorage.removeItem('wallet-selector');
              window.location.replace(window.location.origin + window.location.pathname)
            }));
          }
        }

        if(checkIfSignedIn()) {
          initAuthAndSockets();
        }
      }).catch((err) => {
        alert('Failed to load main the contract. Check console for details.');
        console.log(err);
        console.error(err);
      });  
    }).catch((err) => {
      console.error(err);
      console.log(err);
      alert('Failed to load email the contract. Check console for details.');
    });

  }, []);

  //USER REDIRECTED FROM NEAR LOGIN PAGE
  const initAuthAndSockets = () => {
    //Remove anonymous data from local storage on app init
    localStorage.removeItem('anonymousEmail');
    localStorage.removeItem('anyonmousToken');
    localStorage.setItem('username', window.accountId);
    setMyUsername(window.accountId);
    login();
  }

  const login = async () => {
    let key = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, window.accountId);
    const privKeyUi8 = bs58.decode(key.secretKey);
    const hostKeyPair = nacl.box.keyPair.fromSecretKey(privKeyUi8.slice(0, 32));
    const data = nacl.util.decodeUTF8(JSON.stringify({privateKey: key.secretKey, user: window.accountId, publicKey: nacl.util.encodeBase64(hostKeyPair.publicKey)}));
    signMessage(key, data).then(async (signedMessage) => {
      //CHECK IF USER PRIVATE KEY EXISTS IN DB
      const url = new URL(process.env.REACT_APP_SERVER_URL + '/login');
      await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type":"application/json",
        },
        body: JSON.stringify({...signedMessage}),
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log('login response', data);
        if(!data.success) {
          return;
        }
        if(data.access_token) {
          localStorage.setItem('jwtToken', data.access_token);

          //PWA INIT
          register();
          subscribeUser(window.accountId);

          if(data.privateKey) {
            console.log('setting key old way into wallet');
            window.account.connection.signer.keyStore.setKey(window.account.connection.networkId, window.walletConnection.getAccountId(), data.privateKey);
            setOldPrivateKey(data.privateKey);
            setOpenKeyModal(true);
          }
          if(localStorage.getItem(window.accountId + '_encryptionKey')) {
            setOpenKeyModal(false);
          }
          sessionStorage.setItem('keyPassword', data.keyPassword);
          setIsLoggedIn(true);
        }
      });
    });
  };

  // const initSocketConnection = () => {
  //   const initSocket = io.connect(process.env.REACT_APP_SERVER_SOCKET_URL, {
  //     path: process.env.REACT_APP_SOCKET_PATH,
  //     transports: ["websocket"],
  //     removeTrailingSlashes: true,
  //     query: {
  //       token: localStorage.getItem('jwtToken'),
  //       isAnonymous: false
  //     }
  //   });
  //   initSocket.on("exception", (err) => {
  //     console.log('SOCKET ERROR: ', err); // prints the message associated with the error
  //   });
  //   initSocket.on("error", (msg) => {
  //     if(msg.status === 0 && msg.message === 'User not found') {
  //       alert('Your session has expired. Please login again.');
  //       localStorage.removeItem('jwtToken');
  //       localStorage.removeItem('username');
  //       window.location.href = '/';
  //     }
  //   });
  //   // setSocket(initSocket);
    
  //   console.log('SOCKET INITIALIZED');
  // };

  //INIT SOCKET CONNECTION AFTER GETTING JWT TOKEN FROM SERVER
  // useEffect(() => {
    // if(jwtToken && myUsername && !socket) {
      
      
      //GET CURRENT DOWNLOADED FILES
      //REDESIGN
      // getCurrentDownloadedFiles();
      //TODO CHECK IF NEEDED TO RUN EVERY TIME
      //CHECK FOR SHARED FILES ON BC
      // window.contract.file_access_by_user({user_id: window.accountId}).then(files => {
      //   getSharedFilesBC(files).then(sharedFiles => {

      //     const currentLocalFiles = await db.file
      //     console.log('sharedFiles', sharedFiles);
          // if(sharedFiles.length > 0) {
          //   setCurrentSharedFiles([...sharedFiles]);
          // }
      //   });
      // });
      
    // }
  // }, [jwtToken]);

  //THIS IS FOR PRESERVING FILES AFTER REFRESH IF THEY HAVE BEEN DOWNLOADED
  // const getCurrentDownloadedFiles = async () => {
  //   console.log('getCurrentDownloadedFiles', myUsername);
  //   const filesIds = await window.contract.file_access_by_user({user_id: myUsername});
  //   console.log('BC files', filesIds);

  //   const currentDownloadedFiles = await db.downloadedFiles.toArray();
  //   console.log('currentDownloadedFiles', currentDownloadedFiles);
  //   if(currentDownloadedFiles.length > 0) {
  //     const allFileIds = [];
  //     for(const fileCid of filesIds) {
  //       const fileParts = fileCid.split('|');
  //       if(fileParts[0] !== undefined && fileParts[0] !== 'null') {
  //         allFileIds.push(fileParts[0]);
  //       }
  //       if(fileParts[1] !== undefined && fileParts[1] !== 'null') {
  //         allFileIds.push(fileParts[1]);
  //       }
  //     }
  //     console.log('allFileIds', allFileIds);
  //     const removeFromDB = currentDownloadedFiles.filter(obj => !allFileIds.some(fileId => fileId === obj.fileId)).map(obj => obj.fileId);
  //     console.log('removeFromDB', removeFromDB);
  //     let newFiles = currentDownloadedFiles;
  //     if(removeFromDB.length > 0) {
  //       removeFromDB.forEach(async fileId => {
  //         await db.downloadedFiles.where({fileId: fileId}).delete();
  //       });
  //       newFiles = currentDownloadedFiles.filter(file => removeFromDB.includes(file.fileId));
  //     }
  //     // const files = await db.downloadedFiles.toArray();
  //     // console.log('files', files);
  //     newFiles = newFiles.map(file => {
  //       file.downloading = true;
  //       file.percentage = 100;
  //       file.user = file.owner;
  //       file.anonymous = false;
  //       file.verified = true;
  //       return file;
  //     });
  //     console.log('newFiles with additional data', newFiles);
  //     setCurrentPercentage([...newFiles]);
  //   }
  //   const userFiles = await window.contract.get_files_by_user({user_id: myUsername});
  //   console.log('BC userFiles', userFiles);
  //   const userFilesDB = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
  //   console.log('userFilesDB', userFilesDB);
  //   if(userFilesDB.length !== userFiles.length && !isRedirect) {
  //     setSyncModalContent('It seems that your local state is different from the blockchain state. Do you want to sync your local state with the blockchain state?');
  //     setOpenSyncModal(true);
  //   }
  // };

  return (
    <GlobalContext.Provider value={{ isLoggedIn, setIsLoggedIn, myUsername, newEmailCount, setNewEmailCount, socket, setSocket, encryptionKey, setEncryptionKey, keyPassword, oldPrivateKey, setOldPrivateKey, openKeyModal, setOpenKeyModal, isKeyError, setIsKeyError, openWalletSelector, openOnboardingModal, setOpenOnboardingModal}}>
      {children}
    </GlobalContext.Provider>
  );
};