
import { NFTStorage } from 'nft.storage';
import * as nacl from 'tweetnacl';
import * as bs58 from 'bs58';
import { decryptPrivateKey } from '../../inc/encryption';

nacl.util = require('tweetnacl-util');


const client = new NFTStorage({ token: process.env.REACT_APP_NFTSTORAGE_TOKEN })

export const encryptFile = async (doc, recipientPublicKeys, username, isUpload = false, isEmail = false) => {

  // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, username);
  // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));

  const hostKeyPair = await decryptPrivateKey();

  //GET FILE FROM DB
  // const doc = await db.files.get(fileId);
  let fileContent = null;
  let symmetricKey = null;
  let parsedFileWithMetadata = {};
  let messageNonce = null;
  let fileContentUi8 = null;
  let encryptedMessage = null;
  

  //CHECK IF WE ARE UPDATING USERS OR IF WE ARE SHARING FOR THE FIRST TIME
  if(!isUpload) {
    const fileReader = new FileReader();
    fileReader.readAsText(doc.slice(0, doc.size, 'application/json'));
    fileContent = await new Promise((resolve) => {
      fileReader.onload = (e) => {
        parsedFileWithMetadata = JSON.parse(e.target.result);
        const privKey = hostKeyPair.secretKey;
        //FIND RIGHT ENCRYPTED SYMMETRIC KEY
        const encSymKey = parsedFileWithMetadata.metadata.encryptedSymmetricKeys.find(x => x.user === username);
        //DECODE NONCE
        const nonce = nacl.util.decodeBase64(encSymKey.nonce);
        //DECODE AND CAST TO UINT8ARRAY ENCRYPTED SYMMETRIC KEY
        const encryptedSymmetricKeyUint8 = new Uint8Array(nacl.util.decodeBase64(encSymKey.key));
        //CHECK IF WE ARE GRANTING OR REVOKING ACCESS IF WE ARE REVOKING WE GENERATE A NEW SYMMETRIC KEY
        symmetricKey = nacl.box.open(encryptedSymmetricKeyUint8, nonce, nacl.util.decodeBase64(parsedFileWithMetadata.metadata.senderPublicKey), privKey);

        //SET MESSAGE NONCE
        messageNonce = parsedFileWithMetadata.metadata.messageNonce;

        // console.log('symmetricKey', symmetricKey);
        // console.log('messageNonce', nacl.util.decodeBase64(messageNonce));
        console.log('parsedFileWithMetadata', parsedFileWithMetadata);
        // console.log('FILE CONTENT', parsedFileWithMetadata.content);

        const decryptedMessage = nacl.secretbox.open(nacl.util.decodeBase64(parsedFileWithMetadata.content), nacl.util.decodeBase64(messageNonce), symmetricKey);
        // console.log('decryptedMessage', decryptedMessage);
        //RETURN BASE64 ENCODED FILE CONTENT
        resolve(decryptedMessage);
      };
    });
    // Convert the file content to Uint8Array format
    fileContentUi8 = fileContent;
  }else {
    //READ FILE CONTENT
    const reader = new FileReader();
    reader.readAsArrayBuffer(doc);
    fileContent = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
    });

    // Convert the file content to Uint8Array format
    fileContentUi8 = new Uint8Array(fileContent);
  }

  // console.log('fileContent', fileContent);

  // Generate a random symmetric key
  symmetricKey = nacl.randomBytes(nacl.secretbox.keyLength);

  // console.log('symmetricKey', symmetricKey);

  // Generate a random nonce for use with AES encryption
  messageNonce = nacl.randomBytes(nacl.secretbox.nonceLength);

  // console.log('messageNonce', messageNonce);
  
  // Encrypt the file content using the symmetric key and nonce
  encryptedMessage = nacl.secretbox(fileContentUi8, messageNonce, symmetricKey);

  // console.log('encryptedMessage', encryptedMessage);
  encryptedMessage = nacl.util.encodeBase64(encryptedMessage);

  //ENCODE NONCE
  messageNonce = nacl.util.encodeBase64(messageNonce);

  //ADD HOST PUBLIC KEY TO RECIPIENT PUBLIC KEYS SO THAT HE CAN DECRYPT THE SYMMETRIC KEY - FOR UPDATING FILE LATER ON
  recipientPublicKeys.push({publicKey: nacl.util.encodeBase64(hostKeyPair.publicKey), value: username});
  // console.log('recipientPublicKeys', recipientPublicKeys);
  // Derive a shared secret for each recipient using the sender's private key and the recipient's public key
  let sharedKeys = recipientPublicKeys.map(publicKey => {
    const nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
    const publicKeyBytes = nacl.util.decodeBase64(publicKey.publicKey);
    const encodedKey = nacl.box(symmetricKey, nonce, publicKeyBytes, hostKeyPair.secretKey);
    return {key: nacl.util.encodeBase64(encodedKey), nonce: nacl.util.encodeBase64(nonce), user: publicKey.value};
  });

  //IF ITS FILE UPDATE THEN ADD NEW SHARED KEYS TO THE EXISTING ONES
  if(parsedFileWithMetadata.metadata) {
    sharedKeys = [...sharedKeys];
  }

  //METADATA NEEDED FOR DECRYPTION
  const fileMetadata = {
    encryptedSymmetricKeys: sharedKeys,
    senderPublicKey: nacl.util.encodeBase64(hostKeyPair.publicKey),
    messageNonce: messageNonce
  };

  //COMBINE METADATA AND FILE
  const metadataAndFile = {
    metadata: fileMetadata,
    content: encryptedMessage
  };
  // console.log('metadataAndFile', metadataAndFile);

  //CREATE BLOB CONTAINING METADATA AND FILE
  const metadataAndContentBlob = new Blob([JSON.stringify(metadataAndFile)], {type: doc.type});
  //CREATE FILE FROM BLOB CONTAINING METADATA AND FILE
  const fileWithMetadata = new File([metadataAndContentBlob], doc.name, {type: doc.type});
  if(isEmail) {
    return metadataAndFile;
  }
  return fileWithMetadata;
}


export const uploadFileToIPFS = async (file, owner, fileId) => {
  // console.log('uploading file to IPFS', file);
  const cid = await client.store({
    name: file.name,
    description: file.type,
    image: new File(['<DATA>'], 'pinpie.jpg', { type: 'image/jpg' }),
    properties: {
      file: file,
    }
  });
  // console.log('stored files with cid:', cid);
  return cid;
};


export const getIPFSMetadata = async (cid) => {
  try {
    const metadata = await fetch('https://nftstorage.link/ipfs/' + cid + '/metadata.json', {
      method: 'GET',
    }).then(function(res) {
      return res.json();
    }).then(function(response) {
      return response;
    });
  
    const file_cid = metadata.properties.file.split("ipfs://").pop();
    metadata.file_cid = file_cid;
  
    return metadata;
  }catch (error) {
    console.log('error', error);
    return;
  }
};

export const downloadFileFromIPFS = async (cid) => {
  try {
    const metadata = await getIPFSMetadata(cid);
    const response = await fetch('https://nftstorage.link/ipfs/' + metadata.file_cid);
    const reader = response.body.getReader();
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const contentLength = response.headers.get('content-length');
    let total = parseInt(contentLength, 10);
    if(!contentLength) {
      total = metadata.description.split('|')[3];
    }
    
    let loaded = 0;
    const chunks = [];
  
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
  
      chunks.push(value);
    }
  
    const blob = new Blob(chunks);
  
    const file = new File([blob], metadata.name, { type: metadata.description.split('|')[0] });
    return file;
  }catch (error) {
    console.log('error', error);
    return;
  }
  
}

export const decryptMessage = async (file, fileName, myUsername, isEmail = false) => {

  //GET HOST PRIVATE KEY
  // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, myUsername);
  // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
  const hostKeyPair = await decryptPrivateKey();

  if(isEmail) {
    const metadata = file.metadata;
    const encryptedContent = nacl.util.decodeBase64(file.content);
    const privKey = hostKeyPair.secretKey;
    const encSymKey = metadata.encryptedSymmetricKeys.find(x => x.user === myUsername);
    if(!encSymKey) {
      return false;
    }
    const nonce = nacl.util.decodeBase64(encSymKey.nonce);
    const encryptedSymmetricKeyUint8 = new Uint8Array(nacl.util.decodeBase64(encSymKey.key));
    const decryptedSymmetricKey = nacl.box.open(encryptedSymmetricKeyUint8, nonce, nacl.util.decodeBase64(metadata.senderPublicKey), privKey);
    const decryptedMessage = nacl.secretbox.open(encryptedContent, nacl.util.decodeBase64(metadata.messageNonce), decryptedSymmetricKey);
    return decryptedMessage;
  }
  const metadataReader = new FileReader();
  //READ FILE CONTENT AND METADATA
  metadataReader.readAsText(file.slice(0, file.size, 'application/json'));
  const newFile = await new Promise((resolve) => {
    metadataReader.onload = (e) => {
      const result = JSON.parse(e.target.result);
      const metadata = result.metadata;
      if(result.content) {
        //GET ENCRYPTED FILE CONTENT
        const encryptedFileContent = nacl.util.decodeBase64(result.content);
        //SET HOST PRIVATE KEY
        const privKey = hostKeyPair.secretKey;
        // console.log('privKey pair', privKey);
        //FIND RIGHT ENCRYPTED SYMMETRIC KEY
        const encSymKey = metadata.encryptedSymmetricKeys.find(x => x.user === myUsername);
        if(!encSymKey) {
          resolve(false);
          return;
        }
        // console.log('encSymKey', encSymKey);
        //DECODE NONCE
        const nonce = nacl.util.decodeBase64(encSymKey.nonce);
        // console.log('nonce', nonce);
        //DECODE AND CAST TO UINT8ARRAY ENCRYPTED SYMMETRIC KEY
        const encryptedSymmetricKeyUint8 = new Uint8Array(nacl.util.decodeBase64(encSymKey.key));
        // console.log('encryptedSymmetricKeyUint8', encryptedSymmetricKeyUint8);
        // console.log('senderPublicKey', nacl.util.decodeBase64(metadata.senderPublicKey));
        //DECRYPT SYMMETRIC KEY
        const decryptedSymmetricKey = nacl.box.open(encryptedSymmetricKeyUint8, nonce, nacl.util.decodeBase64(metadata.senderPublicKey), privKey);
        if(!decryptedSymmetricKey) {
          resolve(false);
          return;
        }
        //DECRYPT MESSAGE/FILE CONTENT WITH SYMMETRIC KEY
        const decryptedMessage = nacl.secretbox.open(encryptedFileContent, nacl.util.decodeBase64(metadata.messageNonce), decryptedSymmetricKey);

        // console.log('decryptedMessage', decryptedMessage);
        //CREATE FILE OBJECT FROM DECRYPTED MESSAGE
        const fileContentBuffer = decryptedMessage.buffer.slice(decryptedMessage.byteOffset, decryptedMessage.byteLength + decryptedMessage.byteOffset);
        // console.log('fileContentBuffer', fileContentBuffer);
        const decryptedFile = new File([fileContentBuffer], fileName, {type: file.type});
        // console.log('decryptedFile', decryptedFile);
        // console.log('-------END DECRYPTING MESSAGE-------');

        resolve(decryptedFile);
      }
    };
  });
  return newFile;
};

export const readJsonFile = async (file) => {
  const metadataReader = new FileReader();
  metadataReader.readAsText(file.slice(0, file.size, 'application/json'));
  const fileContent = await new Promise((resolve) => {
    metadataReader.onload = (e) => {
      const result = JSON.parse(e.target.result);
      // console.log('result PARSED JSON', result);
      resolve(result);
    }
  });

  return fileContent;
};

export const serverAuth = async () => {
  let key = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, window.walletConnection.getAccountId());
  // const privKeyUi8 = bs58.decode(key.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(privKeyUi8.slice(0, 32));
  const hostKeyPair = await decryptPrivateKey();
  const data = nacl.util.decodeUTF8(JSON.stringify({privateKey: hostKeyPair.secretKey, user: window.walletConnection.getAccountId(), publicKey: nacl.util.encodeBase64(hostKeyPair.publicKey)}));
  // console.log('hostKeyPair', hostKeyPair)

  return signMessage(key, data).then(async (signedMessage) => {
    const url = new URL(process.env.REACT_APP_SERVER_URL + '/login');
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type":"application/json",
      },
      body: JSON.stringify({...signedMessage}),
    }).then(response => {
      return response.json();
    }).then(async data => {
      // console.log('data', data);
      if(!data.success) {
        return;
      }
      if(data.access_token) {
        localStorage.setItem('jwtToken', data.access_token);
        localStorage.setItem('username', window.walletConnection.getAccountId());
        // window.account.connection.signer.keyStore.setKey(window.account.connection.networkId, window.walletConnection.getAccountId(), data.privateKey);
        // return await getAllUsers();
      }
    });
  });
}

const signMessage = async (key, data) => {
  const signedMessage = await key.sign(data, window.walletConnection.getAccountId(), window.account.connection.networkId);
  return {signedMessage: signedMessage, message: data};
};

export const getAllUsers = async () => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_SERVER_URL + '/users', {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }})
  .then(response => 
    response.json()
  ).then(data => {
    console.log('all users', data);

    // const myUsername = window.walletConnection.getAccountId();
    // const availableUsers = data.filter(x => x.username !== myUsername).map(x => ({label: x.username, value: x.username, publicKey: x.publicKey}));
    // console.log('availableUsers', availableUsers)
    const availableUsers = data.map(x => ({label: x.username, value: x.username, publicKey: x.publicKey}));
    return availableUsers;
  });
};