import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useState } from "react";
import styled from "styled-components";
import cancelButton from '../assets/images/cancel-x.png';
import { useUserFilesContext } from "../context/UserFilesProvider";
import { peerConfig } from "../inc/constants";
import { useGlobalContext } from "../context/GlobalProvider";

const ModalWrapper = styled.div`
  background: var(--BG_8, #283134);
  box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
  border-radius: 32px;
  padding: 24px; 
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

      .title {
        color: #FFF;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }
      .cancel-icon {
        width: 24px;
        height: 24px;
      }
  }

  .input-label {
    color: #F1F3F5;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; 
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    background: #FFF;
    gap: 8px;
    color: var(--Bg_10, #1C2129);
  }

  .subtitle {
    color: #FFF;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .status-message {
    color: var(--Red_3, #FA9C9C);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-top: 8px;
  }

  .message {
    color: #30BD5C;
    margin-bottom: 24px;
  }

  .modal-footer {
    justify-content: center;
  }
`;


export default function EmailModal({handleClose, fileHash}) {
  const [emailValue, setEmailValue] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const { peerInstanceReceiver, setDownloads } = useUserFilesContext();

  const { socket } = useGlobalContext();


  const submitEmail = () => {
    setStatusMessage('');
    console.log('submit email', emailValue);
    if(String(emailValue).length === 64 || emailValue.includes('.testnet') || emailValue.includes('.near') || String(emailValue).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      console.log('valid email');
      setStatusMessage('Checking file access...');
      fetch(process.env.REACT_APP_SERVER_URL + '/users/check-file-encryption',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({hash: fileHash, email: emailValue})
      }).then((res) => {
        return res.json();
      }).then((res) => {
        console.log(res);
        //If file owner is online, init socket and P2P connection
        if(res.ownerOnline) {
          localStorage.setItem('anyonmousToken', res.jwt);
          localStorage.setItem('anonymousEmail', emailValue);

          const peer = new window.SimplePeer({
            initiator: false,
            trickle: false,
            config: peerConfig
          });
          peerInstanceReceiver.current[res.fileInfo.cid] = peer;

          peerInstanceReceiver.current[res.fileInfo.cid] = peer;
          console.log('SOCKET ID', socket);
          console.log('init_download anonymous', {fileId: res.fileInfo.cid, username: emailValue, isPdf: false, socketId: socket.id});

          let progress = {};
          progress['user'] = res.owner;
          progress['percentage'] = 0;
          progress['downloading'] = true;
          progress['verified'] = false;
          progress['fileName'] = res.fileName;
          progress['fileId'] = res.fileInfo.cid;
          progress['statusMessage'] = 'Initializing P2P connection...';

          setDownloads((prevState) => {
            let newState = [...prevState];
            if(newState.length > 0) {
              const index = newState.findIndex(object => object.fileId === progress.fileId);
              if (index === -1) {
                newState = [...prevState, progress];
              } else {
                newState[index] = progress;
              }
            }else {
              newState = [...prevState, progress];
            }
            return [...newState];
          });

          socket.emit('init_download', {fileId: res.fileInfo.cid, username: emailValue, isPdf: false, socketId: socket.id, anonymousTransfer: true});
          handleClose();
        }else {
          // if(res.decryptedFileInfo) {
          //   setAnonymousFileCid(res.decryptedFileInfo)
          //   setAnonymousEmail(emailValue);
          //   handleClose();
          //   // setOpenDownloadModal(true);
          //   // setAnimateFlip(true);
          // }else {
          //   setStatusMessage('You do not have access to this file or file doesn\'t exist');
          // }
        }
      });
    } else {
      setStatusMessage('Invalid email address');
    }
  }

  const submitValue = (e) => {
    if(e.key === 'Enter') {
      submitEmail();
    }
  }

  
  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="md" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <p className="title">Enter you email or wallet address:</p>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="body-wrap">
            <div className="content-wrap">
              <p className="input-label">Enter you email or wallet address:</p>
              <input type="text" className="email-input" value={emailValue} onKeyDown={(e) => submitValue(e)} onChange={(e) => setEmailValue(e.target.value)}  />
            </div>
            <p className="status-message">{statusMessage}</p>
          </div>
        </DialogBody>
        <DialogFooter>
          <button className="btn-primary" onClick={() => submitEmail() }>Continue</button>
        </DialogFooter>
      </ModalWrapper>
    </Dialog>
  )
}