import { createContext, useContext, useEffect, useRef, useState } from "react";
import { downloadFileAnonymously } from "../inc/storage";
import { PublicKey } from "near-api-js/lib/utils";
import { useUserFilesContext } from "./UserFilesProvider";
import { sendMetrics } from "../inc/functions";

const AnonymousTransferFileContext = createContext();

export const useAnonymousTransferFileContext = () => useContext(AnonymousTransferFileContext);

export default function AnonymousTransferFileProvider({ children }) {

  const [anonymousEmail, setAnonymousEmail] = useState('');
  const [anonymousFileCid, setAnonymousFileCid] = useState('');
  const [anonymousFile, setAnonymousFile] = useState([]);

  const [ socket, setSocket ] = useState(null);
  
  const [signedMessage, setSignedMessage] = useState({});
  const signedMessageRef = useRef(signedMessage);

  const { peerInstanceReceiver } = useUserFilesContext();

  useEffect(() => {
    if(anonymousFileCid && anonymousEmail) {

      if(anonymousFileCid === null) {
        alert('Couldn\t decrypt file. Please try again.');
        return;
      }
      console.log('fileObject', anonymousFileCid);
      downloadFileAnonymously(anonymousFileCid.cid, setAnonymousFile, anonymousFileCid.nonce, anonymousFileCid.key, anonymousEmail);
    }
  }, [anonymousFileCid, anonymousEmail]);

  useEffect(() => {
    //ADD SIGNED MESSAGE TO REF WITH UNIQUE KEY TO PREVENT DUPLICATES
    //COMBINE USERNAME AND FILE ID TO CREATE UNIQUE KEY
    signedMessageRef.current[signedMessage.key] = signedMessage.message;
  }, [signedMessage]);

  useEffect(() => {
    if(socket) {
       //RECEIVER SIDE - RECEIVE REQUEST
       socket.on('request_sent', ({ signal, to, username, fileMetadata, fileSize, signedMessage, isAnonymous, socketId}) => {
        console.log('request_sent', signal, username, fileMetadata, fileSize, signedMessage)
        setSignedMessage({message: signedMessage, key: username + fileMetadata.fileId});
        acceptRequest(signal, to, username, fileMetadata, fileSize, isAnonymous, socketId);
      });
    }
  }, [socket]);

  const acceptRequest = async (signal, to, username, fileMetadata, fileSize, isAnonymous, socketId) => {
    console.log('ACCEPT REQUEST: ', signal, to, username, fileMetadata, fileSize, isAnonymous, socketId);
    const peer = peerInstanceReceiver.current[fileMetadata.fileId];
    if(peer.destroyed) {
      return;
    }
    console.log('peer', peerInstanceReceiver.current);
    peer.on("signal", data => {
      socket.emit('accept_request', {
        signal: data,
        to: username,
        myUsername: to,
        fileId: fileMetadata.fileId,
        isAnonymous: isAnonymous,
        socketId: socketId
      });
    });
    const fileChunks = [];
    let transfered = 0;
    peer.on("data", async data => {
      let progress = {};
      progress['user'] = username;
      progress['percentage'] = Math.floor((transfered / fileSize) * 100);
      progress['downloading'] = true;
      progress['verified'] = false;
      progress['fileName'] = fileMetadata.name;
      progress['fileId'] = fileMetadata.fileId;
      progress['rawPermissions'] = fileMetadata.rawPermissions;
      progress['readPermissions'] = fileMetadata.readPermissions;
      progress['watermarkPermissions'] = fileMetadata.watermarkPermissions;
      //END OF FILE TRANSFER
      if (data.toString() === "EOF") {
        console.log('EOF');
        // Once, all the chunks are received, combine them to form a Blob
        const receivedFile = new Blob(fileChunks, { type: fileMetadata.type });
        var reader = new FileReader();
        reader.onload = async function() {
          //VERIFY FILE
          const encodedMessage = new Uint8Array(this.result);
          //check if file is correctly signed
          // console.log('ENCODED MESSAGE: ', encodedMessage);

          console.log('SIGNED MESSAGE: ', signedMessageRef.current[username + fileMetadata.fileId]);

          const signedMessage = {signature: new Uint8Array(signedMessageRef.current[username + fileMetadata.fileId].signature), publicKey: {keyType: signedMessageRef.current[username + fileMetadata.fileId].publicKey.keyType ,data: new Uint8Array(signedMessageRef.current[username + fileMetadata.fileId].publicKey.data)}};
          const publicKey = new PublicKey(signedMessage.publicKey);
          const verified = publicKey.verify(encodedMessage, signedMessage.signature);
          console.log('VERIFIED: ', verified)
          if(verified) {
            progress['verified'] = true;
            progress['file'] = receivedFile;
            // await db.downloads.add({
            //   name: progress['fileName'],
            //   size: fileSize,
            //   sharedTo: [username],
            //   fileId: fileMetadata.fileId,
            //   date: new Date().getTime(),
            // });

            let metricPermissions = [];
            if(fileMetadata.rawPermissions) {
              metricPermissions.push('raw');
            }
            if(fileMetadata.readPermissions) {
              metricPermissions.push('read');
            }
            if(fileMetadata.watermarkPermissions) {
              metricPermissions.push('watermark');
            }
            const data = {
              name: progress['fileName'],
              size: fileSize,
              sharedTo: to,
              sharedBy: username,
              date: new Date().getTime(),
              permissions: metricPermissions,
              transferType: 'P2P',
              action: 'Transfer'
            }
            sendMetrics(data);
            
          } else {
            progress['verified'] = false;
            console.log('INVALID');
          }
          signedMessageRef.current[username + fileMetadata.fileId] = null;
          const fileWithName = new File([receivedFile], fileMetadata.name, {type: fileMetadata.type})
          progress['file'] = fileWithName;
  
          setAnonymousFile([progress]);

          peerInstanceReceiver.current[fileMetadata.fileId].destroy();
          delete peerInstanceReceiver.current[fileMetadata.fileId];
        }
        reader.readAsArrayBuffer(receivedFile);
       
      } else {
        transfered += data.length;
        setAnonymousFile([progress]);
        // Keep appending various file chunks
        fileChunks.push(data);
      }
    });
    console.log('PEER SIGNAL: ', signal);
    peer.signal(signal);
  };
  
  return (
    <AnonymousTransferFileContext.Provider value={{anonymousEmail, setAnonymousEmail, anonymousFileCid, setAnonymousFileCid, anonymousFile, setAnonymousFile, socket, setSocket}}>
      {children}
    </AnonymousTransferFileContext.Provider>
  );
}