import { connect, keyStores, WalletConnection, Contract } from 'near-api-js'
import getConfig from './config'
import { Buffer } from 'buffer';
// import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
// import { setupWalletSelector } from "@near-wallet-selector/core";
// import { setupFastAuthWallet } from 'near-fastauth-wallet';

import * as nacl from 'tweetnacl';
nacl.util = require('tweetnacl-util');

window.Buffer = Buffer;


// Initialize contract & set global variables
export async function initContract(nearConfig) {
  const currentWallet = localStorage.getItem('wallet-selector');
  if(currentWallet === 'sender') {
    const isLoggedIn = checkIfSignedIn();
    if(isLoggedIn) {
      window.walletConnection = window.near;
      window.account = window.near.account();
      window.accountId = window.near.getAccountId();
      window.contract = new Contract(window.walletConnection.account(), nearConfig.contractName, {
        viewMethods: ['get_ipfs_for_wallet','file_access_by_user','get_files_by_user']
      });
    }
  }else {
    const near = await connect(Object.assign({ keyStore: new keyStores.BrowserLocalStorageKeyStore() }, nearConfig))
    window.walletConnection = new WalletConnection(near, 'pathfinder_');
    await window.walletConnection._completeSignInWithAccessKey();
    // Getting the Account ID. If still unauthorized, it's just empty string
    window.accountId = await window.walletConnection.getAccountId();
    window.account = await window.walletConnection.account();
    // Initializing our contract APIs by contract name and configuration
    window.contract = new Contract(window.walletConnection.account(), nearConfig.contractName, {
      // View methods are read only. They don't modify the state, but usually return some value.
      viewMethods: ['get_ipfs_for_wallet','file_access_by_user','get_files_by_user'],
      // Change methods can modify the state. But you don't receive the returned value when called.
      changeMethods: ['add_file_users','remove_file','remove_users_from_file'],
    });
  } 
}

export function logout() {
  const currentWallet = localStorage.getItem('wallet-selector');
  if(currentWallet === 'sender') {
    window.sender.near.signOut();
  }else {
    localStorage.removeItem('username');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('wallet-selector');
    window.walletConnection.signOut();
    window.location.replace(window.location.origin + window.location.pathname)
  }
}

export function login(walletUrl) {
  if(walletUrl === 'sender') {
    window.sender.near.requestSignIn({
      contractId: process.env.REACT_APP_CONTRACT_NAME_MAIN,
      contractMethods: ['add_file_users','remove_file','remove_users_from_file']
    });
  }else {
    // const selector = setupWalletSelector({
    //   network: "testnet",
    //   modules: [
    //     setupMyNearWallet(),
    //     setupFastAuthWallet({
    //       relayerUrl: "http://nearbalkans.org:3030",
    //       walletUrl: "https://fast-auth-sdk-frontend-xbgueyzluq-uc.a.run.app",
    //     })
    //   ],
    // });
    // selector.then((selector) => selector.wallet('fast-auth-wallet'))
    // .then((fastAuthWallet) => {
    //   fastAuthWallet.signIn({
    //     contractId: "dev-1683106272212-84505562529126",
    //     isRecovery: true,
    //   });
    // });
   
    const nearConfig = getConfig(process.env.REACT_APP_NETWORK_ID);
    connect(Object.assign({ keyStore: new keyStores.BrowserLocalStorageKeyStore() }, nearConfig)).then((near) => {
      window.walletConnection = new WalletConnection(near, 'pathfinder_');
      window.walletConnection.requestSignIn({
        contractId: nearConfig.contractName,
        successUrl: process.env.REACT_APP_SHARE_URL,
      });
    });
  }
  
}

export const checkIfSignedIn = () => {
  // const selector = setupWalletSelector({
  //   network: "testnet",
  //   modules: [
  //     setupMyNearWallet(),
  //     setupFastAuthWallet({
  //       relayerUrl: "http://nearbalkans.org:3030",
  //       walletUrl: "https://fast-auth-sdk-frontend-xbgueyzluq-uc.a.run.app",
  //     })
  //   ],
  // });
  // selector.then((selector) => console.log('TEST: ', selector.isSignedIn()));
  const currentWallet = localStorage.getItem('wallet-selector');
  if(currentWallet === 'sender') {
    return window.sender.near.isSignedIn({contractId: process.env.REACT_APP_CONTRACT_NAME_MAIN});
  }else {
    if(!window.walletConnection) {
      return false;
    }
    return window.walletConnection.isSignedIn();
  }
}

export const checkUserIPFS = async (user) => {
  console.log('checkUserIPFS', user);
  const response = await window.contract.get_ipfs_for_wallet({wallet_id: user});

  return response;
}

export const addFileToContract = async (users, oldCid, newCid, userIPFSHash, fileDBID, newDocumentId) => {
  if(localStorage.getItem('wallet-selector') === 'sender') {
    const tx = {
      receiverId: process.env.REACT_APP_CONTRACT_NAME_MAIN,
      actions: [
        {
          methodName: 'add_file_users',
          args: {
            old_cid: String(oldCid),
            new_cid: String(newCid),
            user_ids: users,
            ipfs_hash: String(userIPFSHash),
          },
          deposit: '0',
          gas: '300000000000000'
        }
      ]
    }
    const res = await window.near.signAndSendTransaction(tx);
    console.log('RES: ', res);
    return;
  }else {
    await window.contract.add_file_users({
      callbackUrl: window.location.href, // callbackUrl after the transaction approved (optional)
      meta: fileDBID + '|' + newDocumentId + '|isUpload', // meta information NEAR Wallet will send back to the application. `meta` will be attached to the `callbackUrl` as a url param
      args: {
        old_cid: String(oldCid),
        new_cid: String(newCid),
        user_ids: users,
        ipfs_hash: String(userIPFSHash),
      },
    });
  }
}

export const addUserToContract = async (users, oldCid, newCid, userIPFSHash, fileDBID, newDocumentId) => {
  if(localStorage.getItem('wallet-selector') === 'sender') {
    const tx = {
      receiverId: process.env.REACT_APP_CONTRACT_NAME_MAIN,
      actions: [
        {
          methodName: 'add_file_users',
          args: {
            old_cid: String(oldCid),
            new_cid: String(newCid),
            user_ids: users,
            ipfs_hash: String(userIPFSHash),
          },
          deposit: '0',
          gas: '300000000000000'
        }
      ]
    }
    const res = await window.near.signAndSendTransaction(tx);
    console.log('RES: ', res);
    return;
  }else {
    await window.contract.add_file_users({
      callbackUrl: window.location.href, // callbackUrl after the transaction approved (optional)
      meta: fileDBID + '|' + newDocumentId, // meta information NEAR Wallet will send back to the application. `meta` will be attached to the `callbackUrl` as a url param
      args: {
        old_cid: String(oldCid),
        new_cid: String(newCid),
        user_ids: users,
        ipfs_hash: String(userIPFSHash),
      }
    });
  }
}

export const getFileAccessByUser = async (username) => {
  return await window.contract.file_access_by_user({user_id: username});
}

export const removeFileUserFromContract = async (users, oldCid, newCid, userIPFSHash, fileDBID) => {
  if(localStorage.getItem('wallet-selector') === 'sender') {
    const tx = {
      receiverId: process.env.REACT_APP_CONTRACT_NAME_MAIN,
      actions: [
        {
          methodName: 'remove_users_from_file',
          args: {
            users: users,
            old_cid: oldCid,
            new_cid: newCid,
            ipfs_hash: userIPFSHash
          },
          deposit: '0',
          gas: '300000000000000'
        }
      ]
    }
    const res = await window.near.signAndSendTransaction(tx);
    console.log('RES: ', res);
    return;
  }else {
    await window.contract.remove_users_from_file({
      callbackUrl: window.location.href, // callbackUrl after the transaction approved (optional)
      meta: fileDBID + '|removeUser',
      args: {
        users: users,
        old_cid: oldCid,
        new_cid: newCid,
        ipfs_hash: userIPFSHash
      }
    });
  }
};

export const removeFileContract = async (fileCid, walletIPFS, fileId ) => {
  try {
    if(localStorage.getItem('wallet-selector') === 'sender') {
      const tx = {
        receiverId: process.env.REACT_APP_CONTRACT_NAME_MAIN,
        actions: [
          {
            methodName: 'remove_file',
            args: {
              file_id: String(fileCid),
              ipfs_hash: walletIPFS
            },
            deposit: '0',
            gas: '300000000000000'
          }
        ]
      }
      const res = await window.near.signAndSendTransaction(tx);
      console.log('RES: ', res);
    }else {
      await window.contract.remove_file({
        callbackUrl: window.location.href, // callbackUrl after the transaction approved (optional)
        meta: fileId + '|delete',
        args: {
          file_id: String(fileCid),
          ipfs_hash: walletIPFS
        }
      });
    }
  }catch(e) {
    console.log(e);
  }
};