import { Fragment, useEffect, useState } from "react";
import { useGlobalContext } from "../context/GlobalProvider";
import { decryptMessage, encryptFile, encryptUserFile } from "../inc/encryption";
import { deleteFileFromIPFS, uploadFileToIPFS } from "../inc/storage";
import { generatePDF } from "../inc/functions";
import { db } from "../inc/database";
import { addUserToContract, checkUserIPFS, removeFileUserFromContract } from "../assets/near/utils";
import { enableTrackingFormats, rawFormats, viewerEnabledFormats } from "../inc/constants";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import styled from "styled-components";
import Select from 'react-select';
import cancelButton from '../assets/images/cancel-x.png';
import { useUserFilesContext } from "../context/UserFilesProvider";

import permissionsLoader from '../assets/images/permission-loader.svg';
import successIcon from '../assets/images/success-icon.png';
import { deleteFileFromMachina, uploadFileToMachina } from "../inc/storageOnMachina";

const ModalWrapper = styled.div`
  background: var(--BG_8, #283134);
  box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
  border-radius: 32px;
  padding: 24px; 

  @media (max-width: 768px) {
    padding: 0;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      color: #FFF;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
    }
    .cancel-icon {
      width: 24px;
      height: 24px;
    }

    .file-name {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }
  }
  .share-label {
    color: #FFF;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .shared-with-label,
  .user-info {
    color: #F1F3F5;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    margin-bottom: 8px;
  }

  .shared-with-label {
    border-bottom: 1px solid var(--Gray_2, #C0CDCF);
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
  }

  .user-info {
    font-weight: 600;
  }

  .file-access-wrap {
    display: flex;
    flex-wrap: wrap;

    .checkbox-wrap {
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 24px;
      border: 1px solid var(--Gray_2, #C0CDCF);
      background: var(--BG_8, #283134);
      cursor: pointer;
      margin-right: 8px;
      margin-bottom: 6px;

      @media (max-width: 768px) {
        width: 80px;
        padding: 5px 10px;
        margin-bottom: 10px;
      }

      &.filled {
        background: var(--Mint_5, #06C5C5);
        border: 1px solid var(--Mint_5, #06C5C5);
      }

      label {
        width: 100%;
        cursor: pointer;

        input {
          position: absolute;
          display: none;
          color: #fff !important;

          &:checked + span {
            color: #000;
            background: var(--Mint_5, #06C5C5);
          }
        }
        span {
          text-align: center;
          padding: 3px 0;
          display: block;
          color: var(--Gray_1, #F1F3F5);
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 200% */
        }
      }
    }
  }

  .revoke-label {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid var(--Red_3, #FA9C9C);
    cursor: pointer;
    margin-right: 8px;
    color: var(--Red_3, #FA9C9C);
    margin-bottom: 6px;

    @media (max-width: 768px) {
      width: 80px;
      padding: 5px 10px;
      margin-bottom: 10px;
    }

    label {
      cursor: pointer;

      span {
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .share-wrap {
    margin-top: 16px;
  }

  .progress-bar-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
  
    .progress-bar {
      width: 100%;
      height: 10px;
      background: transparent;
      border-radius: 10px;
    }
  
    .percentage {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;
      display: flex;
      align-items: center;
      background: transparent;
      margin-left: 5px !important;
    }
  }

  .modal-footer {
    justify-content: center;
  }

  .shared-with-wrap {
    border-bottom: 1px solid var(--Gray_2, #C0CDCF);
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .btn-primary {
    &:disabled {
      background: var(--Mint_8, #057272);
    }
  }

  .progress-bar-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .permission-loader {
      width: 45px;
      height: 45px;
      margin-bottom: 16px;
    }

    .message {
      color: var(--Mint_5, #06C5C5);
      font-family: Open Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
      text-align: center;

      &.success-message {
        color: #06C547;
        margin-bottom: 16px;
      }
    }

    .notification {
      color: var(--Gray_1, #F1F3F5);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
      text-align: center;
    }
  }

  
`;

export default function ShareFileModal({handleClose, users, document, setCurrentDocument}) {

  //Posible users to add to file
  const [availableUsers, setAvailableUsers] = useState([]);

  //Current users with access to file
  const [currentUsers, setCurrentUsers] = useState([]);
  
  //Get user username for determing if user is owner of file
  const { myUsername, socket } = useGlobalContext();

  //Check URL params for added added user query param
  const urlParams = new URLSearchParams(window.location.search);
  
  let isErrorResponse = urlParams.get("errorCode");

  const [viewCheckbox, setViewCheckbox] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const { setUploadedFiles } = useUserFilesContext();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [newlyAddedUsers, setNewlyAddedUsers] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const addedUser = urlParams.get("signMeta");
  useEffect(() => {
    if(addedUser) {
      const documents = addedUser.split("|");
      if(documents.length < 3) {
        if(documents[1] === 'removeUser') {
          setSuccessMessage('Access revoked!');
        }else {
          console.log('OPENING MODAL ON REDIRECT');
          openModalOnRedirect(addedUser);
        }
      }
      window.history.pushState('object', '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if(newlyAddedUsers.length > 0) {
      setSubmitButtonDisabled(false);
    }else {
      setSubmitButtonDisabled(true);
    }
  }, [newlyAddedUsers]);
  

  //Progress bar percentage and data
  const [progressBarData, setProgressBarData] = useState({message: '', percentage: 0});

  //Get every user in the system and set them as options for multiselect, except for users that are currently added to file
  useEffect(() => {
    
    getAllUsers();

    //TODO REMOVE SUCCESS MESSAGE ON OPEN IF NOT REDIRECT
  }, []);

  const getAllUsers = async () => {
    const token = localStorage.getItem('jwtToken');
    fetch(process.env.REACT_APP_SERVER_URL + '/users', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }})
    .then(response => 
      response.json()
    ).then(data => {
      console.log('all users', data);
      console.log('file users', users)
      let availableOptions = [];
      let tempCurrentUsers = [];

      //Get permissions of users that are currently added to file
      let userPermissions = [];
      for(const fileUser of users) {
        const rawUser = fileUser.split('|');
        userPermissions[rawUser[0]] = rawUser[1];
      }
      //Seperate users that are currently added to file and users that are not
      for(const user of data) {
     
        //Skip if its users own username
        if(user.username === myUsername) {
          continue;
        }
        users = users.map((fileUser) => {
          const fileUsersRaw = fileUser.split('|');
          return fileUsersRaw[0];
        });

        //GET PUBLIC KEYS OF USERS CURRENTLY ADDED TO FILE - FOR REVOKING ACCESS
        if(users.includes(user.username)) {
          //READ|DOWNLOAD|WRITE
          let pieces = userPermissions[user.username];
          //Add username, id, public key, flag that users is in file and permissions of user to file users
          tempCurrentUsers.push({
            label: user.username,
            value: user.username,
            publicKey: user.publicKey,
            addedToFile: true,
            read: pieces[0] === 't' ? true : false,
            download: pieces[1] === 't' ? true : false,
            write: pieces[2] === 't' ? true : false
          });
          console.log('tempCurrentUsers', tempCurrentUsers);
        }else {
          //Add username, id, public key, flag that users is in file and permissions of user to available users
          availableOptions.push({label: user.username, value: user.username, publicKey: user.publicKey, addedToFile: false, read: false, download: false, write: true});
        }
      }
      setAvailableUsers(availableOptions);
      setCurrentUsers(tempCurrentUsers);
    });
  };

  const revokeUserAccess = async (user, fileId) => {
    console.log('REVOKE USER ACCESS', user, fileId, currentUsers);
    if(isProcessing) {
      return;
    }
    setSuccessMessage(null);
    setIsProcessing(true);
    setProgressBarData({message: 'Removing user access.', percentage: 10});

    let userWithPermissions = currentUsers.filter((u) => u.label === user.label)[0];
    const readPermissions = userWithPermissions.read ? 't' : 'f';
    const downloadPermissions = userWithPermissions.download ? 't' : 'f';
    const writePermissions = userWithPermissions.write ? 't' : 'f';
    userWithPermissions = userWithPermissions.label + '|' + readPermissions + downloadPermissions + writePermissions;
    console.log('userWithPermissions', userWithPermissions);

    const newUsers = currentUsers.filter((u) => u.label !== user.label);
    console.log('newUsers', newUsers);

    //Format usernames and permissions for DB insertion - only username and permissions
    let usernames = [];
    const formatedUsers = newUsers.map((u) => {
      const readPermissions = u.read ? 't' : 'f';
      const downloadPermissions = u.download ? 't' : 'f';
      const writePermissions = u.write ? 't' : 'f';
      const permissions = readPermissions + downloadPermissions + writePermissions;
      u.name = u.value + '|' + permissions;
      usernames.push(u.label + '|' + permissions);
      return u;
    });

    console.log('usernames', usernames);
    const fileExtension = document.name.split('.').pop();
    const officePdfSupport = ['docx', 'pptx'];
    let rawDocumentUsers = [];
    let pdfDocumentUsers = [];
    //Split users into two arrays, one for raw file and one for pdf file
    for(const user of formatedUsers) {
      //If file is pdf, raw document is the same as tracking version of document
      if(document.type === 'application/pdf') {
        rawDocumentUsers.push(user);
        continue;
      }
      //Get permissions of user
      const userPermissions = user.name.split('|')[1];

      //Check if user permissions are all false
      if(userPermissions === 'fff') {
        //If file is doxc ili pptx, add user to PDF version of file
        if(officePdfSupport.includes(fileExtension)) {
          pdfDocumentUsers.push(user);
          continue;
        }else {
          rawDocumentUsers.push(user);
          continue;
        }
      }

      //If user has write permissions (last permission), add to raw file users, else add to pdf file users
      if(userPermissions[2] !== 't') {
        pdfDocumentUsers.push(user);
      }else {
        if(userPermissions[0] === 't' || userPermissions[1] === 't') {
          pdfDocumentUsers.push(user);
        }
        rawDocumentUsers.push(user);          
      }
    }

    console.log('rawDocumentUsers', rawDocumentUsers);
    console.log('pdfDocumentUsers', pdfDocumentUsers);

    const encryptedRawDocument = await db.files.get(document.id);
    let encryptedRawFile = encryptedRawDocument.file;
    let encryptedPdfFile = encryptedRawDocument.pdfFile;
    let cidPrivate = encryptedRawDocument.cid_private;
    let cidPdf = encryptedRawDocument.cid_pdf ? encryptedRawDocument.cid_pdf : null;
    //If user has write access, encrypt file with new users
    setProgressBarData({message: 'Removing user access.', percentage: 40});
    if(rawDocumentUsers.length > 0) {
      if(cidPrivate) {
        if(/[a-zA-Z]/g.test(cidPrivate)) {
          await deleteFileFromIPFS(cidPrivate);
        }else {
          await deleteFileFromMachina(myUsername, cidPrivate);
        }
      }
      encryptedRawFile = await encryptFile(encryptedRawFile, rawDocumentUsers, myUsername);
      // cidPrivate = await uploadFileToIPFS(encryptedRawFile, myUsername, fileId);
      // cidPrivate = cidPrivate.ipnft;
      cidPrivate = await uploadFileToMachina(encryptedRawFile, myUsername, (Date.now() + 1).toString());
      console.log('cid_private', cidPrivate);
      //DELETE OLD FILE FROM IPFS
    }else {
      //DELETE OLD FILE FROM IPFS
      if(cidPrivate) {
        if(/[a-zA-Z]/g.test(cidPrivate)) {
          await deleteFileFromIPFS(cidPrivate);
        }else {
          await deleteFileFromMachina(myUsername, cidPrivate);
        }
      }
      encryptedRawFile = await encryptFile(encryptedRawDocument.file, [], myUsername);
      // cidPrivate = await uploadFileToIPFS(encryptedRawFile, myUsername, fileId);
      // cidPrivate = cidPrivate.ipnft;
      cidPrivate = await uploadFileToMachina(encryptedRawFile, myUsername, (Date.now() + 1).toString());
      console.log('cid_private', cidPrivate);
    }
    setProgressBarData({message: 'Removing user access.', percentage: 70});
    //If user has read or download access, update file in IPFS
    if(pdfDocumentUsers.length > 0 && (document.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || document.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      const rawDocument = await decryptMessage(encryptedRawFile, encryptedRawFile.name, myUsername);
      console.log('rawDocument', rawDocument);
      //Generate pdf file from raw file
      const pdfDocument = await generatePDF(rawDocument, myUsername, pdfDocumentUsers);
      console.log('pdfDocument', pdfDocument)
      //Encrypt pdf file with users that have read or write permissions
      encryptedPdfFile = await encryptFile(pdfDocument, pdfDocumentUsers, myUsername, true);
      console.log('encryptedPdfFile', encryptedPdfFile);

      //Delete old PDF file from IPFS
      if(encryptedRawDocument.cid_pdf) {
        if(/[a-zA-Z]/g.test(encryptedRawDocument.cid_pdf)) {
          await deleteFileFromIPFS(encryptedRawDocument.cid_pdf);
        }else {
          await deleteFileFromMachina(myUsername, encryptedRawDocument.cid_pdf);
        }
      }
      //Upload new pdf file to IPFS
      // cidPdf = await uploadFileToIPFS(encryptedPdfFile, myUsername, fileId);
      // cidPdf = cidPdf.ipnft;
      cidPdf = await uploadFileToMachina(encryptedPdfFile, myUsername, fileId);
      console.log('cidPdf', cidPdf);
    }else {
      encryptedPdfFile = null;
      cidPdf = null;
    }

    //Format cids for DB insertions
    const newContractCid = cidPrivate + '|' + cidPdf;
    //Create new file in local DB
    const data = {
      file: encryptedRawFile,
      pdfFile: encryptedPdfFile,
      cid_public: encryptedRawDocument.cid_public,
      cid_private: cidPrivate,
      cid_pdf: cidPdf,
      cid_contract: newContractCid,
      nonce: encryptedRawDocument.nonce,
      key: encryptedRawDocument.key,
      owner: myUsername,
      users: usernames,
      fileId: fileId,
      name: encryptedRawFile.name,
      type: encryptedRawFile.type,
      size: encryptedRawFile.size,
    };
    console.log('data', data);
    
    //Update file in local DB
    await db.files.update(encryptedRawDocument.id, data);

    //Check if user has an IPFS hash stored on the blockchain
    const currentIPFS = await checkUserIPFS(window.accountId);
    console.log('currentIPFS', currentIPFS);

    if(currentIPFS && /[a-zA-Z]/g.test(currentIPFS)) {
      await deleteFileFromIPFS(currentIPFS);
    }else if(currentIPFS) {
      await deleteFileFromMachina(window.accountId, currentIPFS);
    }

    setProgressBarData({message: 'Removing user access.', percentage: 80});
    //Encrypt user file but send current document ID so that it doesn't include it in new file - it will be deleted after confirmation of sharing
    const file = await encryptUserFile(myUsername, document.id);
    console.log('USER FILE', file);
    //Upload user file to IPFS
    // const wallet_ipfs = await uploadFileToIPFS(file, myUsername, fileId);
    const wallet_ipfs = await uploadFileToMachina(file, myUsername, (Date.now() + 1).toString());
    console.log('wallet_ipfs', wallet_ipfs);
    
    const documentMetadata = {
      type: encryptedRawFile.type,
      size: encryptedRawFile.size,
      name: encryptedRawFile.name,
      owner: myUsername,
      fileId: fileId,
      users: usernames
    }
    socket.emit('revoke_user_access', {
      document: documentMetadata,
      user: user.label,
      fileId: fileId,
      cid_private: cidPrivate,
      cid_pdf: cidPdf,
      cid_contract: newContractCid,
      userWithPermissions: userWithPermissions,
      old_cid_contract: encryptedRawDocument.cid_contract,
    });
    user.addedToFile = false;
    user.read = false;
    user.write = true;

    const files = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
    console.log('newDocumentsList', files);
    setUploadedFiles([...files]);

    try {
      await removeFileUserFromContract([userWithPermissions], encryptedRawDocument.cid_contract, newContractCid, wallet_ipfs, encryptedRawDocument.id);
    }catch(e) {
      console.log('error', e);
    }

    setAvailableUsers([user ,...availableUsers]);
    setProgressBarData({message: 'Access revoked!', percentage: 100});
    setCurrentUsers(newUsers);
    setIsProcessing(false);
  };

  const grantUserAccess = async (users, fileId) => {
    console.log('GRANT USER ACCESS', users, fileId, currentUsers);
    const fileUsers = users;
    setSuccessMessage(null);
    //Check if users have been selected
    if(!users || users.length === 0) {
      setProgressBarData({message: 'Please select at least one user.', percentage: 0});
      return;
    }

    setSubmitButtonDisabled(true);
    setIsProcessing(true);
    const fileExtension = document.name.split('.').pop();
    let permissionEmptyFlag = false;
    //Format usernames and permissions for DB insertion - only username and permissions
    let usernames = [];
    //Iterate through users and format them so that they have permissions attached to them
    const formatedUsers = users.map((u) => {
      const readPermissions = u.read ? 't' : 'f';
      const downloadPermissions = u.download ? 't' : 'f';
      const writePermissions = u.write ? 't' : 'f';
      const permissions = readPermissions + downloadPermissions + writePermissions;
      
      //If viewer doesn't support file type and user has no permissions, stop the process
      if(permissions === 'fff' && rawFormats.includes(fileExtension)) {
        permissionEmptyFlag = true;
      }
      usernames.push(u.label + '|' + permissions);
      u.name = u.value + '|' + permissions;

      return u;
    });

    //If any user has no permissions checked, stop the process
    if(permissionEmptyFlag) {
      setProgressBarData({message: 'User(s) needs to have at least one permission for this file type.', percentage: 0});
      return;
    }
    
    if(formatedUsers.length > 0) {
      setProgressBarData({message: 'Setting permissions', percentage: 10});

      let rawDocumentUsers = [];
      let pdfDocumentUsers = [];
      const officePdfSupport = ['docx', 'pptx'];

      //Split users into two arrays, one for raw file and one for pdf file
      for(const user of formatedUsers) {
        //If file is pdf, raw document is the same as tracking version of document
        if(document.type === 'application/pdf') {
          rawDocumentUsers.push(user);
          continue;
        }
        
        const userPermissions = user.name.split('|')[1];

        //Check user permissions
        if(userPermissions === 'fff') {
          //If file is doxc ili pptx, add user to PDF version of file
          if(officePdfSupport.includes(fileExtension)) {
            pdfDocumentUsers.push(user);
            continue;
          }else {
            rawDocumentUsers.push(user);
            continue;
          }
        }

        //If track permission is set, add user to pdf file
        if(userPermissions[0] === 't') {
          pdfDocumentUsers.push(user);
        }else {
          rawDocumentUsers.push(user);
        }
      }

      console.log('rawDocumentUsers', rawDocumentUsers);
      console.log('pdfDocumentUsers', pdfDocumentUsers);

      //TODO optimize process by not encrypting file if the user structure hasn't changed - using addedToFile flag
      
      //Set current file values, so that they persist if there aren't any changes
      const encryptedRawDocument = await db.files.get(document.id);
      let encryptedRawFile = encryptedRawDocument.file;
      let cidPrivate = encryptedRawDocument.cid_private;
      let cidPdf = encryptedRawDocument.cid_pdf ? encryptedRawDocument.cid_pdf : null;
      let encryptedPdfFile = encryptedRawDocument.pdfFile;

      // console.log('encryptedRawDocument', encryptedRawDocument);
      // return;
      //Encrypt raw file if there are users that have write permissions
      if(rawDocumentUsers.length > 0) {
        encryptedRawFile = await encryptFile(encryptedRawDocument.file, rawDocumentUsers, myUsername);
        console.log('encryptedRawFile', encryptedRawFile);
        
        setProgressBarData({message: 'Setting permissions', percentage: 40});
        //Upload new raw file to IPFS
        // cidPrivate = await uploadFileToIPFS(encryptedRawFile, myUsername, fileId);
        // cidPrivate = cidPrivate.ipnft;
        cidPrivate = await uploadFileToMachina(encryptedRawFile, myUsername, (Date.now() + 1).toString());
        console.log('cid_private', cidPrivate);

        //Delete old priv file from IPFS
        // if(encryptedRawDocument.cid_private) {
        //   if(/[a-zA-Z]/g.test(encryptedRawDocument.cid_private)) {
        //     await deleteFileFromIPFS(encryptedRawDocument.cid_private);
        //   }else {
        //     await deleteFileFromMachina(myUsername, encryptedRawDocument.cid_private);
        //   }
        // }
      }else {
        //If there are no users that have write permissions, use the same raw file
        encryptedRawFile = await encryptFile(encryptedRawDocument.file, [], myUsername);
        // cidPrivate = await uploadFileToIPFS(encryptedRawFile, myUsername, fileId);
        // cidPrivate = cidPrivate.ipnft;
        cidPrivate = await uploadFileToMachina(encryptedRawFile, myUsername, (Date.now() + 1).toString());
        console.log('cid_private', cidPrivate);

        // //Delete old PDF file from IPFS
        // if(encryptedRawDocument.cid_private) {
        //   if(/[a-zA-Z]/g.test(encryptedRawDocument.cid_private)) {
        //     await deleteFileFromIPFS(encryptedRawDocument.cid_private);
        //   }else {
        //     await deleteFileFromMachina(myUsername, encryptedRawDocument.cid_private);
        //   }
        // }
      }
      //Generate and encrypt pdf file if there are users that have write or read permissions
      if(pdfDocumentUsers.length > 0) {
        //First decrypt raw file from local DB
        const rawDocument = await decryptMessage(encryptedRawDocument.file, encryptedRawDocument.name, myUsername);
        setProgressBarData({message: 'Setting permissions', percentage: 60});
        console.log('rawDocument', rawDocument);
        //Generate pdf file from raw file
        const pdfDocument = await generatePDF(rawDocument, myUsername, pdfDocumentUsers);
        console.log('pdfDocument', pdfDocument)
        //Encrypt pdf file with users that have read or write permissions
        encryptedPdfFile = await encryptFile(pdfDocument, pdfDocumentUsers, myUsername, true);
        console.log('encryptedPdfFile', encryptedPdfFile);
        //Delete old PDF file from IPFS
        // if(encryptedRawDocument.cid_pdf) {
        //   if(/[a-zA-Z]/g.test(encryptedRawDocument.cid_pdf)) {
        //     await deleteFileFromIPFS(encryptedRawDocument.cid_pdf);
        //   }else {
        //     await deleteFileFromMachina(myUsername, encryptedRawDocument.cid_pdf);
        //   }
        // }
        //Upload new pdf file to IPFS
        // cidPdf = await uploadFileToIPFS(encryptedPdfFile, myUsername, fileId);
        // cidPdf = cidPdf.ipnft;
        cidPdf = await uploadFileToMachina(encryptedPdfFile, myUsername, (Date.now() + 1).toString());
        console.log('cidPdf', cidPdf);
      }

      //Format cids for DB insertions
      let oldCid = encryptedRawDocument.cid_contract;
      const newContractCid = cidPrivate + '|' + cidPdf;
      //Create new file in local DB
      const data = {
        file: encryptedRawFile,
        pdfFile: encryptedPdfFile,
        cid_public: encryptedRawDocument.cid_public,
        cid_private: cidPrivate,
        cid_pdf: cidPdf,
        cid_contract: newContractCid,
        nonce: encryptedRawDocument.nonce,
        key: encryptedRawDocument.key,
        owner: myUsername,
        users: usernames,
        fileId: fileId,
        name: encryptedRawFile.name,
        type: encryptedRawFile.type,
        size: encryptedRawFile.size,
      };
      console.log('data', data);
      
      //Add new file to local DB
      const newDocumentId = await db.files.add(data);

      //Check if user has an IPFS hash stored on the blockchain
      const currentIPFS = await checkUserIPFS(window.accountId);
      console.log('currentIPFS', currentIPFS);

      if(currentIPFS && /[a-zA-Z]/g.test(currentIPFS)) {
        await deleteFileFromIPFS(currentIPFS);
      }else if(currentIPFS) {
        await deleteFileFromMachina(window.accountId, currentIPFS);
      }

      setProgressBarData({message: 'Setting permissions', percentage: 80});
      //Encrypt user file but send current document ID so that it doesn't include it in new file - it will be deleted after confirmation of sharing
      const file = await encryptUserFile(myUsername, document.id);
      console.log('USER FILE', file);
      //Upload user file to IPFS
      // const wallet_ipfs = await uploadFileToIPFS(file, myUsername, fileId);
      const wallet_ipfs = await uploadFileToMachina(file, myUsername, (Date.now() + 1).toString());

      console.log('wallet_ipfs', wallet_ipfs);
      
      setProgressBarData({message: 'Setting permissions', percentage: 90});
      //Update blockchain with new file data
      console.log('old contract_cid', oldCid);
      console.log('new contract_cid', data.cid_contract);
      await addUserToContract(usernames, oldCid, data.cid_contract, wallet_ipfs, document.id, newDocumentId);
      isErrorResponse = false;

      console.log('users', fileUsers);
      const addedUsers = fileUsers.map((u) => {
        u.addedToFile = true;
        return u;
      });
      console.log('addedUsers', addedUsers);
      setCurrentUsers(addedUsers);

      openModalOnRedirect(document.id + '|' + newDocumentId);

      console.log('file updated', document);
    }
  };

  //Change user permissions
  const setUserAccess = async (checked, user, permissionType) => {
    if(checked) {
      setCurrentUsers((prev) => {
        return prev.map((item) => {
          if(item.value === user.value) {
            //If user gave track permission, give download permission
            if(permissionType === 'read') {
              item.write = true;
            }
            item[permissionType] = true;
          }
          return item;
        });
      });
    }else {
      setCurrentUsers((prev) => {
        return prev.map((item) => {
          if(item.value === user.value) {
            item[permissionType] = false;
            //If user removed download permission unchecked track permission
            if(permissionType === 'write' && item.read === true) {
              item.read = false;
            }
          }
          return item;
        });
      });
    }
    setNewlyAddedUsers((prev) => {
      // Check if the user is already in the array
      const userExists = prev.some((item) => item.value === user.value);
      if (userExists) {
        // If the user is in the array, update them
        return prev.map((item) => {
          if (item.value === user.value) {
            return user;
          }
          return item;
        });
      } else {
        // If the user is not in the array, add them
        return [...prev, user];
      }
    });
  };

  const onUserSelect = (value) => {
    console.log("Selected: ", value);
    setCurrentUsers((prev) => [...prev, value]);
    setNewlyAddedUsers((prev) => [...prev, value]);
    setAvailableUsers((prev) => prev.filter((option) => option.value !== value.value));
    setProgressBarData({message: '', percentage: 0});
  };

  const removeUserFromList = (user) => {
    console.log('removeUserFromList', user);
    if(isProcessing) {
      return;
    }
    setCurrentUsers((prev) => prev.filter((option) => option.value !== user.value));
    setNewlyAddedUsers((prev) => prev.filter((option) => option.value !== user.value));
    setAvailableUsers((prev) => [...prev, user]);
  }

  const openModalOnRedirect = async (documentId) => {

    const documents = documentId.split("|");
    console.log('documents: ', documents);

    if(documents[1] === 'removeUser') {
      setSuccessMessage('Access revoked!');
      return;
    }
    let deleteDocumentId = documents[0];
    let saveDocumentId = documents[1];
    
    if(isErrorResponse) {
      deleteDocumentId = documents[1];
      saveDocumentId = documents[0];
    }
    console.log('deleteDocumentId: ', deleteDocumentId);
    console.log('saveDocumentId: ', saveDocumentId);
    const deleteFile = await db.files.where({id: parseInt(deleteDocumentId)}).first();
    console.log('deleteFile: ', deleteFile);

    if(deleteFile.cid_private) {
      if(/[a-zA-Z]/g.test(deleteFile.cid_private)) {
        await deleteFileFromIPFS(deleteFile.cid_private);
      }else {
        await deleteFileFromMachina(myUsername, deleteFile.cid_private);
      }
      console.log('RAW file deleted from ipfs');
    }

    if(deleteFile.cid_pdf) {
      if(/[a-zA-Z]/g.test(deleteFile.cid_pdf)) {
        await deleteFileFromIPFS(deleteFile.cid_pdf);
      }else {
        await deleteFileFromMachina(myUsername, deleteFile.cid_pdf);
      }
      console.log('PDF file deleted from ipfs');
    }

    const dFile = await db.files.where({id: parseInt(deleteDocumentId)}).delete();
    console.log('dFile: ', dFile);

    const transferFile = await db.files.where({id: parseInt(saveDocumentId)}).first();
    
    const oldUsers = deleteFile.users.filter(user => transferFile.users.includes(user));
    const newUsers = transferFile.users.filter(user => !deleteFile.users.includes(user));
    console.log('transferFile: ', transferFile);
    setCurrentDocument(transferFile);
    
    setProgressBarData({message: 'Access granted.', percentage: 100});

    transferFile.users.map((user) => {
      const rawUser = user.split("|");
      return rawUser[0];
    });
    console.log('TRANSFER FILE ID: ', transferFile.fileId)
    if(!isErrorResponse) {
      socket.emit('grant_user_access', {
        fileId: transferFile.fileId,
        oldUsers: oldUsers.toString(),
        newUsers: newUsers.toString(),
        owner: transferFile.owner,
        cid_private: transferFile.cid_private ? transferFile.cid_private : null,
        cid_pdf: transferFile.cid_pdf ? transferFile.cid_pdf : null,
        cid_contract: transferFile.cid_contract ? transferFile.cid_contract : null,
        old_cid_private: deleteFile.cid_private ? deleteFile.cid_private : null,
        old_cid_pdf: deleteFile.cid_pdf ? deleteFile.cid_pdf : null,
        name: transferFile.name,
        type: transferFile.type,
        size: transferFile.size,
      });
      const allUserFiles = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
      setUploadedFiles(allUserFiles);
      setNewlyAddedUsers([]);
      setIsProcessing(false);
    }
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#000',
      };
    },
  };


  return (
    <Dialog open={true} handler={handleClose} className="bg-transparent shadow-none" size="sm">
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="header-wrap">
            <p className="title">Set permissions for:</p>
            <p className="file-name">{document.name}</p>
          </div>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="user-wrapper">
            <p className="shared-with-label">Shared with:</p>
            {
              currentUsers.length > 0 ?
                currentUsers.map((user, index) => {
                  let disabledWatermark = false;
                  let disabledRead = false;
                  let viewFlag = false;

                  //Check if file type is possible to convert to pdf
                  const fileExtension = document.name.split('.').pop();

                  //If viewer doesn't support file type and user has no permissions, stop the process
                  if(viewerEnabledFormats.includes(fileExtension) || document.type.search('image') >= 0 || document.type.search('video') >= 0 || document.type.search('audio') >= 0) {
                    viewFlag = true;
                  }

                  if(enableTrackingFormats.includes(fileExtension)) {
                    disabledRead = true;
                    disabledWatermark = true;
                  }

                  const userId = user.value.length >= 32 ? user.value.slice(0,6) + '...' + user.value.slice(-4) : user.value;
                  return (
                    <Fragment key={index}>
                      <div className="shared-with-wrap">
                          
                          <p className="user-info">{userId}</p>
                          <div className="file-access-wrap">
                            {
                              viewFlag &&
                              <div className={viewFlag ? "checkbox-wrap filled" : "checkbox-wrap"}>
                                <label>
                                  <input type="checkbox" name="read" className={viewFlag ? "read fill-checkbox" : "btn-checkbox"} disabled={true} checked={viewFlag} />
                                  <span>View</span> 
                                </label>
                              </div>
                            }
                            {
                              disabledRead &&
                                <div className={user.read ? "checkbox-wrap filled" : "checkbox-wrap"}>
                                  <label>
                                    <input type="checkbox" name="read" className={user.read ? "read fill-checkbox" : "btn-checkbox"} disabled={!disabledRead} checked={user.read} onChange={(e) => setUserAccess(e.target.checked, user, 'read')} />
                                    <span>Track</span> 
                                  </label>
                                </div>
                            }
                            {
                              disabledWatermark &&
                                <div className={user.download ? "checkbox-wrap filled" : "checkbox-wrap"}>
                                  <label>
                                    <input type="checkbox" name="download" className={user.download ? "download fill-checkbox" : "download btn-checkbox"} disabled={!disabledWatermark} checked={user.download} onChange={(e) => setUserAccess(e.target.checked, user, 'download')} />
                                    <span>Watermark</span> 
                                  </label>
                                </div>
                            }

                            <div className={user.write ? "checkbox-wrap filled" : "checkbox-wrap"}>
                              <label>
                                <input type="checkbox" name="write" className={user.write ? "write fill-checkbox" : "write btn-checkbox"} checked={user.write} onChange={(e) => setUserAccess(e.target.checked, user, 'write')} />
                                <span>Download</span> 
                              </label>
                            </div>
                          
                            {
                              //If user has been added to file, show revoke button, else show remove from list button
                              user.addedToFile ?
                                <div className="revoke-label" disabled={isProcessing} onClick={() => revokeUserAccess(user, document.fileId)}>
                                  <label>
                                    <span>Revoke</span> 
                                  </label>
                                </div>
                              :
                                <div className="revoke-label" disabled={isProcessing} onClick={() => removeUserFromList(user)}>
                                  <label>
                                    <span>Remove</span> 
                                  </label>
                                </div>
                            }
                          </div>
                          
                        </div>
                    </Fragment>
                  )
                })
              :
                <p className="shared-with-label">No users have access to this document</p>
            }
          </div>
          <div className="share-wrap">
            <p className="share-label wallet-tooltip">Add people:</p>
            <Select 
              options={availableUsers} 
              onChange={(values) => onUserSelect(values)}
              value={[]}
              styles={colourStyles}
              isDisabled={isProcessing}
            />
          </div>
        </DialogBody>
        <DialogFooter>
          {
            progressBarData.percentage > 0 || successMessage ?
            (
              <>
                <div className="progress-bar-wrap">
                <img className="permission-loader" src={successMessage || progressBarData.percentage === 100 ? successIcon : permissionsLoader} alt="loader" />
                  {
                    successMessage ?
                      (
                        <>
                          <div className="success-status">
                            <p className="message success-message">{successMessage}</p>
                            <p className="notification">You can close the window.</p>
                          </div>
                        </>
                      )
                      
                    :
                      <>
                        <div className="success-status">
                          <p className={progressBarData.percentage !== 100 ? "message" : "message success-message"}>{progressBarData.message}</p>
                          {
                            (progressBarData.message === 'Access granted.' || progressBarData.message === 'Access revoked!') &&
                              <p className="notification">You can close the window.</p>
                          }
                        </div>
                      </>
                      
                  }
                </div>
              </>
            )
            :
            (
              <button className="btn-primary" disabled={submitButtonDisabled} onClick={() => grantUserAccess(currentUsers, document.fileId)}>
                Confirm
              </button>
            )
          }
          
        </DialogFooter>
      </ModalWrapper>
    </Dialog>
  )
}