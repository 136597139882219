export const disableRightClick = () => {
  // document.body.oncopy = function(){return false}; 
  // document.body.oncontextmenu = function(){return false}; 
  // document.body.onselectstart = document.body.ondrag = function(){
  //     return false;
  // }
  //  document.onkeydown = function(event) {
  //   if((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 83) {
  //     event.preventDefault();
  //   }
  //   if((event.ctrlKey == true || event.metaKey == true) && event.code == 83) {
  //     event.preventDefault();
  //   }
  // }
};

export const blurPage = () => {
  document.addEventListener('keyup', (e) => {
    if (e.key == 'PrintScreen') {
      navigator.clipboard.writeText('');
    }
  });
  
  document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key == 'p') {
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
  });
};

export const toBlur = () => {
  // document.querySelector('.file-viewer #react-doc-viewer').className = 'modal-body blur-section';
  // console.log(document.querySelector('.modal-body').classList);
};

export const toClear = () => {
  // document.querySelector('.file-viewer #react-doc-viewer').className = 'modal-body';
}

export const disablePrint = () => {
  var c=document.createElement("span");
  c.style.display="none";
  c.style.postion="absolute";
  c.style.background="#000";
  var first=document.body.firstChild;
  document.body.insertBefore(c,first);
  c.setAttribute('width', document.body.scrollWidth);
  c.setAttribute('height', document.body.scrollHeight);
  c.style.display="block";
 
  var cssNode3 = document.createElement('style'); 
  cssNode3.type = 'text/css'; 
  cssNode3.media = 'print'; 
  cssNode3.innerHTML ='body{display:none}';
  document.head.appendChild(cssNode3); 	
};