import styled from "styled-components";
import { useUserFilesContext } from "../context/UserFilesProvider";
import { officeRestrictions, rawFormats, viewerEnabledFormats } from "../inc/constants";
import { Fragment, useState } from "react";
import cancelButton from '../assets/images/cancel-x.png';
import { useGlobalContext } from "../context/GlobalProvider";
import { decryptMessage } from "../inc/encryption";
import { db } from "../inc/database";
import { markPdf, sendMetrics } from "../inc/functions";
import PdfViewer from "./viewers/PdfViewer";
import { saveAs } from "file-saver";
import VideoViewer from "./viewers/VideoViewer";
import ModelViewer from "./viewers/ModelViewer";
import redCancelBtn from '../assets/images/cancel-x-red.png';
import ExcelFileViewer from "./viewers/ExcelFileViewer";

const FileActionButtonsWrap = styled.div`
  padding: 5px 5px 5px 0;

  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .btn-primary { 
      margin-right: 16px;
      margin-bottom: 5px;
    }
  }

  .close-btn {
    width: 24px;
    height: 24px;
  }

  .cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    font-weight: 600;

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export default function FileActionButtons({ currentFile }) {

  const { downloads, setDownloads } = useUserFilesContext();

  const { myUsername } = useGlobalContext();

  const [currentViewingFile, setCurrentViewingFile] = useState(null);
  const [openFileViewer, setOpenFileViewer] = useState(false);



  const removeFile = async (fileId) => {
    console.log('removeFile', fileId);
    //Check if anonymous download
    if(myUsername) {
      await db.downloadedFiles.where({fileId: fileId}).delete();
    }
    console.log('setDownloads', downloads);
    setDownloads((prevState) => {
      console.log('prevState', prevState);
      const newState = prevState.filter( el => {
        return el.fileId !== fileId;
      })
      console.log('newState', newState);
      return [...newState];
    });
  };

  const downloadFileSolo = (file) => {
    saveAs(file.file, file.file.name);
  };

  const viewFile = async (file) => {
    console.log('OPEN FILE viewFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(file.file, file.fileName, myUsername);
    }

    let permissions = ['view'];
    if(file.watermarkPermissions) {
      permissions.push('watermark');
    }
    const data = {
      name: fileData.fileName,
      size: fileData.file.size,
      sharedTo: myUsername ? myUsername : file.user,
      sharedBy: fileData.owner,
      date: new Date().getTime(),
      permissions: permissions,
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('file');
  };

  const viewVideoFile = async (file) => {
    console.log('OPEN FILE video viewFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    let permissions = ['view'];
    if(fileData.watermarkPermissions) {
      permissions.push('watermark');
    }
    const data = {
      name: fileData.fileName,
      size: file.file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.owner,
      date: new Date().getTime(),
      permissions: permissions,
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('video');
  };

  const viewModelFile = async (file) => {
    console.log('OPEN FILE ModelFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    let permissions = ['view'];
    if(fileData.watermarkPermissions) {
      permissions.push('watermark');
    }
    const data = {
      name: fileData.fileName,
      size: file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.owner,
      date: new Date().getTime(),
      permissions: permissions,
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('model');
  };

  const viewExcelFile = async (file) => {
    console.log('OPEN FILE viewExcelFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    const data = {
      name: fileData.fileName,
      size: file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.owner,
      date: new Date().getTime(),
      permissions: ['view'],
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('excel');
  };

  const downloadFile = async (file) => {
    console.log('downloadFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    if(fileData.file.type === 'application/pdf') {
      markPdf(fileData.file, fileData.watermarkPermissions, fileData.readPermissions, fileData.owner).then((pdf) => {
        const data = {
          name: fileData.file.name,
          size: fileData.file.size,
          sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousUsername'),
          sharedBy: fileData.owner,
          date: new Date().getTime(),
          permissions: ['raw'],
          transferType: '/',
          action: 'Download'
        }
        sendMetrics(data);
        //DOWNLOAD FILE
        saveAs(pdf, fileData.file.name);
      });
    }else {
      const data = {
        name: fileData.fileName,
        size: file.size,
        sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
        sharedBy: fileData.owner,
        date: new Date().getTime(),
        permissions: ['raw'],
        transferType: '/',
        action: 'Download'
      }
      sendMetrics(data);
      //DOWNLOAD FILE
      saveAs(fileData.file, fileData.fileName);
    }
  };


// console.log('currentFile', currentFile)

  const percentage = currentFile['percentage'];
  const isDownloading = currentFile.downloading;
  const fileId = currentFile.fileId;

  const fileExtension = currentFile.fileName.split('.').pop();
  let viewerEnabled = false;
  if(viewerEnabledFormats.includes(fileExtension) || currentFile.file.type.search('image') >= 0 || currentFile.file.type.search('video') >= 0 || currentFile.file.type.search('audio') >= 0) {
    viewerEnabled = true;
  }
  if(officeRestrictions.includes(fileExtension) && currentFile['rawPermissions']) {
    viewerEnabled = false;
  }

  let isModelFile = false;
  if (currentFile['fileName'].split('.').pop() === 'obj' || currentFile['fileName'].split('.').pop() === 'glb') {
    isModelFile = true;
  }

  return (
    <FileActionButtonsWrap>
      {
        openFileViewer === 'file' && <PdfViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
      {
        openFileViewer === 'video' && <VideoViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
      {
        openFileViewer === 'model' && <ModelViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
       {
        openFileViewer === 'excel' && <ExcelFileViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
      {
        <Fragment>
            {
              isDownloading ?
                percentage === 100 &&
                  currentFile['verified'] ?
                    <Fragment>
                      <div className="btn-wrap">
                      {
                        currentFile['anonymous'] ?
                            <div className="btn-wrap">
                              <button onClick={() => downloadFileSolo(currentFile)} className="btn-primary">Save</button>
                            </div>
                          :
                          <Fragment>
                            {
                              <Fragment>
                                  {
                                    viewerEnabled 
                                    && currentFile['file'].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    && ( currentFile['file'].type.search('video') >= 0 || currentFile['file'].type.search('audio') >= 0 )
                                    && !isModelFile
                                      && <button onClick={() => viewVideoFile(currentFile)} className="btn-primary">View</button>
                                  }
                                  {
                                    viewerEnabled 
                                    && currentFile['file'].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    && isModelFile
                                      && <button onClick={() => viewModelFile(currentFile)} className="btn-primary">View</button>
                                  }
                                  {
                                    viewerEnabled 
                                    && currentFile['file'].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    && (currentFile['file'].type.search('video') < 0 && currentFile['file'].type.search('audio') < 0)
                                    && !isModelFile
                                      && <button onClick={() => viewFile(currentFile)} className="btn-primary">View</button>
                                  }
                                  {
                                    viewerEnabled 
                                    && currentFile['file'].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    && (currentFile['file'].type.search('video') < 0 && currentFile['file'].type.search('audio') < 0)
                                    && !isModelFile
                                      && <button onClick={() => viewExcelFile(currentFile)} className="btn-primary">View</button>
                                  }
                                  {
                                    currentFile['rawPermissions'] &&
                                      <button onClick={() => downloadFile(currentFile)} className="btn-primary">Save</button>
                                  }
                              </Fragment>
                            }
                          </Fragment>
                      }
                        <button className="cancel-btn" onClick={() => removeFile(fileId)} >
                          <img className="cancel-icon" src={redCancelBtn} alt="remove button" />
                          Remove
                        </button>
                      </div>
                    </Fragment>
                  :
                  <Fragment>
                    <p className="verify-error">File couldn't be verified</p>
                    <button className="close-btn" onClick={() => removeFile(fileId)} >
                      <img className="cancel-icon" src={redCancelBtn} alt="remove button" />
                    </button>
                  </Fragment>
              :
              ''
            }
          </Fragment>
        }
    </FileActionButtonsWrap>
  )
}