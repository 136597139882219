import { useUserFilesContext } from "../../context/UserFilesProvider";
import { formatBytes } from "../../inc/functions";

import shareIcon from '../../assets/images/share.png';
import generateIcon from '../../assets/images/generate-link.png';
import trashIcon from '../../assets/images/trash.png';
import { Input, Typography } from "@material-tailwind/react";
import { Fragment, useEffect, useState } from "react";
import GenerateShareLinkModal from "../../modals/GenerateShareLinkModal";
import ShareFileModal from "../../modals/ShareFileModal";
import { db } from "../../inc/database";
import DeleteFileModal from "../../modals/DeleteFileModal";
import UploadFile from "../UploadFile";

const { default: styled } = require("styled-components")

const UploadsWrap = styled.div`

  .background-gray {
    background: #283134;
  }

  .row-wrap {
    padding: 0 15px;

    @media (max-width: 768px) {
      padding-bottom: 15px;
    }
  }

  .title {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      padding-left: 12px;
    }
  }

  .table-header-wrap {
    color: #C0CDCF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .td-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .table-header {
    color: #C0CDCF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    svg {
      display: inline-block;
    }
  }

  .content-wrap {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    min-height: 95px;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 768px) {
      min-height: 0;
      margin-bottom: 5px;
      padding: 12px;
    }

    .file-info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
      
      .owner {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .action-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 6px 6px 6px 0;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    button {
      margin-bottom: 5px;
    }
  }

  .progress-bar-wrap {
    display: flex;
    width: 100%;
    align-items: center;

  
    .progress-bar {
      width: 100%;
      height: 10px;
      border-radius: 24px;
      background: var(--Bg_6, #404B51);
    }
  
    .percentage {
      color: #FFF;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;
      display: flex;
      align-items: center;
      margin-left: 5px !important;
    }
  }

  .search-wrap {
    margin-left: auto;

    > div {
      min-width: 0;
    }
  }

  .no-files {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;

    @media (max-width: 768px) {
      padding-left: 12px;
    }
  }

  .btn-secondary {
    height: 50px;
    margin-left: 13px;

    &.delete-btn {
      background: #62322F;
    }
  
    .download-icon {
      width: 16px;
      height: 16px;
    }
  }  
`;

export default function Uploads() {

  const { uploads } = useUserFilesContext();

  const [currentOpenModal, setOpenModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);

  const TABLE_HEAD = ["Name", "Date", "Size", "Search"];

  const [tableData, setTableData] = useState(uploads);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  
  const urlParams = new URLSearchParams(window.location.search);
  let addedUser = urlParams.get("signMeta");
  const userRejected = urlParams.get("errorCode");
  
  useEffect(() => {
    console.log('addedUser: ', addedUser)
    if(addedUser) {
      if(userRejected) {
        window.history.replaceState(null, '', window.location.pathname);
        return;
      }
      const documents = addedUser.split("|");
      if(documents.length === 2) {
        if(documents[1] !== 'delete') {
          let docId = parseInt(documents[1]);
          if(documents[1] === 'removeUser') {
            docId = parseInt(documents[0]);
          }
          db.files.where({id: docId}).first().then((file) => {
            setCurrentDocument(file);
            setOpenModal('share');
          });
        }else {
          console.log('documents[0]', documents[0]);
          db.files.where({id: parseInt(documents[0])}).first().then((file) => {
            file.isDeleted = true;
            setCurrentDocument(file);
            setOpenModal('delete');
          });
        }
      }
      addedUser = null;
    }
  }, []);

  useEffect(() => {
    setTableData(uploads);
  }, [uploads]);

  const openDocumentModal = (document, modal) => {
    setCurrentDocument(document);
    setOpenModal(modal);
  }

   // Function to handle sorting
   const requestSort = (key) => {
    if(key === 'Search') {
      return;
    }
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    console.log('sortConfig', sortConfig)
  };

  // Function to search the table
  const searchTable = () => {
    return tableData.filter((item) =>
      Object.values(item).some((value) => {
        if(value) {
          return value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        }
        return false;
      })
    );
  };

  const sortedData = searchTable().sort((a, b) => {
    if (sortConfig.key) {
      let currentKey = sortConfig.key.toLowerCase();
      console.log('currentKey', sortConfig.key)

      if(currentKey === 'date') {
        currentKey = 'fileId';
      }
      if(currentKey === 'Size') {
        currentKey = 'size';
      }
        
      const aValue = a[currentKey].toString();
      const bValue = b[currentKey].toString();

      console.log('aValue', aValue)
      console.log('bValue', bValue)
      
      if (aValue && bValue) {
        const order = sortConfig.direction === 'asc' ? 1 : -1;
        if (currentKey === 'size') {
          // Convert to numbers before comparing
          return (Number(aValue) - Number(bValue)) * order;
        } else {
          // For other keys, convert to string and use localeCompare
          return aValue.toString().localeCompare(bValue.toString()) * order;
        }
      }
    }
    return 0;
  });

  const SortIcon = ({ direction }) => {
    return direction === 'asc' ?
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
    :
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg> 
    ;
  };

 
  
  return (
    <Fragment>
      {
        currentOpenModal === 'delete' &&
          <DeleteFileModal handleClose={() => setOpenModal('')} document={currentDocument} />
      }
      {
        currentOpenModal === 'generate-link' && 
          <GenerateShareLinkModal handleClose={() => setOpenModal('')} fileId={currentDocument.fileId} />
      }
      {
        currentOpenModal === 'share' && 
          <ShareFileModal handleClose={() => setOpenModal('')} document={currentDocument} setCurrentDocument={setCurrentDocument} users={currentDocument ? currentDocument.users : []} />
      }
      <UploadsWrap className="grid grid-flow-row overflow-scroll">
        <h2 className="title row-start-1">Uploads</h2>
        <div className="table-wrap">
            <div className="table-header-wrap grid grid-cols-8 grid-row-flow mobile-hide-grid" >
              {
                TABLE_HEAD.map((head, index) => {
                    let className = "";
                    if(head === 'Name') {
                      className="col-span-4 lg:col-span-3 td-wrap";
                    }else if(head === 'Date' || head === 'Size') {
                      className="col-span-1 td-wrap";
                    }else if(head === 'Search') {
                      className="col-span-2 col-start-9 lg:col-span-3 lg:col-start-7 td-wrap";
                    }

                    let currentDirection = 'asc';
                    if(sortConfig.key === head) {
                      currentDirection = sortConfig.direction;
                    }

                    return (
                      <div key={head} className={className} onClick={() => requestSort(head)} >
                        <div
                          variant="small"
                          color="blue-gray"
                          className={index === TABLE_HEAD.length - 1 ? "table-header search-wrap" : "table-header"}
                        >
                          
                          {index === TABLE_HEAD.length - 1 ? (
                            <Input
                              label="Search"
                              type="text"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              color="white"
                            />
                          ):
                            <>
                              {head}{" "}
                              <SortIcon direction={currentDirection} />
                            </>
                          }
                        </div>
                      </div>
                    );
                })
              }
            </div>
            <div id="upload-section">
            {
              sortedData.length === 0 ? (
                <p className="no-files">You currently don't have any uploaded file</p>
              )
              :
                sortedData.map((document, index) => {

                  const fileDate = new Date(parseInt(document.fileId)).toLocaleString().split(',')[0];
                  const trClass = index % 2 === 0 ? 'background-gray grid grid-cols-8 grid-row-flow row-wrap' : 'grid grid-cols-8 grid-row-flow row-wrap';

                  const fillerStyles = {
                    height: '100%',
                    width: `${document.percentage}%`,
                    background: '#06C5C5',
                    borderRadius: '24px',
                  }
                  let showProgress = true;
                  if('percentage' in document === false) {
                    showProgress = false;
                  }

                  if(document.percentage && document.percentage === 100) {
                    showProgress = false;
                  }
                  return (
                    <div key={index} className={trClass}>
                        <div className="col-span-4 lg:col-span-3 content-wrap">
                          <div className="file-info-wrap">
                            <p className="name">{document.name}</p>
                          </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1 content-wrap">
                            <p className="date">{fileDate}</p>
                        </div>
                        <div className="col-span-2 lg:col-span-1 content-wrap">
                            <p className="size">{formatBytes(document.size)}</p>
                        </div>
                        <div className="col-span-8 lg:col-span-3 action-btn-wrap">
                          {
                          !showProgress ? (
                            <Fragment>
                              <button className="btn-secondary" onClick={() => openDocumentModal(document, 'share')}>
                                <img className="download-icon" src={shareIcon} alt="share-icon" />
                                <span>{document.users.length > 0 ? 'Edit sharing' : 'Share'}</span>
                              </button>
                              <button className="btn-secondary" onClick={() => openDocumentModal(document, 'generate-link')}>
                                <img className="download-icon" src={generateIcon} alt="generate-icon" />
                                <span>Generate Link</span>
                              </button>
                              <button className="btn-secondary delete-btn" onClick={() => openDocumentModal(document, 'delete')}>
                                <img className="download-icon" src={trashIcon} alt="download-icon" />
                              </button>
                            </Fragment> )
                            :
                            (  
                              <div className="progress-bar-wrap">
                                <div className="progress-bar">
                                  <p style={fillerStyles}></p>
                                </div>
                                <p className="percentage">
                                  {`${document.percentage}%`}
                                </p>
                              </div>
                            )
                          }
                        </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
      </UploadsWrap>
    </Fragment>
  );
}
