import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from './GlobalProvider';
import { db } from '../inc/database';
import { getFileAccessByUser } from '../assets/near/utils';
import { getIPFSMetadata } from '../inc/storage';
import { getonMachinaMetadata } from '../inc/storageOnMachina';


const UserFilesContext = createContext();

export const useUserFilesContext = () => useContext(UserFilesContext);

export const UserFilesProvider = ({ children }) => {

  const { isLoggedIn, myUsername } = useGlobalContext();
  const jwtToken = localStorage.getItem('jwtToken');

  //Global variable for the files user uploaded
  const [uploads, setUploadedFiles] = useState([]);
  //Global variable for the files user can download
  const [availableFiles, setAvailableFiles] = useState([]);
  //Global variable for the files user downloaded
  const [downloads, setDownloads] = useState([]);
  //Global variable for the files user is currently seeding
  const [currentTransfers, setCurrentTransfers] = useState([]);

  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [syncModalContent, setSyncModalContent] = useState('');

  //PEERJS VARIABLES
  const peerInstanceHost = useRef([]);
  const peerInstanceReceiver = useRef([]);

  useEffect(() => {
    if(isLoggedIn && jwtToken && myUsername) {
      // GET CURRENT DOWNLOADED FILES
      getCurrentDownloadedFiles();
    }
  }, [jwtToken, isLoggedIn, myUsername]);

   const getCurrentDownloadedFiles = async () => {
    const filesIds = await getFileAccessByUser(myUsername);
    console.log('BC files', filesIds);

    const currentDownloadedFiles = await db.downloadedFiles.toArray();
    console.log('currentDownloadedFiles', currentDownloadedFiles);

    if(currentDownloadedFiles.length > 0) {
      const allFileIds = [];
      for(const fileCid of filesIds) {
        const fileParts = fileCid.split('|');
        if(fileParts[0] !== undefined && fileParts[0] !== 'null') {
          allFileIds.push(fileParts[0]);
        }
        if(fileParts[1] !== undefined && fileParts[1] !== 'null') {
          allFileIds.push(fileParts[1]);
        }
      }
      const removeFromDB = currentDownloadedFiles.filter(obj => !allFileIds.some(fileId => fileId === obj.fileId)).map(obj => obj.fileId);
      let currentDownloads = currentDownloadedFiles;
      if(removeFromDB.length > 0) {
        removeFromDB.forEach(async fileId => {
          await db.downloadedFiles.where({fileId: fileId}).delete();
        });
        currentDownloads = currentDownloadedFiles.filter(file => removeFromDB.includes(file.fileId));
      }
      currentDownloads = currentDownloads.map(file => {
        file.downloading = true;
        file.percentage = 100;
        file.user = file.owner;
        file.anonymous = false;
        file.verified = true;
        return file;
      });
      console.log('currentDownloads: ', currentDownloads);
      setDownloads([...currentDownloads]);
    }
    let fileOwners = new Set();
    let currentAvailableFiles = [];
    for(const fileId of filesIds) {
      let metadata;
      const cid_private = fileId.split('|')[0];
      const pdf_cid = fileId.split('|')[1];
      const owner = fileId.split('|')[2];
      fileOwners.add(owner);
      const permissions = fileId.split('|')[3];
      if(cid_private && /[a-zA-Z]/g.test(cid_private)) {
        metadata = await getIPFSMetadata(cid_private);
        console.log('metadata', metadata);
        if(metadata) {
          const fileParts = metadata.description.split('|');
          const file = {
            fileId: fileParts[2],
            name: metadata.name,
            owner: owner,
            size: fileParts[3],
            type: fileParts[0],
            readPermission: permissions[0] === 't' ? true : false,
            waterMarkPermission: permissions[1] === 't' ? true : false,
            rawPermissions: permissions[2] === 't' ? true : false,
            cid_private: cid_private,
            cid_pdf: pdf_cid,
            cid_contract: cid_private + '|' + pdf_cid
          };
          file.isOriginalPdf = false;
          if(file.type === 'application/pdf') {
            file.isOriginalPdf = true;
          }
          currentAvailableFiles.push(file);
        }
      }else {
        metadata = await getonMachinaMetadata(cid_private, owner);
        console.log('metadata', metadata);
        if(metadata) {
          const fileParts = metadata.split('|');
          const file = {
            fileId: fileParts[2],
            name: fileParts[4],
            owner: owner,
            size: fileParts[3],
            type: fileParts[0],
            readPermissions: permissions[0] === 't' ? true : false,
            waterMarkPermission: permissions[1] === 't' ? true : false,
            rawPermissions: permissions[2] === 't' ? true : false,
            cid_private: cid_private,
            cid_pdf: pdf_cid,
            cid_contract: cid_private + '|' + pdf_cid
          };
          file.isOriginalPdf = false;
          if(file.type === 'application/pdf') {
            file.isOriginalPdf = true;
          }
          currentAvailableFiles.push(file);
        }
      }
    }

    console.log('currentAvailableFiles', currentAvailableFiles);
    setAvailableFiles(currentAvailableFiles)
    const userFiles = await window.contract.get_files_by_user({user_id: myUsername});
    console.log('BC userFiles', userFiles);
    const userFilesDB = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
    console.log('userFilesDB', userFilesDB);
    if(userFilesDB.length !== userFiles.length) {
      setSyncModalContent('It seems that your local state is different from the blockchain state. Do you want to sync your local state with the blockchain state?');
      setOpenSyncModal(true);
    }
  };

  return (
    <UserFilesContext.Provider value={{ availableFiles, setAvailableFiles, uploads, setUploadedFiles, downloads, setDownloads, peerInstanceHost, peerInstanceReceiver, currentTransfers, setCurrentTransfers, openSyncModal, setOpenSyncModal, syncModalContent, setSyncModalContent }}>
      {children}
    </UserFilesContext.Provider>
  );
};