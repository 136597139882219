import { useGlobalContext } from "../context/GlobalProvider";
import EncryptionKeyModal from "../modals/EncryptionKeyModal";
import Homepage from "../pages/Homepage";
import LandingPage from "../pages/LandingPage";
import Navbar from "./Navbar";



const PageWrapper = () => {
  const { isLoggedIn, openKeyModal} = useGlobalContext();
  console.log("isLoggedIn", isLoggedIn);
  return (
      <>
        {
          isLoggedIn ? 
          <>
            {  
              !openKeyModal ?
                <>
                  <Navbar />
                  <Homepage /> 
                </>
              :
                <EncryptionKeyModal />
            }
          </>
          :
          <LandingPage />
        }
      </>
  );
}

export default PageWrapper;