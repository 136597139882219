import { PDFDocument, PDFHexString, PDFName } from 'pdf-lib';
import * as bs58 from 'bs58';
import nacl from 'tweetnacl';
import { decryptPrivateKey } from './encryption';
nacl.util = require('tweetnacl-util');

export const signMessage = async (key, data) => {
  const signedMessage = await key.sign(data, window.walletConnection.getAccountId(), window.account.connection.networkId);
  return {signedMessage: signedMessage, message: data};
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const generatePDF = async (officeFile, fileOwner, recipientsPublicKeys) => {
  // const officeFile = event.target.files[0];
  // console.log(officeFile);

  // Create a new FormData object
  const formData = new FormData();

  // Append the file to the FormData object
  formData.append('file', officeFile);

  // Perform the POST request
  return await fetch(process.env.REACT_APP_SERVER_URL + '/file/pdf-convert', {
    method: 'POST',
    body: formData
  })
  .then(response => response.json())
  .then(async data => {
    // Handle the response data
    console.log('Upload successful:', data);
    console.log('data.data', data.data);
    const uint8Array = new Uint8Array(data.data);
    console.log('uint8Array', uint8Array);
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    console.log('blob', blob);
    const newFile = new File([blob], 'pdf_modified.pdf', { type: 'application/pdf' });
    console.log('newFile', newFile);

    //LOAD PDF FILE
    const pdfDoc = await PDFDocument.load(await newFile.arrayBuffer(), { 
      updateMetadata: false
    });


    //Add recepients public keys to pdf metadata
    let publicKeys = '';
    for(const recepeint of recipientsPublicKeys) {
      publicKeys += recepeint.publicKey + ',';
    }
    console.log('publicKeys', publicKeys);
    pdfDoc['getInfoDict']().set(PDFName.of('pathFinderMetadataUser'), PDFHexString.fromText(publicKeys));
    pdfDoc['getInfoDict']().set(PDFName.of('pathFinderMetadataOwner'), PDFHexString.fromText(fileOwner));

    const pdfBytes = await pdfDoc.save();
    const fileNameWithoutFormat = officeFile.name.split('.').slice(0, -1).join('.');
    const waterMarkBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const waterMarkFile = new File([waterMarkBlob], fileNameWithoutFormat + '.pdf', { type: 'application/pdf' });

    return waterMarkFile;
    
  })
  // .catch(error => {
  //   // Handle any errors
  //   console.error('Error uploading file:', error);
  // });
}

export const sendMetrics = async (data) => {
  console.log('SENDING METRICS', data);
  let userAgent = navigator.userAgent;
  let browserName;
  if(userAgent.match(/chrome|chromium|crios/i)){
    browserName = "Chrome";
  }else if(userAgent.match(/firefox|fxios/i)){
    browserName = "Firefox";
  }  else if(userAgent.match(/safari/i)){
    browserName = "Safari";
  }else if(userAgent.match(/opr\//i)){
    browserName = "Opera";
  } else if(userAgent.match(/edg/i)){
    browserName = "Edge";
  }else{
    browserName="No browser detection";
  }

  let jwt;

  if(localStorage.getItem('jwtToken')) {
    jwt = localStorage.getItem('jwtToken');
  } else {
    jwt = localStorage.getItem('anyonmousToken');
  }
  console.log('jwt', jwt);
  if(jwt) {
    fetch('https://ipapi.co/json/')
      .then(res => res.json())
      .then(ip => {
        console.log('IP DATA: ', ip);
        console.log('METRICS DATA: ', data);
        data.ip = ip.ip;
        data.device = browserName;
        data.country = ip.country_name;
        data.city = ip.city;
    
        fetch(process.env.REACT_APP_SERVER_URL + '/users/add-metric', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt,
          },
          body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
          console.log('METRICS DATA: ', data);
        });
      });
    }else {
      console.log('No jwt token found');
    }
};

export const markPdf = async (file, watermarkPermissions, trackPermissions, fileOwner) => {

  console.log('markPdf file:', watermarkPermissions, trackPermissions, fileOwner);
     
  //LOAD PDF FILE
  const pdfDoc = await PDFDocument.load(await file.arrayBuffer(), { 
    updateMetadata: false
  });

  //Add recepient public key to pdf metadata
  // let key = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, window.walletConnection.getAccountId());
  // const privKeyUi8 = bs58.decode(key.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(privKeyUi8.slice(0, 32));
  const hostKeyPair = await decryptPrivateKey();
  const publicKey = nacl.util.encodeBase64(hostKeyPair.publicKey);

  if(trackPermissions) {
    pdfDoc['getInfoDict']().set(PDFName.of('pathFinderMetadataUser'), PDFHexString.fromText(publicKey));
    pdfDoc['getInfoDict']().set(PDFName.of('pathFinderMetadataOwner'), PDFHexString.fromText(fileOwner));
  }

  if(watermarkPermissions) {
    const pages = pdfDoc.getPages();
    const emblemImageBytes = await generateWaterMarkImage(publicKey);
    const image = await pdfDoc.embedPng(emblemImageBytes);
    const pngDims = image.scale(0.5);

    // loop throgh all pages and add watermark
    for (const page of pages) {
      const { width, height } = page.getSize();

      await page.drawImage(image, {
        x: width - pngDims.width,
        y: height - pngDims.height,
        width: pngDims.width,
        height: pngDims.height,
        opacity: 0.6,
      });
    }
  }
  
  const pdfBytes = await pdfDoc.save();

  const waterMarkBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  const waterMarkFile = new File([waterMarkBlob], file.name, { type: 'application/pdf' });

  return waterMarkFile;
};

const generateWaterMarkImage = async (publicKey) => {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Set the desired image width and height
  const width = 600;
  const height = 200;

  // Set the canvas dimensions
  canvas.width = width;
  canvas.height = height;

  // Set the desired text properties
  const fontSize = 20;
  const fontFamily = 'Arial';
  const fontWeight = 'bold';
  const textColor = 'black';

  // Set the text content
  const text = publicKey;

  // Set the text properties
  context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  context.fillStyle = textColor;

  // Calculate the text width and height
  const textHeight = fontSize;

  // Calculate the text position to center it
  const y = (height + textHeight) / 2;

  // Draw the text on the canvas
  context.fillText(text, 0, y);

  const img = canvas.toDataURL('image/png')
  return img;
}