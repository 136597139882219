import { useEffect, useState } from "react";
import { useGlobalContext } from "../context/GlobalProvider";
import { useUserFilesContext } from "../context/UserFilesProvider";
import { db } from "../inc/database";
import { deleteFileFromIPFS, uploadFileToIPFS } from "../inc/storage";
import { removeFileContract } from "../assets/near/utils";
import cancelButton from '../assets/images/cancel-x.png';
import styled from "styled-components";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { deleteFileFromMachina } from "../inc/storageOnMachina";

const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px; 
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-title-wrap {

        .title {
          color: #FFF;
          font-family: Open Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 155%;
        }
        .cancel-icon {
          width: 24px;
          height: 24px;
        }
      }
      
    }

    .progress-bar-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 10px;
    
      .progress-bar {
        width: 100%;
        height: 10px;
        background: transparent;
        border-radius: 10px;
      }
    
      .percentage {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        background: transparent;
        margin-left: 5px !important;
      }
    }

    .subtitle {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }

    .message {
      color: #30BD5C;
      margin-bottom: 24px;
    }

    .modal-footer {
      justify-content: center;
    }
  `;


export default function DeleteFileModal({handleClose, document}) {

  const { socket, myUsername } = useGlobalContext();
  const { setUploadedFiles } = useUserFilesContext();

  const [progressBarData, setProgressBarData] = useState({message: '', percentage: 0});


  useEffect(() => {
    if(document.isDeleted) {
      console.log('deleteDocument: ', document);
      setProgressBarData({message: 'Removing file from database...', percentage: 90});
      db.files.where('fileId').equals(document.fileId).delete().then(() => {
        socket.emit('delete_document', {fileId: document.fileId, documentUsers: document.users});
        db.files.where({owner: myUsername}).toArray().then((files) => {
          setUploadedFiles(files);
          setProgressBarData({message: 'File removed...', percentage: 100});
          window.history.pushState('object', '', window.location.pathname);
        });
      });
    }
  }, []);

  const deleteDocument = async () => {
    console.log('deleteDocument: ', document);
    setProgressBarData({message: 'Deleting file from IPFS...', percentage: 10});

    const file = await db.files.where('fileId').equals(document.fileId.toString()).first();
    if(file.cid_public) {
      console.log('deleteFileFromIPFS PUBLIC: ', file.cid_public);
      if(/[a-zA-Z]/g.test(file.cid_public)) {
        await deleteFileFromIPFS(file.cid_public);
      }else {
        await deleteFileFromMachina(myUsername, file.cid_public);
      }
    }
    if(file.cid_private) {
      console.log('deleteFileFromIPFS PRIVATE: ', file.cid_private);
      if(/[a-zA-Z]/g.test(file.cid_private)) {
        await deleteFileFromIPFS(file.cid_private);
      }else {
        await deleteFileFromMachina(myUsername, file.cid_private);
      }
      setProgressBarData({message: 'Updating user state...', percentage: 60});
      //CREATE JSON FILE WITH ALL FILES
      let walletIPFS = '';
      const fileUsers = await db.files.toArray();
      if(fileUsers.length > 0) {
        const jsn = JSON.stringify(fileUsers);
        const blob = new Blob([jsn], { type: 'application/json' });
        const file = new File([ blob ], 'file.json');
        const wallet_ipfs = await uploadFileToIPFS(file);
        walletIPFS = wallet_ipfs.ipnft;
      }
      
      setProgressBarData({message: 'Removing file from blockchain...', percentage: 80});
      console.log('removeFileContract: ', file.cid_contract, walletIPFS, file.id)
      await removeFileContract(file.cid_contract, walletIPFS, file.id);
    }
    setProgressBarData({message: 'Removing file from database...', percentage: 90});
    db.files.where('fileId').equals(document.fileId.toString()).delete().then((success) => {
      console.log('deleteDocument LOCAL DB: ', success);
      socket.emit('delete_document', {fileId: document.fileId, documentUsers: document.users});
      db.files.where({owner: myUsername}).toArray().then((files) => {
        setUploadedFiles(files);
        setProgressBarData({message: 'File removed...', percentage: 100});
        window.history.pushState('object', '', window.location.pathname);
      });
    });
  };

  const fillerStyles = {
    height: '100%',
    width: `${progressBarData.percentage}%`,
    background: '#7FD971',
    borderRadius: '10px',
  };

  


  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="xs" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="modal-title-wrap">
            <p className="title">Delete file</p>
            <p className="subtitle">Remove the selected file from your database and its reference in decentralized cloud (blockchain smart contract).</p>
          </div>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="progress-bar-wrap">
            <div className="progress-bar">
              <p style={fillerStyles}></p>
            </div>
          </div>
          <p className="message">{progressBarData.message}</p>
          <button className="btn-primary" onClick={() => deleteDocument()}>
            Delete
          </button>
        </DialogBody>
      </ModalWrapper>
    </Dialog>
  )
}