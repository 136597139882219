import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import useFileUpload from 'react-use-file-upload';
import Select from 'react-select';
import { encryptFile, getAllUsers, serverAuth, uploadFileToIPFS } from '../utility/functions';
import UploadOption from "./UploadOption";
// import { Button, CloseButton, Modal } from "react-bootstrap";

import sendButton from '../../assets/images/send-button.png';
import { dbEmails } from "../utility/Utility";
import { EditorState, convertFromRaw } from "draft-js";
import styled from "styled-components";
import { uploadFileToMachina } from "../../inc/storageOnMachina";


const ComposeWrapper = styled.div`

  padding-left: 32px;
  padding-right: 32px;


  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .background-gray {
    background: #283134;
  }

  .title {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 32px;
  }

  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 30px;
  background: #FFF;
  border-radius: 24px;
  padding: 0;
  border: none;
  background: var(--Bg_9, #242C2F);


  .file-list-wrap {
    display: flex;

    .file-name,
    .file-size {
      color: var(--Gray_1, #F1F3F5);
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */

      
    }
  }

  .input-text {
    padding: 10px;
    margin-bottom: 10px;
    background: var(--Bg_07, #353C40);
    border: none;
    border-radius: 8px;
    box-shadow: none !important;
    color: var(--Gray_1, #F1F3F5);
    font-weight: 400;
  }

  .input-select {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;

    > div {
      background: var(--Bg_07, #353C40);
      border: none;
      border-radius: 8px;
      box-shadow: none !important;
      z-index: 1000;
    }

    input {
      color: #FFF !important;
    }
  }

  #react-select-2-listbox {
    z-index: 1000;
  }

  .reply-editor {
    overflow-y: auto !important;
    color: var(--Gray_1, #F1F3F5);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    padding: 15px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdw-editor-main {
    background: var(--Bg_10, #1C2129);
    height: 400px;
  }

  .upload-icon {
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    margin-bottom: 6px;
    padding: 5px;
  }

  .upload-icon svg {
    width: 15px;
    height: 15px;
    color: #000;
  }

  .rdw-dropdown-selectedtext {
    text-decoration: none;
    color: #000;
  }

  .reply-content-wrap {
    position: relative;

    .upload-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      .upload-section {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      
      .attachment-text {
        color: var(--Gray_1, #F1F3F5);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        text-align: center;
      }

      .upload-btn {
        background: #7fd971;
        border: none;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(16,24,40,.05);
        margin-top: 10px;
        opacity: .65;
        padding: 10px 30px;
        color: #FFF;
        margin-left: 10px;
        margin-bottom: 10px;

        &:hover {
          opacity: 1;
        }
      }

      .cancel-btn {
        background: #303030;
        border: none;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(16,24,40,.05);
        padding: 10px 60px;
        color: #fff;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .css-3w2yfm-ValueContainer {
    padding: 5px;
  }

  .css-1p3m7a8-multiValue {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: var(--Mint_5, #06C5C5);
    padding: 0;

    .css-wsp0cs-MultiValueGeneric {
      padding: 6px;
      padding-left: 12px;
      color: var(--Bg_10, #1C2129) !important;
    }
  }

  .rdw-editor-wrapper {
    background: #FFF;
  }
  
  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 1000px;
  }

  .file-list {
    list-style: none;
    text-align: left;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #000;

      .file-size {
        margin-left: 15px;
      }

      .remove-file-icon {
        width: 20px;
        height: 20px;
        color: #FFF;
        margin-left: 15px;
      }
    }
  }

  .submit-btn {
    background: #7FD971 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px 60px !important;
    border: none;
    opacity: 0.65;


    @media (max-width: 620px) {
      padding: 10px 40px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .action-wrap {
    display: flex;

    .progress-bar-wrap {
      display: flex;
      width: 20%;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 10px;

      @media (max-width: 768px) {
        width: 100%;
      }

      .message {
        color: var(--Gray_1, #F1F3F5);
      }

      .message-status-wrap {
        display: flex;
        align-items: center;
        width: 100%;
      }
    
      .progress-bar {
        width: 100%;
        height: 10px;
        border-radius: 24px;
        background: var(--Bg_6, #404B51);
      }
    
      .percentage {
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
        margin-left: 5px !important;
      }
    }
  }

  .css-f716f5-option {
    color: #000;
  }
`;

const Compose = (props) => {

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const [progressBarData, setProgressBarData] = useState({message: '', percentage: 0});

  const [openUploadSection, setOpenUploadSection] = React.useState(false);
  const [availableUsers, setAvailableUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [editorState, setEditorState] = React.useState(props.thread && props.thread.emails[0].content ? EditorState.createWithContent(convertFromRaw(props.thread.emails[0].content)) : EditorState.createEmpty());
  const [content, setContent] = React.useState('');
  const [subject, setSubject] = React.useState(props.thread ? props.thread.subject : '');

  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const inputRef = useRef();

  const handleSubmit = async (e) => {
    console.log('selectedUsers', selectedUsers)
    console.log('files', files)

    if(!selectedUsers.length || !subject) {
      alert('You didnt fill out all fields');
      return;
    }
    let fileCids = [];
    setProgressBarData({message: 'Encrypting files...', percentage: 10});

    for(let i = 0; i < files.length; i++) {
      let encryptedFile = await encryptFile(files[i], selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true);
      
      // let cid = await uploadFileToIPFS(encryptedFile)
      // fileCids.push(cid.ipnft);
      let cid = await uploadFileToMachina(encryptedFile, window.accountId, (Date.now() + i).toString());
      console.log('cid', cid)
      fileCids.push(cid);

    }
    setProgressBarData({message: 'Storing attachments...', percentage: 40});
    const email = {
      content: content,
      date: new Date(),
      attachments: fileCids,
      recipients: selectedUsers.map(x => x.value),
      sender: window.walletConnection.getAccountId()
    }

    const emailFile = new File([JSON.stringify(email)], Date.now() + '.json', {type: "application/json"});
    const encryptedEmailFile = await encryptFile(emailFile, selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true, true);
    let emailData = {};

    emailData = {
      emails: [encryptedEmailFile],
      subject: subject,
    }
    const fileWithMetadata = new File([JSON.stringify(emailData)], Date.now() + '.json', {type: "application/json"});
    // console.log('fileWithMetadata', fileWithMetadata)
    const encryptedFile = await encryptFile(fileWithMetadata, selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true);
    // console.log('encryptedFile', encryptedFile)
    setProgressBarData({message: 'Saving to smart contract...', percentage: 80});
    uploadFileToMachina(encryptedFile, window.accountId, (Date.now() + 1).toString()).then((cid) => {
    // uploadFileToIPFS(encryptedFile).then((cid) => {
      let allThreadUsers = [];
      allThreadUsers = selectedUsers.map(x => x.value);
      allThreadUsers.push(window.walletConnection.getAccountId());
      allThreadUsers = [...new Set(allThreadUsers)];
      const oldThreadCid = props.encryptedThread ? props.encryptedThread.cid : "false";
      storeEmailOnContract(cid, allThreadUsers, oldThreadCid);
    });
  };

  const storeEmailOnContract = async (emailHash, recipients, oldThreadCid) => {
    setIsEmailSent(true);
    if(props.thread) {
      await dbEmails.drafts.delete(props.thread.emails[0].cid);
    }
    await dbEmails.readEmails.put({cid: emailHash, status: true, owner: window.walletConnection.getAccountId()});
    const queryArgs = recipients.join(',');
    if(localStorage.getItem('wallet-selector') === 'sender') {
      const tx = {
        receiverId: process.env.REACT_APP_CONTRACT_NAME_MAIL,
        actions: [
          {
            methodName: 'store_email',
            args: {
              email_ipfs_hash: String(emailHash),
              recipients: recipients,
              old_email_ipfs_hash: oldThreadCid,
            },
            deposit: '0',
            gas: '300000000000000'
          }
        ]
      }
      const res = await window.near.signAndSendTransaction(tx);
      console.log('RES: ', res);
    }else {
      await window.emailContract.store_email(
        {
          callbackUrl: window.location.href + '?send_email=' + queryArgs, // callbackUrl after the transaction approved (optional)
          args: {
            email_ipfs_hash: String(emailHash),
            recipients: recipients,
            old_email_ipfs_hash: oldThreadCid,
          },
        }
      );
    }
    
    setProgressBarData({message: 'Email sent...', percentage: 100});
    setSubject('');
    clearAllFiles();
    setAvailableUsers([...selectedUsers, ...availableUsers]);
    setSelectedUsers([]);
    setEditorState(EditorState.createEmpty());
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const onContentChange = (editorState) => {
    setContent(editorState);
  };

  useEffect(() => {
    if(props.thread) {
      if(props.thread.attachments && props.thread.attachments.length > 0) {
        for(let i = 0; i < props.thread.attachments.length; i++) {
          setFiles({currentTarget: {files: [props.thread.attachments[i].attachment]}}, 'a')
        }
      }
      getAllUsers().then((users) => {
        setSelectedUsers(props.thread.emails[0].recipients);
        const tempAvailableUsers = users.filter(user => window.walletConnection.getAccountId() !== user.value && user.value !== window.walletConnection.getAccountId());
        setAvailableUsers(tempAvailableUsers);
      });
    }else {
      getAllUsers().then((users) => {
        const removeMyself = users.filter(user => user.value !== window.walletConnection.getAccountId());
        setAvailableUsers(removeMyself);
      });
    }
  }, []);

  const draftState = useRef({});

  useEffect(() => {
    draftState.current = {
      selectedUsers: selectedUsers,
      subject: subject,
      content: content,
      files: files,
      isClosing: isClosing,
      date: new Date(),
    }
  }, [selectedUsers, subject, content, files, isClosing]);

  const saveDraft = async () => {
    console.log('ENTERED SAVE DRAFT');
    console.log('draftState.current', draftState.current);
    // return;
    if(!draftState.current.isEmailSent && (draftState.current.selectedUsers.length > 0 || draftState.current.subject || draftState.current.content || draftState.current.files.length > 0)) {
      console.log('STARTED TO SAVE DRAFT');
      let selectedFiles = [];
      if(draftState.current.files.length > 0) {
        for(let i = 0; i < draftState.current.files.length; i++) {
          let encryptedFile = await encryptFile(draftState.current.files[i], selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true);
          selectedFiles.push(encryptedFile);
        }
      }
      const draft = {
        subject: draftState.current.subject,
        recipients: draftState.current.selectedUsers,
        content: draftState.current.content,
        attachments: selectedFiles,
        date: draftState.current.date,
      }

      console.log('draft', draft)
      if(props.thread) {
        dbEmails.drafts.update(props.thread.emails[0].cid, draft);
      }else {
        dbEmails.drafts.put(draft);
      }
      
    }
  }

  const onUserSelect = (value) => {
    console.log('onUserSelect', value)
    setSelectedUsers(value);
  };

  const handleInputChange = (inputValue) => {


    // console.log('Input Changed');
    // console.log(inputValue);
    // if(inputValue && (inputValue.length === 42 || inputValue.endsWith('.testnet'))) {
    //   console.log('Input Changed')
    //   setAvailableUsers([{value: inputValue, label: inputValue}]);
    // }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }


  const fillerStyles = {
    height: '100%',
    width: `${progressBarData.percentage}%`,
    background: '#7FD971',
    borderRadius: '10px',
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#FFF',
      };
    },
  };


  return (

    <ComposeWrapper className="grid grid-flow-row overflow-scroll">
      <h2 className="title row-start-1">Compose new mail</h2>
      <Select 
          isMulti
          options={availableUsers} 
          onChange={(values) => onUserSelect(values)}
          onInputChange={(inputValue) => handleInputChange(inputValue)}
          value={selectedUsers}
          className="input-select"
          placeholder="Select recipients..."
          // menuIsOpen={true}
          styles={colourStyles}
        />
        {
          <input type="text" placeholder="Subject" className="input-text" value={subject} onChange={(event) => setSubject(event.target.value)}/>
        }
        <div className="reply-content-wrap">
          <Editor
            toolbarClassName="toolbar-class"
            editorClassName="reply-editor"
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            onChange={onContentChange}
            toolbarCustomButtons={[<UploadOption setOpenUploadSection={setOpenUploadSection} openUploadSection={openUploadSection} />]}
          />

          {
            openUploadSection && (
              <div className="upload-wrapper">
                <div
                  onDragEnter={handleDragDropEvent}
                  onDragOver={handleDragDropEvent}
                  onDrop={(e) => {
                    handleDragDropEvent(e);
                    setFiles(e, 'a');
                    setOpenUploadSection(false);
                  }}

                  className="upload-section"
                >
                  <p className="attachment-text">Drag and drop files here</p>
                  <div className="upload-button-wrap">
                    <button onClick={() => setOpenUploadSection(false)} className="cancel-btn">Cancel</button>
                    <button onClick={() => inputRef.current.click()} className="upload-btn">Or select files</button>
                  </div>
                  <p className="attachment-text">to upload!</p>

                  <input
                    ref={inputRef}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      setFiles(e, 'a');
                      setOpenUploadSection(false);
                      inputRef.current.value = null;
                    }}
                  />
                </div>
              </div>
            )
          }
        </div>
        <div className="form-container">
          <div className="file-list-wrap">
            <ul className="file-list">
              {files.map((file) => (
                <li key={file.name}>
                  <span className="file-name">{file.name}</span>
                  <span className="file-size"> {formatBytes(file.size)}</span>
                  <span onClick={() => removeFile(file.name)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="remove-file-icon">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="action-wrap">
          <button className="btn-primary" onClick={() => handleSubmit()}>
            <img src={sendButton} alt="submit-btn" />
            Send
          </button>
          {
            progressBarData.percentage > 0 && (
              <div className="progress-bar-wrap">
                <p className="message">{progressBarData.message}</p>
                <div className="message-status-wrap">
                  <div className="progress-bar">
                    <p style={fillerStyles}></p>
                  </div>
                  <p className="percentage">{progressBarData.percentage}%</p>
                </div>
              </div>
            )
          }
        </div>
    </ComposeWrapper>
  );
}

export default Compose;
