import styled from "styled-components";

import bookIcon from "../assets/images/book-icon.png";

const SectionWrapper = styled.div`
  padding: 24px 24px 24px 16px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(10px);

  .title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .title {
      color: #FFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 16px;
    }

    .subtitle {
      color: #FFF;
      font-family: Nexa;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 16px;
    }
  }

  .btn-primary {
    border-radius: 32px;
    background: var(--Green_5, #00BE83);
    padding: 8px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Bg_10, #1C2129);
    font-family: Nexa;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export default function BookADemoWidget() {

  return (
    <SectionWrapper>
      <div className="title-wrapper">
        <h3 className="title">Book a demo</h3>
        <h4 className="subtitle">Get the answers to your questions</h4>
      </div>
      <a className="btn-primary" href="mailto:contact@pathfinderapp.xyz?subject=Explore%20New%20Horizons%3A%20Book%20Your%20Personalized%20Demo%20Today!&body=%0ADear%20Pathfinder%20Team%2C%0A%0AI%20am%20reaching%20out%20to%20express%20my%20interest%20in%20booking%20a%20demo.%20Our%20team%20is%20eager%20to%20explore%20the%20features%20and%20functionalities%20of%20your%20platform.%0A%0AThank%20you%20for%20considering%20our%20request.%20We%20look%20forward%20to%20the%20opportunity%20to%20explore%20Pathfinder%20further.%0A%0AKind%20regards%2C%0A%5BYour%20Name%5D%0A%5BYour%20Position/Company%5D%0A%5BContact%20Information%5D%0A">
        <img src={bookIcon} alt="book-a-demo-icon" />
        Book a demo
      </a>
    </SectionWrapper>
  );
}