import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import styled from "styled-components";
import cancelButton from '../assets/images/cancel-x.png';
import { useState } from "react";

import currentTransferVideo from '../assets/images/current-transfers.mp4';
import uploadVideo from '../assets/images/upload.mp4';
import permissionsVideo from '../assets/images/permissions.mp4';
import downloadsVideo from '../assets/images/downloads.mp4';
import checkFileVideo from '../assets/images/check-file.mp4';
import historyVideo from '../assets/images/history.mp4';
import emailVideo from '../assets/images/email.mp4';
import { useGlobalContext } from "../context/GlobalProvider";


const ModalWrapper = styled.div`
  background: var(--BG_8, #283134);
  box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
  border-radius: 32px;
  padding: 24px;

  @media (max-width: 768px) {
    padding: 24px 12px; 
  }

  .navigation-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: 768px) {
      padding: 0;
    }
    

    .navigation-wrap {
      margin-bottom: 24px;
  
      .navigation {
        color: #FFF;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }
    }
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;

      .title {
        color: #FFF;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }
      .cancel-icon {
        width: 24px;
        height: 24px;
      }
  }

  .nav-wrap {
    width: 25%;

    @media (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: var(--BG_8, #283134);

      &.closed {
        display: none;
      }
    }

    .close-btn {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      width: 24px;
      height: 24px;

      @media (max-width: 768px) {
        display: block;
      }

    }
  }

  .info-wrap {
    width: 75%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .content {
    color: #FFF;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    margin-bottom: 24px;
  }

  .nav-hamburger {
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .nav-hamburger span {
    display: block;
    width: 33px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #FFFFFF;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;

  }

`;


export default function OnboardingModal() {

  const [currentPage, setCurrentPage] = useState(1);
  const {setOpenOnboardingModal} = useGlobalContext();

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const handleClose = () => {
    setOpenOnboardingModal(false);
  }

  const changePage = (page) => {
    setCurrentPage(page);
    document.querySelector('.nav-wrap').classList.toggle('closed')
  }
  
  
  return (
    <Dialog open={true} show={() => handleClose()} className="bg-transparent shadow-none" size="md" >
      <ModalWrapper>
        <div className="flex relative">
          <div className="nav-wrap closed">
            <button className="close-btn" onClick={() => document.querySelector('.nav-wrap').classList.toggle('closed')}>
              <img className="cancel-icon" src={cancelButton} alt="close button" />
            </button>
            <ul className="navigation-list">
              <li className="navigation-wrap">
                <button onClick={() => changePage(1)} className="navigation">Current transfers</button>
              </li>
              <li className="navigation-wrap">
                <button onClick={() => changePage(2)} className="navigation">Uploads</button>
              </li>
              <li className="navigation-wrap">
                <button onClick={() => changePage(3)} className="navigation">Permissions</button>
              </li>
              <li className="navigation-wrap">
                <button onClick={() => changePage(4)} className="navigation">Downloads</button>
              </li>
              <li className="navigation-wrap">
                <button onClick={() => changePage(5)} className="navigation">Check file</button>
              </li>
              <li className="navigation-wrap">
                <button onClick={() => changePage(6)} className="navigation">History</button>
              </li>
              <li className="navigation-wrap">
                <button onClick={() => changePage(7)} className="navigation">Email</button>
              </li>
            </ul>
          </div>
          <div className="info-wrap">
            <DialogHeader className="modal-header">
              <div className="nav-hamburger" onClick={() => document.querySelector('.nav-wrap').classList.toggle('closed')}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {
                currentPage === 1 &&
                <h1 className="title">Current transfers</h1>
              }
              {
                currentPage === 2 &&
                <h1 className="title">Uploads</h1>
              }
              {
                currentPage === 3 &&
                <h1 className="title">Permissions</h1>
              }
              {
                currentPage === 4 &&
                <h1 className="title">Downloads</h1>
              }
              {
                currentPage === 5 &&
                <h1 className="title">Check file</h1>
              }
              {
                currentPage === 6 &&
                <h1 className="title">History</h1>
              }
              {
                currentPage === 7 &&
                <h1 className="title">Email</h1>
              }
              <button className="close-btn" onClick={() => handleClose()} >
                <img className="cancel-icon" src={cancelButton} alt="cancel button" />
              </button>
            </DialogHeader>
            <DialogBody>
              <div className="body-wrap">
                <div className="content-wrap">
                  {
                    currentPage === 1 &&
                    <>
                      <p className="content">Navigate to the "Current Transfers" section to get real time information about the active file transfers and their progress.</p>
                      <video controls height="252">
                        <source src={currentTransferVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                  {
                    currentPage === 2 &&
                    <>
                      <p className="content">To upload files, navigate to the “Uploads” section. Drag and drop file or click on the box to select your files. 
                        <br />
                        Progress of upload will be visible immediately after upload of file.
                        <br />
                        Once your file is uploaded you can choose whether you want to share the file with specific wallets, or generate a link.
                      </p>
                      <video controls height="252">
                        <source src={uploadVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                  {
                    currentPage === 3 &&
                    <>
                      <p className="content">Sharing a file with specific wallets allows you to set up a granular level of permissions for each user that is selected on the sharing list or you can also revoke access for a specific wallet.</p>
                      <video controls height="252">
                        <source src={permissionsVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                  {
                    currentPage === 4 &&
                    <>
                      <p className="content">To download a file navigate to the “Downloads” section. On the screen you can see the files that have been shared with you. By selecting the download button of the desired file, the file is downloaded to your wallet. Next you can choose whether you want to view the file, save it locally or remove it, based on the permission level.</p>
                      <video controls height="252">
                        <source src={downloadsVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                  {
                    currentPage === 5 &&
                    <>
                      <p className="content">To verify ownership of a file, navigate to the “Check File” section. Select or drag and drop your file, then click upload. If the file was shared through Pathfinder with the Tracking feature the owner and recipient will be displayed.</p>
                      <video controls height="252">
                        <source src={checkFileVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                  {
                    currentPage === 6 &&
                    <>
                      <p className="content">In the history tab find the details about your and your recipients transfers and actions made with the shared files. Sort the list by different metrics or search for specific files and expand to view the full details.</p>
                      <video controls height="252">
                        <source src={historyVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                  {
                    currentPage === 7 &&
                    <>
                      <p className="content">To compose an email navigate to the inbox section and select the compose sub-section. There you will be able to select recipients based on their wallet, only if they have logged in on Pathfinder, add subject and compose an email same as you would on any email service.</p>
                      <video controls height="252">
                        <source src={emailVideo} type="video/mp4" />
                      </video>
                    </>
                  }
                </div>
              </div>
            </DialogBody>
            <DialogFooter className="modal-footer">
              {
                currentPage !== 1 &&
                <button className="btn-primary" onClick={() => previousPage() }>Previous</button>
              }
              {
                currentPage !== 7 &&
                <button className="btn-primary ml-auto" onClick={() => nextPage() }>Next</button>
              }
              
            </DialogFooter>
          </div>
        </div>
      </ModalWrapper>
    </Dialog>
  )
}