import styled from "styled-components";

import bookImage from "../../assets/images/book-image.png";
import bookIcon from "../../assets/images/book-icon.png";
import bookNowBackground from "../../assets/images/book-now-background.png";

const BookADemoDiv = styled.div`
  padding-top: 77px;
  padding-bottom: 50px;
  background: linear-gradient(180deg, #042C25 0%, rgba(4, 44, 37, 0.00) 50%), linear-gradient(180deg, rgba(4, 44, 37, 0.00) 50%, #042C25 100%), url(${bookNowBackground});

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .book-image {
    width: 100%;
    height: auto;   

    @media (max-width: 768px) {
      opacity: 0.5;
    }
  }

  .image-wrap-mobile {

    @media (max-width: 768px) {
      position: absolute;
      width: 100%;
      top: 65%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  .content-wrap {
    border-radius: 24px;
    background: rgba(28, 33, 41, 0.60);
    backdrop-filter: blur(10px);
    padding: 32px 16px;
    margin-bottom: 127px;

    @media (max-width: 768px) {
      position: relative;
      z-index: 2;

    }

    .section-title {
      color: #FFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 16px;
    }

    .section-subtitle {
      color: #FFF;
      font-family: Nexa;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 30px;
      margin-bottom: 24px;
    }

    .section-content {
      color: #FFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      margin-bottom: 16px;

      @media (max-width: 768px) {
        display: none;
      }

      b {
        font-weight: 800;
      }
    }

    .btn-primary {
      border-radius: 16px;
      background: var(--Green_5, #00BE83);
      padding: 8px 16px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Bg_10, #1C2129);
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export default function BookADemo() {

  return (
    <BookADemoDiv>
      <div className="container mx-auto grid grid-cols-12 gap-3 relative">
        <div className="md:col-span-5 md:col-start-2 row-start-1 image-wrap-mobile">
          <img src={bookImage} alt="Path pattern" className="book-image" />
        </div>
        <div className="col-span-12 md:col-span-4 md:col-start-8 row-start-1 content-wrap">
          <h2 className="section-title">Book a demo</h2>
          <h3 className="section-subtitle">Find out more about how to get the most benefits from Pathfinder.</h3>
          <p className="section-content">Interested in <b>learning more</b> about Pathfinder and getting a personalised demo to explore how you can benefit from it the most? </p>
          <a className="btn-primary" href="mailto:contact@pathfinderapp.xyz?subject=Explore%20New%20Horizons%3A%20Book%20Your%20Personalized%20Demo%20Today!&body=%0ADear%20Pathfinder%20Team%2C%0A%0AI%20am%20reaching%20out%20to%20express%20my%20interest%20in%20booking%20a%20demo.%20Our%20team%20is%20eager%20to%20explore%20the%20features%20and%20functionalities%20of%20your%20platform.%0A%0AThank%20you%20for%20considering%20our%20request.%20We%20look%20forward%20to%20the%20opportunity%20to%20explore%20Pathfinder%20further.%0A%0AKind%20regards%2C%0A%5BYour%20Name%5D%0A%5BYour%20Position/Company%5D%0A%5BContact%20Information%5D%0A">
            <img src={bookIcon} className="book-icon" alt="Calendar icon" />
            Book a demo
          </a>
        </div>
      </div>
    </BookADemoDiv>
  );
}