import React, { useEffect, useState } from 'react';
import { decryptMessage, downloadFileFromIPFS, getIPFSMetadata, readJsonFile } from '../utility/functions';
// import Compose from './Compose';
// import EmailDetails from './ThreadSingle';
import { dbEmails } from '../utility/Utility';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/GlobalProvider';


const InboxWrapper = styled.div`
`;


export default function Inbox(props) {
  const [emailThreads, setEmailThreads] = useState([]);
  const [encryptedEmailsThreads, setEncryptedEmailsThreads] = useState([]);
  const [inboxCount, setInboxCount] = useState(0);
  const [paginationParams, setPaginationParams] = useState({page_size: 20, page_number: 1});
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const { setNewEmailCount } = useGlobalContext();


  const getEmails = async () => {
    const emailThreadsBC = await window.emailContract.get_recieved_emails({account_id: window.accountId, pagination_params: paginationParams});
    // console.log('emailThreadsBC', emailThreadsBC);
    setInboxCount(emailThreadsBC.total);
    const getReadEmailsCount = await dbEmails.readEmails.toArray();
    if(emailThreadsBC.total !== getReadEmailsCount.length) {
      setNewEmailCount(emailThreadsBC.total - getReadEmailsCount.length);
    }

    for(let i = 0; i < emailThreadsBC.threads.length; i++) {
      const emailFile = await downloadFileFromIPFS(emailThreadsBC.threads[i].email);

      const decryptedEmailFile = await decryptMessage(emailFile, emailFile.name, window.accountId);
      const emailThread = await readJsonFile(decryptedEmailFile);
      emailThread.cid = emailThreadsBC.threads[i].email;

      let emailReadStatus = getReadEmailsCount.filter((obj) => obj.threadId === emailThreadsBC.threads[i].email);

      if(emailReadStatus.length > 0) {
        emailThread.status = emailReadStatus[0].status ? 'read' : 'unread';
      }else {
        emailThread.status = 'unread';
      }
      setEncryptedEmailsThreads((prevEmails) => [...prevEmails, emailThread]);

      let decryptedEmails = [];
      for(let j = 0; j < emailThread.emails.length; j++) {
        const decryptedEmailUint8 = await decryptMessage(emailThread.emails[j], emailThread.emails[j].name, window.accountId, true);
        if(decryptedEmailUint8) {
          var decryptedEmail = new TextDecoder().decode(decryptedEmailUint8);
          decryptedEmail = JSON.parse(decryptedEmail);
          if(decryptedEmail.attachments.length > 0) {
            for(let k = 0; k < decryptedEmail.attachments.length; k++) {
              const attachment = await getIPFSMetadata(decryptedEmail.attachments[k]);
              decryptedEmail.attachments[k] = {cid: decryptedEmail.attachments[k], name: attachment.name};
            }
          }
          decryptedEmails.push(decryptedEmail);
        }
      }
      const decryptedEmailThread = {...emailThread};
      decryptedEmailThread.emails = decryptedEmails;
      setEmailThreads((prevEmails) => [...prevEmails, decryptedEmailThread]);
      setSearchResults((prevEmails) => [...prevEmails, decryptedEmailThread]);
    }

  }

  useEffect(() => {
    dbEmails.readEmails.toArray().then((emails) => {
      setSelectedEmails(emails);
    });
  }, [emailThreads]);

  const setOpenThread = (thread) => {
    props.setActiveThread(thread)
    props.setEncryptedThread(encryptedEmailsThreads.find((obj) => obj.cid === thread.cid));
  }

  const previousPage = () => {
    if(paginationParams.page_number === 1) {
      return;
    }
    setPaginationParams((prevParams) => {
      return {...prevParams, page_number: prevParams.page_number - 1}
    });
  }

  const nextPage = () => {
    if(paginationParams.page_number * paginationParams.page_size >= inboxCount) {
      return;
    }
    setPaginationParams((prevParams) => {
      return {...prevParams, page_number: prevParams.page_number + 1}
    });
  }

  const refreshEmails = () => {
    setEmailThreads([]);
    setEncryptedEmailsThreads([]);
    setPaginationParams({page_size: 1, page_number: 1});
  }

  // Function to handle the search
  const handleSearch = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    const filtered = emailThreads.filter((thread) =>
      thread.subject.toLowerCase().includes(newValue.toLowerCase())
    );
    setSearchResults(filtered);
  };

  const selectAllCheckboxes = (event) => {
    const status = event.target.checked;

    console.log('status', status);

    let emailIds = [];
    var x = document.getElementsByClassName("selectitem-input");
    for(let i=0; i<x.length; i++) {
      x[i].checked = status;
      if(status) {
        emailIds.push(x[i].value);
      }
    }

    setSelectedEmails(emailIds);
  }

  const setOneSelectedEmail = (event, status, cid) => {
    const checked = event.target.checked;
    console.log('checked', checked);
    if(checked) {
      setCheckedEmails((prevState) => [...prevState, cid]);
      if(status === 'unread') {
        const emailIndex = selectedEmails.findIndex((email) => email.threadId === cid);
        console.log('emailIndex', emailIndex);
        if(emailIndex === -1) {
          setSelectedEmails((prevEmails) => [...prevEmails, {threadId: cid, status: true}]);
        }else {
          selectedEmails[emailIndex].status = true;
          setSelectedEmails(selectedEmails);
        }
      }else {
        const emailIndex = selectedEmails.findIndex((email) => email.threadId === cid);
        if(emailIndex === -1) {
          setSelectedEmails((prevEmails) => [...prevEmails, {threadId: cid, status: false}]);
        }else {
          selectedEmails[emailIndex].status = false;
          setSelectedEmails(selectedEmails);
        }
      }
    } else {
      setCheckedEmails((prevState) => prevState.filter((email) => email !== cid))
      setSelectedEmails((prevEmails) => prevEmails.filter((email) => email.threadId !== cid));
    }
  }

  const setEmailStatus = async () => {
    console.log('setEmailStatus');
    console.log('selectedEmails', selectedEmails);
    const currentReadEmails = await dbEmails.readEmails.toArray();
    const newEmails = selectedEmails.filter((email) => !currentReadEmails.some((obj) => obj.threadId === email.threadId));
    if(newEmails.length > 0) {
      await dbEmails.readEmails.bulkAdd(newEmails);
      const updatedEmails = searchResults.map((thread) => {
        console.log('thread', thread);
        const emailIndex = newEmails.findIndex((email) => email.threadId === thread.cid);
        if(emailIndex !== -1) {
          thread.status = newEmails[emailIndex].status ? 'read' : 'unread';
        }
        return thread;
      });

      setSearchResults(updatedEmails);
    }
  }

  useEffect(() => {
    // console.log('paginationParams', paginationParams);
    setSelectedEmails([]);
    setEmailThreads([]);
    setSearchResults([]);
    setEncryptedEmailsThreads([]);
    getEmails();
  }, [paginationParams]);

  const [checkedEmails, setCheckedEmails] = useState([]);

  console.log('searchResults', searchResults);
  // console.log('checkedEmails', checkedEmails);

  return (
    <InboxWrapper className="grid grid-cols-12 grid-flow-row">
      <div className="xs:col-span-12 md:col-span-12 lg:col-span-12 col-span-12  inbox-wrap">
        <div className="row-start-1">
          <div className="col-span-12">
            <div className="inbox-search">
              <input type="search" className="search-emails" name="s" placeholder="Search" value={searchValue} onChange={(event) => handleSearch(event)}/>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="search-icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="row-start-2">
          <div className="col-span-12">
            <div className='row-start-1 grid grid-cols-12 grid-flow-row'>
              <div className="col-span-12">
                <div className="inbox-toolbar">
                  <div className="inbox-toolbar-btns">
                    <input type="checkbox" name="select-all" className="selectall-input" onChange={(event) => selectAllCheckboxes(event)}/>
                    <a className="refresh-btn" onClick={() => refreshEmails()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                    </a>
                    {
                      selectedEmails && selectedEmails.length > 0 &&
                        <a className="refresh-btn" onClick={() => setEmailStatus()}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                          </svg>
                        </a>
                    }
                  </div>
                  <div className="inbox-toolbar-btns">
                    <p className="email-amount">{((paginationParams.page_number - 1) * paginationParams.page_size) + 1}-{paginationParams.page_number * paginationParams.page_size} of {inboxCount}</p>
                    <a className="refresh-btn" disabled={paginationParams.page_number === 1 ? true : false} onClick={() => previousPage()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                      </svg>
                    </a>
                    <a className="refresh-btn" disabled={paginationParams.page_number * paginationParams.page_size === inboxCount ? true : false} onClick={() => nextPage()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {
          searchResults.length > 0 && searchResults.map((thread, key) => {
            let date = new Date(thread.emails[0].date);
            date = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
            let content = '';
            for(let i = 0; i < thread.emails[0].content.blocks.length; i++) {
              content += thread.emails[0].content.blocks[i].text;
            }
            content = content.substring(0, 50) + '...';
            const readStyle = thread.status === 'read' ? {backgroundColor: '#FFF'} : {backgroundColor: 'red'};
            return (
              <div className={"row-start-" + (key+1)} key={key} style={readStyle}>
                <div className="col-span-12">
                  <div className="inbox-list-item">
                    <input type="checkbox" name="select-item" checked={checkedEmails.includes(thread.cid)} className="selectitem-input" data-read={thread.status} onChange={(event) => setOneSelectedEmail(event, thread.status, thread.cid)} />
                    <div className="inbox-list-item-wrap" onClick={() => setOpenThread(thread)}>
                      <div className="inbox-list-item-mobile-wrap">
                        <p className="inbox-list-item-sender">{thread.emails[0].sender} ({thread.emails.length})</p>
                        <p className="inbox-list-item-date mobile">{date}</p>
                      </div>
                      <p className="inbox-list-item-subject">{thread.subject} - {content}</p>
                      <p className="inbox-list-item-date desktop">{date}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </InboxWrapper>
  );
}