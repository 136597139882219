import styled from "styled-components";

import footerLogo from "../../assets/images/footer-logo.png";


import mediumIcon from "../../assets/images/medium-icon.png";
import telegramIcon from "../../assets/images/telegram-icon.png";
import linkedInIcon from "../../assets/images/linkedin-icon.png";
import twitterIcon from "../../assets/images/twitter-icon.png";

const FooterDiv = styled.div`

  padding-top: 50px;
  background: var(--Dark_green, #042C25);
  padding-bottom: 86px;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
  }

  .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 16px;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    .built-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 13px;      
    }

    .social-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      color: #FFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
      margin-right: 4px;
    }

    .near-logo {
      width: 100px;
      height: auto;
    }

    .social-link {
      margin-right: 8px;
      width: 24px;
      height: auto;
      display: inline-block;

      &:first-child {
        margin-left: 8px;
      }
    }
  }


  .contact-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    .contact-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding-top: 16px;

      @media (max-width: 768px) {
        align-items: flex-start;
      }

      .contact-title {
        color: var(--Gray_1, #F1F3F5);
        font-family: Nexa;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 13px;

        b {
          font-weight: 800;
        }

        .contact-link {
          color: var(--Gray_1, #F1F3F5);
          font-family: Nexa;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-decoration: none;
        }
      }
      .rights {
        color: var(--Gray_1, #F1F3F5);
        text-align: right;
        font-family: Nexa;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;

export default function Footer() {

  return (
    <FooterDiv>
      <div className="container mx-auto grid grid-cols-12 gap-3">
        <div className="col-span-12 md:col-span-4 row-start-1 logo-wrap">
          <div className="built-wrap">
            <p>Built on:</p>
            <a href="https://near.org/?utm_source=pathfinderapp.xyz&utm_medium=website&utm_campaign=built_onNear_footer">
              <img src={footerLogo} className="near-logo" alt="NEAR protocol logo" />
            </a>
          </div>
          <div className="social-wrap">
            <p>
              Follow us on:
              <a href="https://bit.ly/Pathfinder-medium" className="social-link">
                <img src={mediumIcon} className="near-logo" alt="Medium" />
              </a>
              <a href="https://bit.ly/Pathfinder-Telegram-Footer" className="social-link">
                <img src={telegramIcon} className="near-logo" alt="Telegram" />
              </a>
              <a href="https://bit.ly/Pathfinder-LinkedIn-Footer" className="social-link">
                <img src={linkedInIcon} className="near-logo" alt="LinkedIn" />
              </a>
              <a href="https://bit.ly/Pathfinder-twitter-footer" className="social-link">
                <img src={twitterIcon} className="near-logo" alt="Discord" />
              </a>
            </p>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 md:col-start-10 row-start-2 md:row-start-1 contact-wrap">
          <div className="contact-footer">
            <p className="contact-title">
              Contact us <b> <a href="mailto:contact@pathfinderapp.xyz?subject=Let%27s%20Connect%3A%20Your%20Path%20to%20Solutions%20Starts%20Here&body=Dear%20Pathfinder%20Team%2C%0A%0AI%20am%20reaching%20out%20to%20express%20my%20interest%20in%20booking%20a%20demo.%20Our%20team%20is%20eager%20to%20explore%20the%20features%20and%20functionalities%20of%20your%20platform.%0A%0AThank%20you%20for%20considering%20our%20request.%20We%20look%20forward%20to%20the%20opportunity%20to%20explore%20Pathfinder%20further.%0A%0AKind%20regards%2C%0A%5BYour%20Name%5D%0A%5BYour%20Position/Company%5D%0A%5BContact%20Information%5D">here</a></b>
            </p>
            <p className="rights">©All rights reserved 2024</p>
          </div>
          
        </div>
      </div>
    </FooterDiv>
  );
}