import styled from "styled-components";
import { useUserFilesContext } from "../../../context/UserFilesProvider";
import { Fragment, useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/GlobalProvider";
import { db } from "../../../inc/database";
import { Input, Typography } from "@material-tailwind/react";


import downloadingBtn from '../../../assets/images/downloading-btn.png';
import redCancelBtn from '../../../assets/images/cancel-x-red.png';

const CurrentTransfersWrap = styled.div`

  .background-gray {
    background: #283134;
  }

  .tab-header {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .table-header-wrap {
    color: #C0CDCF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .td-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .table-header {
    color: #C0CDCF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    svg {
      display: inline-block;
    }
  }

  .row-wrap {
    padding-left: 16px;
    padding-right: 16px;
    
    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .content-wrap {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    min-height: 95px;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .file-info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
      
      .owner {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .action-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  .file-wrap {
    @media (max-width: 768px) {
      width: 100%;
    }

    .action-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 768px) {
        justify-content: space-between;
      }

      .action-info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 32px;
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;

        img {
          animation: rotation 2s infinite linear;
          margin-right: 8px;
        }

        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }

      .cancel-btn {
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        img {
          width: 16px;
          height: 16px;
          
        }
       
      }
    }

    
   
    .progress-bar-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 10px;
  
    
      .progress-bar {
        width: 100%;
        height: 10px;
        border-radius: 24px;
        background: var(--Bg_6, #404B51);
  
      }
    
      .percentage {
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
        margin-left: 5px !important;
      }
    }
  }
  

  .no-transactions {
      color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;

    @media (max-width: 768px) {
      padding-left: 12px;
    }
  }

  .close-btn {
    width: 24px;
    height: 24px;
  }
`;

export default function CurrentActiveTransfers() {

  const { setDownloads, peerInstanceReceiver, peerInstanceHost, currentTransfers, setCurrentTransfers } = useUserFilesContext();

  const { socket, myUsername } = useGlobalContext();

  const [tableData, setTableData] = useState(currentTransfers);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setTableData(currentTransfers);
  }, [currentTransfers]);

  const cancelTransfer = (isDownloading, username, fileId) => {
    console.log('cancelTransfer', isDownloading, username, fileId);
    socket.emit('cancel_download', {username: username, isDownloading: isDownloading, myUsername: myUsername, fileId: fileId});
    if(isDownloading) {
      //CHECK IF TRANSFER ALREADY STARTED
      if(peerInstanceReceiver.current[fileId]) {
        console.log('destroying peer');
        peerInstanceReceiver.current[fileId].destroy();
      }
      setDownloads((prevState) => {
        return prevState.filter(el => el.fileId !== fileId);
      });
    }else {
      //CHECK IF TRANSFER ALREADY STARTED
      if(peerInstanceHost.current[username + fileId]) {
        peerInstanceHost.current[username + fileId].destroy();
      }
      setCurrentTransfers((prevState) => {
        return prevState.filter(el => el.fileId !== fileId);
      });
    }
  };

  const TABLE_HEAD = ["Name", "Recipient", "Search"];

  const SortIcon = ({ direction }) => {
    return direction === 'asc' ?
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg> 
    ;
  };

  // Function to handle sorting
  const requestSort = (key) => {
    if(key === 'Search') {
      return;
    }
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    console.log('sortConfig', sortConfig)
  };

  // Function to search the table
  const searchTable = () => {
    return tableData.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const sortedData = searchTable().sort((a, b) => {
    if (sortConfig.key) {
      let currentKey = sortConfig.key.toLowerCase();
      console.log('currentKey', sortConfig.key)

      if(currentKey === 'date') {
        currentKey = 'fileId';
      }
      if(currentKey === 'Size') {
        currentKey = 'size';
      }
        
      const aValue = a[currentKey].toString();
      const bValue = b[currentKey].toString();

      console.log('aValue', aValue)
      console.log('bValue', bValue)
      
      if (aValue && bValue) {
        const order = sortConfig.direction === 'asc' ? 1 : -1;
        if (currentKey === 'size') {
          // Convert to numbers before comparing
          return (Number(aValue) - Number(bValue)) * order;
        } else {
          // For other keys, convert to string and use localeCompare
          return aValue.toString().localeCompare(bValue.toString()) * order;
        }
      }
    }
    return 0;
  });

  return (
    <CurrentTransfersWrap>
      <div className="table-wrap">
        <div className="table-header-wrap grid grid-cols-8 grid-row-flow mobile-hide-grid">
          {
            TABLE_HEAD.map((head, index) => {
              let className = "";
              if(head === 'Name') {
                className="col-span-4 td-wrap";
              }else if(head === 'Recipient') {
                className="col-span-1 td-wrap";
              }else if(head === 'Search') {
                className="col-span-2 col-start-9 td-wrap";
              }
              let currentDirection = 'asc';
              if(sortConfig.key === head) {
                currentDirection = sortConfig.direction;
              }
              return (
                <div key={head} className={className} onClick={() => requestSort(head)} >
                  <div
                    variant="small"
                    color="blue-gray"
                    className={index === TABLE_HEAD.length - 1 ? "table-header search-wrap" : "table-header"}
                  >
                    {index === TABLE_HEAD.length - 1 ? (
                        <Input
                          label="Search"
                          type="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          color="white"
                        />
                      ):
                        <>
                          {head}{" "}
                          <SortIcon direction={currentDirection} />
                        </>
                      }
                  </div>
                  </div>);
            })
          }
        </div>
        {
          sortedData.length > 0 ?
            <Fragment>
            {
            sortedData.map((progress, index) => {
              const username = progress['user'].length >= 32 ? progress['user'].slice(0,6) + '...' + progress['user'].slice(-4) : progress['user'];
              const percentage = progress['percentage'];
              const fillerStyles = {
                height: '100%',
                width: `${percentage}%`,
                background: '#06C5C5',
                borderRadius: '24px',

              }
              const trClass = index % 2 === 0 ? 'background-gray grid grid-cols-8 grid-row-flow row-wrap' : 'grid grid-cols-8 grid-row-flow row-wrap';

              return (
                <div key={index} className={trClass}>
                  <div className="col-span-6 lg:col-span-3 content-wrap">
                    <div className="file-info-wrap">
                      <p className="name">{ progress['fileName'].split('.').slice(0, -1).join('.') }</p>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1 content-wrap">
                    <p className="owner">{username}</p> 
                  </div>
                  <div className="col-span-8 lg:col-span-4 content-wrap lg:ml-auto">
                    <div className="file-wrap">
                      <div className="action-wrap">
                        <p className="action-info">
                          <img src={downloadingBtn} alt="cancel-btn" />
                          Transfering...
                        </p>
                        <button className="cancel-btn" onClick={() => cancelTransfer(false, progress.user, progress.fileId)}>
                          <img src={redCancelBtn} alt="cancel-btn" />
                          Cancel
                        </button>
                      </div>
                      <div className="progress-bar-wrap">
                        <div className="progress-bar">
                          <p style={fillerStyles}></p>
                        </div>
                        <p className="percentage">
                          {`${percentage}%`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                );
              })
            }
            </Fragment>
            :
            <p className="no-transactions">You currently have no active file transfers</p>
          }
      </div>
    </CurrentTransfersWrap>
  )
}