import React, { useEffect, useState } from 'react';
import { decryptMessage, downloadFileFromIPFS, getIPFSMetadata, readJsonFile } from '../utility/functions';
import Compose from './Compose';
import EmailDetails from './ThreadSingle';
// import { Col, Row } from 'react-bootstrap';
import { dbEmails } from '../utility/Utility';
import styled from 'styled-components';

const DraftsWrapper = styled.div`
.inbox-wrap {
  .inbox-search {
    position: relative;

    .search-emails {
      display: flex;
      height: 44px;
      padding: 0px 15px 0px 30px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 8px;
      background: #EAF1FB;
      border: none;

       @media screen and (max-width: 820px) {
        width: 100%;
      }
    }

    .search-icon {
      position: absolute;
      top: 0;
      left: 5px;
      width: 20px;
      height: 100%;
    }
  }

  .inbox-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    .inbox-toolbar-btns {
      display: flex;
      align-items: center;
      justify-content: center;

      .selectall-input {
        width: 20px;
        height: 20px;
      }

      .email-amount {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-left: 30px !important;
        color: #FFF;
      }
  
      .refresh-btn {
        margin-left: 20px;
  
        .refresh-icon {
          text-decoration: none;
          height: 25px;
          width: 25px;
          color: #FFF;
          cursor: pointer;
        }
      }
    }

   
  }

  .inbox-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    cursor: pointer;

    .inbox-list-item-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;

      @media screen and (max-width: 820px) {
        flex-direction: column;
      }

      .desktop {
        display: initial;
        @media screen and (max-width: 820px) {
          display: none;
        }
      }

      .inbox-list-item-mobile-wrap {
        @media screen and (max-width: 820px) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
        }
      }

      .mobile {
        display: none;
        @media screen and (max-width: 820px) {
          display: initial;
        }
      }
    }

    .selectitem-input {
      width: 20px;
      height: 20px;
    }

    .inbox-list-item-sender {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 30px !important;
      color: #FFF;

      @media screen and (max-width: 820px) {
        font-size: 15px;
        margin-left: 10px !important;
      }
    }

    .inbox-list-item-subject {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 30px !important;
      color: #FFF;

      @media screen and (max-width: 820px) {
        margin-left: 10px !important;
        margin-top: 10px !important;
        font-size: 15px;
        margin-right: auto !important;
      }
    }

    .inbox-list-item-date {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: auto !important;
      color: #FFF;

      @media screen and (max-width: 820px) {
        font-size: 15px;
      }
    }
  }

  

  
}


`;


export default function Drafts(props) {
  const [emailThreads, setEmailThreads] = useState([]);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [openRespondWindow, setOpenRespondWindow] = useState(false);
  const [openEmailInfoWindow, setOpenEmailInfoWindow] = useState(false);
  const [encryptedEmailsThreads, setEncryptedEmailsThreads] = useState([]);
  const [currentEncryptedEmailsThreads, setCurrentEncryptedEmailsThreads] = useState({});
  const [inboxCount, setInboxCount] = useState(0);
  const [paginationParams, setPaginationParams] = useState({page_size: 1, page_number: 1});
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [openComposeWindow, setOpenComposeWindow] = useState(false);



  useEffect(() => {
    console.log('triggered drafts.jsx');
    // if(window.walletConnection.isSignedIn()) {
    //   getEmails();
    // }
    getDrafts();
  }, []);


  const getDrafts = async () => {

    const emailDraftThreads = await dbEmails.drafts.toArray();
    console.log('emailDraftThreads', emailDraftThreads);
    setInboxCount(emailDraftThreads.count);
    // return;
    for(let i = 0; i < emailDraftThreads.length; i++) {
      const emailThread = {};
      emailThread.emails = [];

      if(emailDraftThreads[i].attachments.length > 0) {
        emailThread.attachments = [];
        for(let j = 0; j < emailDraftThreads[i].attachments.length; j++) {
          console.log('emailDraftThreads[i].attachments[j]', emailDraftThreads[i].attachments[j])
          const attachment = await decryptMessage(emailDraftThreads[i].attachments[j], emailDraftThreads[i].attachments[j].name, window.accountId);
          emailThread.attachments[j] = {
            attachment: attachment,
            name: attachment.name
          };
        }
      }
      emailThread.date = emailDraftThreads[i].date;
      emailThread.subject = emailDraftThreads[i].subject;
      emailThread.emails[0] = {
        cid: emailDraftThreads[i].id,
        content: emailDraftThreads[i].content,
        recipients: emailDraftThreads[i].recipients,
        sender: window.accountId,
      }

      setEmailThreads((prevEmails) => [...prevEmails, emailThread]);
      setSearchResults((prevEmails) => [...prevEmails, emailThread]);
   
      console.log('emailThread', emailThread);
    }
  }

  // useEffect(() => {
  //   db.readEmails.toArray().then((emails) => {
  //     const emailsIds = emails.map((email) => email.threadId);
  //     console.log('emailsOID', emailsIds);
  //     console.log('emailThreads', emailThreads);
  //     const containsValue = emailThreads.some((obj) => emailsIds.includes(obj.cid));
  //     console.log('containsValue', containsValue);
  //     if(containsValue) {
  //       setSelectedEmails(emailsIds);
  //     }
  //   });
  // }, [emailThreads]);

  const setOpenThread = (thread) => {
    // console.log('email', email);
    props.setActiveThread(thread)
    // setCurrentEmail(email);
    // setOpenRespondWindow(true);
  }

  // const openDetailsWindow = (emailThread, encryptedEmailsThreads) => {
  //   console.log('email', emailThread);
  //   setCurrentEmail(emailThread);
  //   setCurrentEncryptedEmailsThreads(encryptedEmailsThreads);
  //   setOpenEmailInfoWindow(true);
  // }

  const previousPage = () => {
    if(paginationParams.page_number === 1) {
      return;
    }
    setPaginationParams((prevParams) => {
      return {...prevParams, page_number: prevParams.page_number - 1}
    });
  }

  const nextPage = () => {
    if(paginationParams.page_number * paginationParams.page_size >= inboxCount) {
      return;
    }
    setPaginationParams((prevParams) => {
      return {...prevParams, page_number: prevParams.page_number + 1}
    });
  }

  const refreshEmails = () => {
    setEmailThreads([]);
    setSearchResults([]);
    getDrafts();
  }

  // Function to handle the search
  const handleSearch = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    const filtered = emailThreads.filter((thread) =>
      thread.subject.toLowerCase().includes(newValue.toLowerCase())
    );
    setSearchResults(filtered);
  };

  const deleteDraft = async () => {
    await dbEmails.drafts.bulkDelete(selectedEmails);
    setEmailThreads([]);
    setSearchResults([]);
    getDrafts();
  }

  const selectAllCheckboxes = (event) => {
    const status = event.target.checked;

    console.log('status', status);

    let emailIds = [];
    var x = document.getElementsByClassName("selectitem-input");
    for(let i=0; i<x.length; i++) {
      x[i].checked = status;
      if(status) {
        emailIds.push(parseInt(x[i].value));
      }
    }

    setSelectedEmails(emailIds);
  }

  const setOneSelectedEmail = (event) => {
    const status = event.target.checked;
    const emailId = parseInt(event.target.value);
    console.log('status', status);
    console.log('emailId', emailId);
    if(status) {
      setSelectedEmails((prevEmails) => [...prevEmails, emailId]);
    } else {
      setSelectedEmails((prevEmails) => prevEmails.filter((email) => email !== emailId));
    }
  }
console.log('SELECTED EMAILS:', selectedEmails);
  // useEffect(() => {
  //   console.log('paginationParams', paginationParams);
  //   // setSelectedEmails([]);
  //   setEmailThreads([]);
  //   setSearchResults([]);
  //   // setEncryptedEmailsThreads([]);
  //   getDrafts();
  // }, [paginationParams]);

  const setOpenCompose = (thread) => {
    setOpenComposeWindow(thread);
  }

  return (
    <DraftsWrapper className="">
      <div className="inbox-wrap grid grid-cols-12">
        <div className="inbox-search row-start-1 col-span-12">
          <input type="search" className="search-emails" name="s" placeholder="Search" value={searchValue} onChange={(event) => handleSearch(event)}/>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="search-icon">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </div>
        <div className="row-start-2 col-span-12">
          <div className="inbox-toolbar">
            <div className="inbox-toolbar-btns">
              <input type="checkbox" name="select-all" className="selectall-input" onChange={(event) => selectAllCheckboxes(event)}/>
              <a className="refresh-btn" onClick={() => refreshEmails()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </a>
              {
                selectedEmails && selectedEmails.length > 0 &&
                  <a className="refresh-btn" onClick={() => deleteDraft()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                  </a>
              }
            </div>
            <div className="inbox-toolbar-btns">
              <p className="email-amount">{((paginationParams.page_number - 1) * paginationParams.page_size) + 1}-{paginationParams.page_number * paginationParams.page_size} of {inboxCount}</p>
              <a className="refresh-btn" disabled={paginationParams.page_number === 1 ? true : false} onClick={() => previousPage()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
              </a>
              <a className="refresh-btn" disabled={paginationParams.page_number * paginationParams.page_size === inboxCount ? true : false} onClick={() => nextPage()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </a>
            </div>

          </div>
        </div>
        {
          searchResults.length > 0 && searchResults.map((thread, key) => {
            let date = new Date(thread.date);
            date = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
            let content = '';
            if(thread.emails[0].content.blocks > 0) {
              for(let i = 0; i < thread.emails[0].content.blocks.length; i++) {
                content += thread.emails[0].content.blocks[i].text;
              }
              content = content.substring(0, 50) + '...';
            }
            
            return (
              <div className="col-span-12" key={key}>
                  <div className="inbox-list-item">
                    <input type="checkbox" name="select-item" value={thread.emails[0].cid} checked={selectedEmails.includes(thread.emails[0].cid)} className="selectitem-input" onChange={(event) => setOneSelectedEmail(event)} />
                    <div className="inbox-list-item-wrap" onClick={() => setOpenCompose(thread)}>
                      <p className="inbox-list-item-sender">{thread.emails[0].sender}</p>
                      <p className="inbox-list-item-subject">{thread.subject} - {content}</p>
                      <p className="inbox-list-item-date">{date}</p>
                    </div>
                  </div>
              </div>
            );
          })
        }
      </div>
      {
      openComposeWindow &&
        <Compose thread={openComposeWindow} handleClose={() => setOpenComposeWindow(false)} />
      }
    </DraftsWrapper>
    
  );
}