import styled from "styled-components";


import lockIcon from "../assets/images/lock-icon.png";
import keyIcon from "../assets/images/key-icon.png";

import leftBackground from "../assets/images/left-background.png";
import rightBackground from "../assets/images/right-background.png";
import shieldIcon from "../assets/images/shield-icon.png";

const SectionWrapper = styled.div`

  .title-wrapper {
    padding: 24px 16px;
    border-radius: 24px;
    background: rgba(192, 205, 207, 0.20);
    backdrop-filter: blur(18px);
    height: 100%;

    .title {
      color: #FFF;
      font-family: Nexa;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        font-size: 56px;
        line-height: normal;
        margin-bottom: 16px;
      }

      b {
        font-weight: 800;
      }
    }

    .text {
      color: #FFF;
      font-family: Nexa;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 28px;

      @media (max-width: 768px) {
        font-size: 24px;
        font-weight: 800;
        line-height: 35px;
        margin-bottom: 26px;
      }
    }

    .list {
      color: var(--White, #FFF);
      font-family: Nexa;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;

      @media (max-width: 768px) {
        margin-top: 24px;
        position: relative;
        z-index: 2;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      .desc-image {
        display: none;

        @media (max-width: 768px) {
          display: initial;
          position: absolute;
          width: 30%;
          height: auto;
          top: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }

  .content {
    color: #FFF;
    font-family: Nexa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    margin-bottom: 16px;
  }

  .mobile-section-wrapper {
    display: none;

    @media (max-width: 768px) {
      display: initial;
    }
  
    .text {
      color: #FFF;
      font-family: Nexa;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      margin-bottom: 16px;
    }

    p {
      color: #FFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%; /* 24.8px */
      margin-bottom: 16px;
    }

    .desc-image {
      display: inline-block;
      width: 40%;
      height: auto;

      &.align-right {
        float: right;
      }
    }
  }

  .show-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .hide-mobile {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

`;

export default function InfoContent({handleClose, hash, setOpenDownloadModal, setAnimateFlip, setSocket, peerInstanceReceiver}) {

  return (
    <SectionWrapper className="">
      <div className="title-wrapper">
        <h1 className="title">Transfer with <b>Trust</b></h1>
        <p className="show-mobile content">Pathfinder enables you the secure way to send your files while remaining in full control and ownership over your them. Built on the powerful NEAR protocol blockchain, it gives you free, encrypted and simple peer-to-peer based file sharing.</p>
        <p className="text hide-mobile"><b>Security</b> & sovereignty with <b>decentralized</b> file sharing</p>
        <p className="text show-mobile">Secure, Decentralized, and Empowering file sharing</p>
        <ul className="list relative">
          <li className="item">
            <img src={lockIcon} alt="info-icon" />
            Your data. Your control.
          </li>
          <li className="item">
            <img src={shieldIcon} alt="info-icon" />
            Send files securely.
          </li>
          <li className="item">
            <img src={keyIcon} alt="info-icon" />
            Free, encrypted and simple.
          </li>
          <img src={leftBackground} className="desc-image" alt="path-image" />
        </ul>
      </div>
    </SectionWrapper>
  );
}