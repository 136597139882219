import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useEffect, useRef, useState } from 'react';
import useFileUpload from 'react-use-file-upload';
import Select from 'react-select';
import { encryptFile, getAllUsers, uploadFileToIPFS } from '../utility/functions';
import UploadOption from "./UploadOption";
import styled from "styled-components";
import { EditorState } from "draft-js";
import { uploadFileToMachina } from "../../inc/storageOnMachina";

const ReplyWrap = styled.div`
.reply {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 30px;
  background: #FFF;
  border-radius: 24px;
  padding: 0;
  border: none;
  background: var(--Bg_9, #242C2F);


  .file-list-wrap {
    display: flex;

    .file-name,
    .file-size {
      color: var(--Gray_1, #F1F3F5);
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */

      
    }
  }

  

  .input-text {
    width: 98%;
    padding: 10px;
    // margin-bottom: 10px;
    background: #F1F1F1;
  }

  .input-select {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;

    > div {
      background: var(--Bg_07, #353C40);
      border: none;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input {
      color: #FFF !important;
    }
  }

  .toolbar-class {
    // border: 1px solid black !important;
  }

  .reply-editor {
    overflow-y: auto !important;
    color: var(--Gray_1, #F1F3F5);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    padding: 15px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdw-editor-main {
    background: var(--Bg_10, #1C2129);
    height: 400px;
  }

  .upload-icon {
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    margin-bottom: 6px;
    padding: 5px;
  }

  .upload-icon svg {
    width: 15px;
    height: 15px;
    color: #000;
  }

  .rdw-dropdown-selectedtext {
    text-decoration: none;
    color: #000;
  }

  .reply-content-wrap {
    position: relative;

    .upload-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      .upload-section {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      
      .attachment-text {
        color: var(--Gray_1, #F1F3F5);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        text-align: center;
      }

      .upload-btn {
        background: #7fd971;
        border: none;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(16,24,40,.05);
        margin-top: 10px;
        opacity: .65;
        padding: 10px 30px;
        color: #FFF;
        margin-left: 10px;
        margin-bottom: 10px;

        &:hover {
          opacity: 1;
        }
      }

      .cancel-btn {
        background: #303030;
        border: none;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(16,24,40,.05);
        padding: 10px 60px;
        color: #fff;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .css-3w2yfm-ValueContainer {
    padding: 5px;
  }

  .css-1p3m7a8-multiValue {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: var(--Mint_5, #06C5C5);
    padding: 0;

    .css-wsp0cs-MultiValueGeneric {
      padding: 6px;
      padding-left: 12px;
      color: var(--Bg_10, #1C2129) !important;
    }
  }

  .rdw-editor-wrapper {
    background: #FFF;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    max-width: 1000px;
  }

  .file-list {
    list-style: none;
    text-align: left;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #000;

      .file-size {
        margin-left: 15px;
      }

      .remove-file-icon {
        width: 20px;
        height: 20px;
        color: #FFF;
        margin-left: 15px;
      }
    }
  }

  .submit-btn {
    background: #7FD971 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px 60px !important;
    border: none;
    opacity: 0.65;


    @media (max-width: 620px) {
      padding: 10px 40px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .action-wrap {
    display: flex;

    .progress-bar-wrap {
      display: flex;
      width: 20%;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 10px;

      .message {
        color: var(--Gray_1, #F1F3F5);
      }

      .message-status-wrap {
        display: flex;
        align-items: center;
        width: 100%;
      }
    
      .progress-bar {
        width: 100%;
        height: 10px;
        border-radius: 24px;
        background: var(--Bg_6, #404B51);

      }
    
      .percentage {
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%;
        margin-left: 5px !important;
      }
    }
  }
}
`;

const Reply = (props) => {

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const [progressBarData, setProgressBarData] = useState({message: '', percentage: 0});

  const [openUploadSection, setOpenUploadSection] = React.useState(false);
  const [availableUsers, setAvailableUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [content, setContent] = React.useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const inputRef = useRef();

  const handleSubmit = async (e) => {

    console.log('selectedUsers', selectedUsers)
    console.log('files', files)
    let fileCids = [];
    setProgressBarData({message: 'Encrypting files...', percentage: 10});
    for(let i = 0; i < files.length; i++) {
      let encryptedFile = await encryptFile(files[i], selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true);
      let cid = await uploadFileToMachina(encryptedFile, window.accountId, (Date.now() + i).toString());
      // let cid = await uploadFileToIPFS(encryptedFile)
      // fileCids.push(cid.ipnft);
      console.log('cid', cid)
      fileCids.push(cid);
    }
    // console.log('fileCids', fileCids)
    // return;
    setProgressBarData({message: 'Storing attachments...', percentage: 40});
    const email = {
      content: content,
      date: new Date(),
      attachments: fileCids,
      recipients: selectedUsers.map(x => x.value),
      sender: window.walletConnection.getAccountId()
    }

    const emailFile = new File([JSON.stringify(email)], Date.now() + '.json', {type: "application/json"});
    const encryptedEmailFile = await encryptFile(emailFile, selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true, true);
    let emailData = {};
    emailData = {
      emails: [...props.encryptedThread.emails, encryptedEmailFile],
      subject: props.thread.subject,
    }
    // console.log('emailData', emailData)
    // return;
    const fileWithMetadata = new File([JSON.stringify(emailData)], Date.now() + '.json', {type: "application/json"});
    // console.log('fileWithMetadata', fileWithMetadata)
    const encryptedFile = await encryptFile(fileWithMetadata, selectedUsers.map(x => {return {value: x.value, publicKey: x.publicKey}}), window.walletConnection.getAccountId(), true);
    // console.log('encryptedFile', encryptedFile)
    setProgressBarData({message: 'Saving to smart contract...', percentage: 80});
    uploadFileToMachina(encryptedFile, window.accountId, (Date.now() + 1).toString()).then((cid) => {
    // uploadFileToIPFS(encryptedFile).then((cid) => {
      let allThreadUsers = [];
      allThreadUsers = selectedUsers.map(x => x.value);
      allThreadUsers.push(window.walletConnection.getAccountId());


      if(props.thread) {
        props.thread.emails[0].recipients.forEach((recipient) => {
          allThreadUsers.push(recipient);
        });
      }
      allThreadUsers = [...new Set(allThreadUsers)];
      const oldThreadCid = props.encryptedThread ? props.encryptedThread.cid : "false";
      storeEmailOnContract(cid, allThreadUsers, oldThreadCid);
      
    });
  };

  const storeEmailOnContract = async (emailHash, recipients, oldThreadCid) => {
    const queryArgs = recipients.join(',');

    if(localStorage.getItem('wallet-selector') === 'sender') {
      const tx = {
        receiverId: process.env.REACT_APP_CONTRACT_NAME_MAIL,
        actions: [
          {
            methodName: 'store_email',
            args: {
              email_ipfs_hash: String(emailHash),
              recipients: recipients,
              old_email_ipfs_hash: oldThreadCid,
            },
            deposit: '0',
            gas: '300000000000000'
          }
        ]
      }
      const res = await window.near.signAndSendTransaction(tx);
      console.log('RES: ', res);
    }else {
      await window.emailContract.store_email(
        {
          callbackUrl: window.location.href + '?send_email=' + queryArgs, // callbackUrl after the transaction approved (optional)
          args: {
            email_ipfs_hash: String(emailHash),
            recipients: recipients,
            old_email_ipfs_hash: oldThreadCid,
          },
        }
      );
    }
    setProgressBarData({message: 'Email sent...', percentage: 100});
    clearAllFiles();
    setAvailableUsers([...selectedUsers, ...availableUsers]);
    setSelectedUsers([]);
    setEditorState(EditorState.createEmpty());
  }

  const onContentChange = (editorState) => {
    setContent(editorState);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  }

  useEffect(() => {
    getAllUsers().then((users) => {
      let currentlySelectedUsers = [];
      const sender = users.find(user => user.value === props.email.sender);
      if(sender.value === window.walletConnection.getAccountId()) {
        const possibleRecipients = users.filter(user => props.email.recipients.includes(user.value)).map(user => {return {value: user.value, label: user.value, publicKey: user.publicKey}});
        setSelectedUsers(possibleRecipients);
      }else {
        currentlySelectedUsers.push({value: props.email.sender, label: props.email.sender, publicKey: sender.publicKey});
        setSelectedUsers(currentlySelectedUsers);
      }
      const possibleRecipients = users.filter(user => props.email.sender !== user.value && user.value !== window.walletConnection.getAccountId());
      setAvailableUsers(possibleRecipients);
    });
  }, []);

  const onUserSelect = (value) => {
    console.log('onUserSelect', value)
    setSelectedUsers(value);
  };

  const handleInputChange = (inputValue) => {
    // console.log('Input Changed');
    // console.log(inputValue);
    // if(inputValue && (inputValue.length === 42 || inputValue.endsWith('.testnet'))) {
    //   console.log('Input Changed')
    //   setAvailableUsers([{value: inputValue, label: inputValue}]);
    // }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const fillerStyles = {
    height: '100%',
    width: `${progressBarData.percentage}%`,
    background: '#7FD971',
    borderRadius: '10px',
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#FFF',
        textAlign: 'left',
      };
    },
  };


  return (
    <ReplyWrap>
      <div className="reply">
        <Select 
          isMulti
          options={availableUsers} 
          onChange={(values) => onUserSelect(values)}
          onInputChange={(inputValue) => handleInputChange(inputValue)}
          value={selectedUsers}
          className="input-select"
          // menuIsOpen={true}
          styles={colourStyles}
        />
        <div className="reply-content-wrap">
          <Editor
            toolbarClassName="toolbar-class"
            editorClassName="reply-editor"
            onChange={onContentChange}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbarCustomButtons={[<UploadOption setOpenUploadSection={setOpenUploadSection} openUploadSection={openUploadSection} />]}
          />
          {
            openUploadSection && (
              <div className="upload-wrapper">
                <div
                  onDragEnter={handleDragDropEvent}
                  onDragOver={handleDragDropEvent}
                  onDrop={(e) => {
                    handleDragDropEvent(e);
                    setFiles(e, 'a');
                    setOpenUploadSection(false);
                  }}

                  className="upload-section"
                >
                  <p className="attachment-text">Drag and drop files here</p>
                  <div className="upload-button-wrap">
                    <button onClick={() => setOpenUploadSection(false)} className="cancel-btn">Cancel</button>
                    <button onClick={() => inputRef.current.click()} className="upload-btn">Or select files</button>
                  </div>
                  <p className="attachment-text">to upload!</p>

                  <input
                    ref={inputRef}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      setFiles(e, 'a');
                      setOpenUploadSection(false);
                      inputRef.current.value = null;
                    }}
                  />
                </div>
              </div>
            )
          }
        </div>
        <div className="form-container">
          <div className="file-list-wrap">
            <ul className="file-list">
              {files.map((file) => (
                <li key={file.name}>
                  <span className="file-name">{file.name}</span>
                  <span className="file-size"> {formatBytes(file.size)}</span>
                  <span onClick={() => removeFile(file.name)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="remove-file-icon">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="action-wrap">
          <button variant="primary" className="btn-primary" onClick={() => handleSubmit()}>
            Send
          </button>
          {
            progressBarData.percentage > 0 && (
              <div className="progress-bar-wrap">
                <p className="message">{progressBarData.message}</p>
                <div className="message-status-wrap">
                  <div className="progress-bar">
                    <p style={fillerStyles}></p>
                  </div>
                  <p className="percentage">{progressBarData.percentage}%</p>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </ReplyWrap>
  );
}

export default Reply;
