import styled from "styled-components";
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import CurrentDownloads from "./current-transfers/CurrentDownloads";
import CurrentActiveTransfers from "./current-transfers/CurrentActiveTransfers";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/GlobalProvider";
import DownloadHistory from "./history/HistoryDownload";
import UploadHistory from "./history/HistoryUpload";

import sharedTo from "../../assets/images/shared-to.png";
import sharedBy from "../../assets/images/shared-by.png";

const HistoryWrap = styled.div`

  @media (max-width: 768px) {
    padding: 0 12px;
  }

  .tab-header {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .shared-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .shared-title {
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;

        @media (max-width: 1024px) {
          font-size: 15px;
        }

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .underline-border {
    border-color: #D9D9D9;
  }

  .title {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 32px;
  }

  .subtitle {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    margin-bottom: 40px;
  }

  .tabs-wrapper {
    width: 50%;

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

`;


export default function HistoryWrapper() {
  const [myFilesArchive, setMyFilesArchive] = useState([]);
  const [myFilesDownloads, setMyFilesDownloads] = useState([]);

  const { myUsername } = useGlobalContext();
 
  useEffect(() => {
    getMetrics();
  }, []);

  const getMetrics = async () => {
    fetch(process.env.REACT_APP_SERVER_URL + '/users/get-metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
      },
      body: JSON.stringify({ username: myUsername }),
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data) {
        setMyFilesArchive(data.sharedBy);
        setMyFilesDownloads(data.sharedTo);
      }
    });
  };


  return (
    <HistoryWrap>
      <h2 className="title row-start-1">History</h2>
      <h3 className="subtitle">Details about transfers and actions you and your recipients made with the shared files.</h3>
     <Tabs value="downloads" className="">
      <TabsHeader
        className="bg-transparent tabs-wrapper"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 underline-border shadow-none rounded-none",
        }}
      >
        <Tab key="downloads" value="downloads" className="tab-header">
          <img src={sharedTo} alt="shared-to" className="shared-icon" />
          <p className="shared-title">Your actions</p>
        </Tab>
        <Tab key="uploads" value="uploads" className="tab-header">
          <img src={sharedBy} alt="shared-to" className="shared-icon" />
          <p className="shared-title">Recipients actions</p>
        </Tab>
      </TabsHeader>
      <TabsBody>
        <TabPanel key="downloads" value="downloads">
          <DownloadHistory myFilesArchive={myFilesArchive} />
        </TabPanel>
        <TabPanel key="uploads" value="uploads">
          <UploadHistory uploadFileHistory={myFilesDownloads} />
        </TabPanel>
      </TabsBody>
    </Tabs>
    </HistoryWrap>
  )
}