import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { blurPage, disablePrint, disableRightClick, toBlur, toClear } from '../../inc/viewerFunctions';
import { Dialog, DialogBody } from '@material-tailwind/react';
import styled from 'styled-components';
import cancelButton from '../../assets/images/cancel-x.png';


const ViewerWrapper = styled.div`

  .viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--BG_8, #283134);
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #FFF;

    .close-btn {
      width: 24px;
      height: 24px;
    }
  }

  .react-pdf__Document {
    max-height: 700px;
    overflow: auto;
  }

  #image-img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export default function VideoViewer({file, handleClose}) {
  
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [fileTitle, setFileTitle] = useState('');

  useEffect(() => { 
    disableRightClick();
    disablePrint();
    blurPage();
    setFileTitle(file.file.name);
    console.log('file', file);
    console.log('file.name', file.name)
    console.log('file uri', URL.createObjectURL(file.file));
    setVideoFilePath(URL.createObjectURL(file.file));
    
  }, []);

  return (
    <Dialog open={true} size="lg" className="bg-transparent shadow-none file-viewer" onMouseEnter={() => toClear()} onMouseLeave={() => toBlur()}>
      <ViewerWrapper>
        <DialogBody>
          <div className="viewer-header">
            <p className="file-title">{fileTitle}</p>
            <button className="close-btn" onClick={() => handleClose()} >
              <img className="cancel-icon" src={cancelButton} alt="cancel button" />
            </button>
          </div>
          {
            videoFilePath && <ReactPlayer url={videoFilePath} width="100%" height="100%" controls={true} />
          }
        </DialogBody>
      </ViewerWrapper>
    </Dialog>
  );
}