import { Contract } from 'near-api-js'
import getEmailConfig from './config'

import { Buffer } from 'buffer';
import { checkIfSignedIn } from '../../../assets/near/utils';

window.Buffer = Buffer;

const nearConfig = getEmailConfig(process.env.REACT_APP_NETWORK_ID);

// Initialize contract & set global variables
export async function initMailContract() {

  const currentWallet = localStorage.getItem('wallet-selector');
  if(!currentWallet) {
    return;
  }
  if(currentWallet === 'sender') {
    if(checkIfSignedIn()) {
      window.emailContract = new Contract(window.near.account(), nearConfig.contractName, {
        // View methods are read only. They don't modify the state, but usually return some value.
        viewMethods: ['get_recieved_emails', 'get_sent_emails'],
      });
    }
  }else {
    // Initializing our contract APIs by contract name and configuration
    window.emailContract = new Contract(window.walletConnection.account(), nearConfig.contractName, {
      // View methods are read only. They don't modify the state, but usually return some value.
      viewMethods: ['get_recieved_emails', 'get_sent_emails'],
      // Change methods can modify the state. But you don't receive the returned value when called.
      changeMethods: ['store_email'],
    });
  }
  
}
