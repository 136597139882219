import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import React, { useEffect, useRef } from "react";
import cancelButton from '../assets/images/cancel-x.png';
import styled from "styled-components";

const MetricInfoWrap = styled.div`
  background: var(--BG_8, #283134);
  box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
  border-radius: 32px;
  padding: 24px; 

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

      .title {
        color: #FFF;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }

      .modal-subtitle {
        color: #FFF;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 155%;
      }
      .cancel-icon {
        width: 24px;
        height: 24px;
      }
  }
  .info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .message {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      margin-top: 10px !important;
    }
  }
`;

export default function MetricInfoModal({metricData, handleClose, isShared}) {

  const date= new Date(parseInt(metricData.date));
  let recipient = useRef('');

  const formatBytes = (bytes, decimals = 2) => {
    if(!+bytes) {
      return '0 Bytes';
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  useEffect(() => {
    if(isShared) {
      recipient.current = metricData.sharedTo;
    }else {
      recipient.current = metricData.sharedBy;
    }
    
    console.log('recipient', recipient.current);
    if(recipient.current.length >= 32) {
      recipient.current = recipient.current.slice(0,6) + '...' + recipient.current.slice(-4);
    }
    console.log('recipient', recipient.current);
  }, []);

  const formattedPermissions = metricData.permissions.toString().replace('raw', 'View');

  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="xs">
      <MetricInfoWrap>
        <DialogHeader  className="modal-header">
          <div className="modal-header-text">
            <p className="title">Transfer info</p>
            <p className="modal-subtitle">Here you can see all the information about the transfer.</p>
          </div>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
              <div className="info-wrap">
                <p className="message">File name:</p>
                <p className="message">{metricData.name}</p>
              </div>
              <div className="info-wrap">
                <p className="message">Date:</p>
                <p className="message">{date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '. ' + date.getHours() + ":" + date.getMinutes() + " "}</p>
              </div>
              <div className="info-wrap">
                <p className="message">Size:</p>
                <p className="message">{formatBytes(metricData.size)}</p>
              </div>
              <div className="info-wrap">
                <p className="message">{isShared ? 'Shared to:' : 'Shared by:'}</p>
                <p className="message">{isShared ? metricData.sharedTo : metricData.sharedBy}</p>
              </div>
              <div className="info-wrap">
                <p className="message">Transfer type:</p>
                <p className="message">{metricData.transferType}</p>
              </div>
              <div className="info-wrap">
                <p className="message">Device:</p>
                <p className="message">{metricData.device}</p>
              </div>
              <div className="info-wrap">
                <p className="message">IP address:</p>
                <p className="message">{metricData.ip}</p>
              </div>
              <div className="info-wrap">
                <p className="message">Country:</p>
                <p className="message">{metricData.country}</p>
              </div>
              <div className="info-wrap">
                <p className="message">City:</p>
                <p className="message">{metricData.city}</p>
              </div>
              <div className="info-wrap">
                <p className="message">Action:</p>
                <p className="message">{metricData.action}</p>
              </div>
              {/* {
                isShared &&
                <div className="info-wrap">
                  <p className="message">Permissions:</p>
                  <p className="message">{formattedPermissions}</p>
                </div>
              } */}
        </DialogBody>
      </MetricInfoWrap>
    </Dialog>
  )
}