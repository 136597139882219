import styled from "styled-components";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/GlobalProvider';
import * as bs58 from 'bs58';
import cancelButton from '../assets/images/cancel-x.png';

import * as bip39 from 'bip39';
import crypto from 'crypto-browserify';
import nacl from 'tweetnacl';
import { saveAs } from 'file-saver';
import Dropzone from 'react-dropzone';
import { Scanner } from "@yudiel/react-qr-scanner";
import QRCode from "react-qr-code";
import { is } from "immutable";
import { decryptPrivateKey } from "../inc/encryption";


const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px 10px; 

    @media (max-width: 767px) {
      padding: 24px 8px;
    }
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-title-wrap {
        .cancel-icon {
          width: 24px;
          height: 24px;
        }
      }

      .cancel-icon {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      color: #FFF;
      font-family: Open Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
    }

    .subtitle {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }

    .content {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
    }

    .message {
      color: #30BD5C;
      margin-bottom: 24px;

      &.error {
        color: #FF0000;
      }

      &.success {
        color: #30BD5C;
      }
    }

    .modal-footer {
      justify-content: center;
    }

    .password,
    .seed-phrase {
      color: #FFF;
      font-weight: 500;
      background: var(--BG_8, #283134);
      border: 1px solid #FFF;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
      margin-bottom: 16px;
    }

    .seed-phrase {
      display: flex;
      padding: 48px 24px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px dashed #4C585F;
      cursor: pointer;

      .text-wrapper {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #353C40;
      }
    }

    .paste-key {
      color: #FFF;
      font-weight: 500;
      background: var(--BG_8, #283134);
      border: 1px solid #FFF;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
    }

    .info-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      
      .step-action {
        margin-bottom: 16px;
      }

      .additional-info {
        color: #FFF;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;
      }

      .btn-primary {

        @media (max-width: 767px) {
          padding: 8px 16px;
          font-size: 15px;
        }
      }
    }
  `;


export default function EncryptionKeyExportModal({ handleClose }) {

  const [generateQrCode, setOpenGenerateQrCode] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const [openQRScanner, setOpenQRScanner] = useState(false);
  const [openPasteInput, setOpenPasteInput] = useState(false);
  const [openUploadInput, setOpenUploadInput] = useState(false);

  const { myUsername } = useGlobalContext();

  const encryptedPrivateKey = localStorage.getItem(window.walletConnection.getAccountId() + '_encryptionKey');
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [isError, setIsError] = useState(false);

  const downloadKey = async () => {
    saveAs(new Blob([encryptedPrivateKey]), window.walletConnection.getAccountId() + '_pathfinder.txt');
  }

  const generateQRCode = () => {
    const jsonEncryptedPrivateKey = JSON.parse(encryptedPrivateKey);
    jsonEncryptedPrivateKey.owner = window.walletConnection.getAccountId();

    const qrData = JSON.stringify(jsonEncryptedPrivateKey);
    const qrDataUint8 = new TextEncoder().encode(qrData);
    const qrDataEncoded = bs58.encode(qrDataUint8);
    console.log('qrDataEncoded', qrDataEncoded);
    setQrCodeValue(qrDataEncoded);
    setOpenGenerateQrCode(true);
  }

  const copyToClipBoard = () => {
    const el = document.createElement('textarea');
    el.value = encryptedPrivateKey;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('Key copied to clipboard');
  }

  const scanResults = (text, result) => {
    if(result) {
      const qrDataDecoded = bs58.decode(result.text);
      const qrData = JSON.parse(new TextDecoder().decode(qrDataDecoded));
      console.log('qrData', qrData);
      if(qrData.owner !== window.walletConnection.getAccountId()) {
        alert('This QR code is not for your account');
        return;
      }else {
        //Remove owner from the qrData
        delete qrData.owner;
        localStorage.setItem(window.walletConnection.getAccountId() + '_encryptionKey', JSON.stringify(qrData));
        decryptPrivateKey().then((decryptedKeyPair) => {
          if(decryptedKeyPair === null) {
            setStatusMessage('The imported secret key cannot be decrypted. Please generate a new secret key or scan the correct secret key QR code.')
            setIsError(true);
          }else {
            setStatusMessage('The imported secret key has been successfully imported.')
            setIsError(false);
          }
          setOpenQRScanner(false);
        });
      }
    }
  }

  const submitPrivateKey = () => {
    try {
      const data = JSON.parse(encryptionKey);
      localStorage.setItem(myUsername + '_encryptionKey', encryptionKey);
      console.log('data', encryptionKey);

      decryptPrivateKey().then((decryptedKeyPair) => {
        if(decryptedKeyPair === null) {
          setStatusMessage('The imported secret key cannot be decrypted. Please generate a new secret key or paste the correct secret key.');
          setIsError(true);
          setOpenPasteInput(false);
          return;
        }else {
          setStatusMessage('The inserted secret key has been successfully imported.');
          setIsError(false);
          setOpenPasteInput(false);
        }
      });
    }catch(e) {
      console.log(e);
      setStatusMessage('The inserted secret key is not valid. Please insert a valid secret key.')
      setIsError(true);
      setOpenPasteInput(false);
    }
  }

  const uploadSecretKey = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      try {
        const data = JSON.parse(reader.result);
        localStorage.setItem(myUsername + '_encryptionKey', reader.result);
        decryptPrivateKey().then((decryptedKeyPair) => {
          if(decryptedKeyPair === null) {
            console.log('No private key found');
            setStatusMessage('The uploaded secret key cannot be decrypted. Please generate a new secret key or upload the correct secret key file.')
            setIsError(true);
            setOpenUploadInput(false);
            
            return;
          }else {
            setStatusMessage('The uploaded secret key has been successfully imported.')
            setIsError(false);
            setOpenUploadInput(false);
          }
        });
      }catch(e) {
        console.log(e);
        setStatusMessage('The uploaded secret key cannot be decrypted. Please generate a new secret key or upload the correct secret key file.')
        setIsError(true);
        setOpenUploadInput(false);
      }
     
    };
    reader.onerror = function () {
      console.log(reader.error);
    };
  }

  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="xs" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="modal-title-wrap">
            <p className="title">Encryption key management!</p>
          </div>
          <button className="close-btn" onClick={() => handleClose(false)} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody className="grid grid-cols-12 grid-rows-flow ">
          {
            openQRScanner ? (
              <>
                <div className="col-span-12 row-span-2">
                  <p className="title mb-5">Scan the QR code generated on another device to import it!</p>
                  <Scanner
                    onResult={(text,result) => scanResults(text,result)}
                    onError={(err) => {
                      console.error(err)
                    }}
                  />
                  <button className="btn-primary" style={{marginTop: '12px'}} onClick={() => setOpenQRScanner(false)}>Close</button>
                </div>
              </>
            )
            :
            ( generateQrCode ? (
              <div className="col-span-12 row-span-2">
                <p className="title mb-5">Scan the QR code below from different device to import your encryption key!</p>
                <div style={{ background: 'white', padding: '16px' }}>
                  <QRCode value={qrCodeValue} style={{width: '100%', height: 'auto'}} />
                </div>
                <div className="info-wrap mt-5">
                  <button className="btn-primary" onClick={() => setOpenGenerateQrCode(false)}>
                    Close
                  </button>
                </div>
              </div> )
              :
              ( openPasteInput ? (
                <div className="col-span-12 row-span-2">
                  <p className="title mb-5">Paste the encryption key below to import it!</p>
                  <input type="text" className="paste-key" placeholder="Paste the encryption key here" onChange={(e) => setEncryptionKey(e.target.value)} />
                  <div className="info-wrap mt-5">
                    <button className="btn-primary" onClick={() => setOpenPasteInput(false)}>
                      Close
                    </button>
                    <button className="btn-primary" onClick={() => submitPrivateKey()}>
                      Submit
                    </button>
                  </div>
                </div> )
                :
                ( openUploadInput ? (
                  <div className="col-span-12 row-span-2">
                    <p className="title mb-5">Upload the encryption key below to import it!</p>
                    <Dropzone onDrop={(acceptedFiles) => uploadSecretKey(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                      <section className="seed-phrase" {...getRootProps()}>
                        <div className="text-wrapper">
                          <input {...getInputProps()} />
                          {
                            <>
                              <p className="input-text"><b>Upload encryption key</b></p>
                              <p className="input-text">Drag'n'drop file here or click to upload.</p>
                            </>
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                    <div className="info-wrap mt-5">
                      <button className="btn-primary" onClick={() => setOpenUploadInput(false)}>
                        Close
                      </button>
                    </div>
                  </div> )
                  :
                  ( openQRScanner ? (
                    <div className="col-span-12 row-span-2">
                      <p className="title mb-5">Scan the QR code generated on another device to import it!</p>
                      <Scanner
                        onResult={(text,result) => scanResults(text,result)}
                        onError={(err) => {
                          console.error(err)
                        }}
                      />
                      <button className="btn-primary" style={{marginTop: '12px'}} onClick={() => setOpenQRScanner(false)}>Close</button>
                    </div>)
                    : (
                <>
                <div className="col-span-12 row-span-1 mb-5">
                  {
                    statusMessage ? 
                      <p className={isError ? "message error" : "message success"}>{statusMessage}</p>
                    :
                    (
                      <>
                        <p className="subtitle">Transfer your secret encryption key to another device by scanning the QR code, copying or downloading the key.</p>
                      </>
                    )
                  }
                </div>
                <div className="col-span-12 row-span-2">
                  <p className="subtitle">Export actions:</p>
                </div>
                <div className="col-span-12 row-span-2 info-wrap mt-5">
                  <button className="btn-primary step-action" onClick={() => generateQRCode()}>
                    Generate QR code
                  </button>
                  <button className="btn-primary step-action" onClick={() => copyToClipBoard()}>
                    Copy key
                  </button>
                  <button className="btn-primary step-action" onClick={() => downloadKey()}>
                    Download key
                  </button>
                </div>
                <div className="col-span-12 row-span-2">
                  <p className="subtitle">Import actions:</p>
                </div>
                <div className="col-span-12 row-span-2 info-wrap mt-5">
                  <button className="btn-primary step-action" onClick={() => setOpenQRScanner(true)}>
                    Scan QR code
                  </button>
                  <button className="btn-primary step-action" onClick={() => setOpenPasteInput(true)}>
                    Paste key
                  </button>
                  <button className="btn-primary step-action" onClick={() => setOpenUploadInput(true)}>
                    Upload key
                  </button>
                </div>
              </>
            )))))
          }
        </DialogBody>
      </ModalWrapper>
    </Dialog>
  )
}