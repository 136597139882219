import styled from "styled-components";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/GlobalProvider';
import * as bs58 from 'bs58';
import cancelButton from '../assets/images/cancel-x.png';


import * as bip39 from 'bip39';
import crypto from 'crypto-browserify';
import nacl from 'tweetnacl';
import { saveAs } from 'file-saver';
import Dropzone from 'react-dropzone';
import { decryptPrivateKey } from "../inc/encryption";
import { Scanner } from "@yudiel/react-qr-scanner";


const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px; 

    @media (max-width: 767px) {
      padding: 24px 8px;
    }
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-title-wrap {
        .cancel-icon {
          width: 24px;
          height: 24px;
        }
      }

      .cancel-icon {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      color: #FFF;
      font-family: Open Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
    }

    .subtitle {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;

      &.error {
        color: #FF0000;
      }
    }

    .content {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
    }

    .message {
      color: #30BD5C;
      margin-bottom: 24px;
    }

    .modal-footer {
      justify-content: center;
    }

    .paste-key {
      color: #FFF;
      font-weight: 500;
      background: var(--BG_8, #283134);
      border: 1px solid #FFF;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
    }

    .password,
    .seed-phrase {
      color: #FFF;
      font-weight: 500;
      background: var(--BG_8, #283134);
      border: 1px solid #FFF;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
      margin-bottom: 16px;
    }

    .seed-phrase {
      display: flex;
      padding: 48px 24px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px dashed #4C585F;
      cursor: pointer;

      .text-wrapper {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #353C40;
      }
    }

    .info-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .additional-info {
        color: #FFF;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;
      }

      .btn-primary {

        @media (max-width: 767px) {
          padding: 8px 16px;
          font-size: 15px;
        }
      }
    }
  `;


export default function EncryptionKeyModal({ handleClose }) {

  const { setEncryptionKey, oldPrivateKey, encryptionKey, openKeyModal, setOpenKeyModal, isKeyError, setIsKeyError, setOpenOnboardingModal, myUsername } = useGlobalContext();
  const [openUploadSection, setOpenUploadSection] = useState(false);
  const [keyUploaded, setKeyUploaded] = useState(false);

  const [processing, setProcessing] = useState(false);
  const keyExists = localStorage.getItem(myUsername + '_encryptionKey');
  const keyPassword = sessionStorage.getItem('keyPassword');

  const [showImportOptions, setShowImportOptions] = useState(false);
  const [openQRScanner, setOpenQRScanner] = useState(false);
  const [showPasteField, setShowPasteField] = useState(false);

  useEffect(() => {
    if(oldPrivateKey) {
      generateKeyPair();
    }

    if(keyPassword && !keyExists) {
      setShowImportOptions(true);
    }
    
  }, []);

  useEffect(() => {
    if(openKeyModal && !oldPrivateKey) {
      setEncryptionKey(false);
    }
  }, [openKeyModal])

  const generateKeyPair = async () => {
    console.log('keyPassword', keyPassword);
    setProcessing(true);
    let privateKey;
    let keyUint8;
    if(!oldPrivateKey) {
      // console.log('no private key present')
      const mnemonic = bip39.generateMnemonic();
      // console.log('mnemonic', mnemonic);
      // Generate a seed from the mnemonic
      const seed = bip39.mnemonicToSeedSync(mnemonic);

      // console.log('seed', seed);
      // console.log('seed HR', seed.toString('hex'));
      
      // Derive a 32-byte key from the seed
      keyUint8 = crypto.pbkdf2Sync(seed, keyPassword, 100000, 32, 'sha512');
      privateKey = keyUint8.toString('hex');
    }else {
      // window.account.connection.signer.keyStore.setKey(window.account.connection.networkId, myUsername, oldPrivateKey);
      // console.log('KEY SET! ');
      // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, myUsername);
      // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
      // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
      // console.log('hostKeyPair old way', hostKeyPair);
      // console.log('private key present', oldPrivateKey);
      privateKey = oldPrivateKey;

      keyUint8 = bs58.decode(privateKey).slice(0, 32);
      privateKey = keyUint8.toString('hex');
    }
    
    // console.log('private key', privateKey);
    // console.log('keyUint8', keyUint8);
    // Generate KeyPair from the private key
    // const keyPair = nacl.box.keyPair.fromSecretKey(keyUint8)
    // console.log('keyPair', keyPair);

    // Create a SHA-256 hash of your password
    const hash = crypto.createHash('sha256');
    hash.update(keyPassword);
    const key = hash.digest();

    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encryptedPrivateKey = cipher.update(privateKey, 'utf8', 'hex');
    encryptedPrivateKey += cipher.final('hex');
    console.log('encryptedPrivateKey: ', encryptedPrivateKey);
    console.log('iv: ', iv.toString('hex'));
    // setEncryptedData({seed: encryptedPrivateKey, iv: iv.toString('hex')});
    localStorage.setItem(myUsername + '_encryptionKey', JSON.stringify({seed: encryptedPrivateKey, iv: iv.toString('hex')}));
    setEncryptionKey({seed: encryptedPrivateKey, iv: iv.toString('hex')});
    // console.log('ENCRYPTED: ', encryptedPrivateKey);
  };

  const uploadEncryptionKey = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      try {
        const data = JSON.parse(reader.result);
        console.log('data', data);
        localStorage.setItem(myUsername + '_encryptionKey', reader.result);
        decryptPrivateKey().then((decryptedKeyPair) => {
          if(decryptedKeyPair === null) {
            console.log('No private key found');
            setIsKeyError('The uploaded secret key cannot be decrypted. Please generate a new secret key or upload the correct secret key file.')
            setKeyUploaded(false);
            setOpenUploadSection(false);
            return;
          }else {
            setEncryptionKey(data);
            setKeyUploaded(true);
            setOpenUploadSection(false);
          }
        });
      }catch(e) {
        console.log(e);
        setIsKeyError('The uploaded secret key cannot be decrypted. Please generate a new secret key or upload the correct secret key file.')
        setKeyUploaded(false);
        setOpenUploadSection(false);
      }
     
    };
    reader.onerror = function () {
      console.log(reader.error);
    };
  }

  const finishProcess = async () => {
    setOpenKeyModal(false);
  }

  const downloadKey = () => {
    if(!keyUploaded) {
      saveAs(new Blob([encryptionKey]), myUsername + '_pathfinder.txt');
    }
  }

  const copyToClipBoard = () => {
    const el = document.createElement('textarea');
    el.value = encryptionKey;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('Key copied to clipboard');
  }

  const scanResults = (text, result) => {
    if(result) {
      const qrDataDecoded = bs58.decode(result.text);
      const qrData = JSON.parse(new TextDecoder().decode(qrDataDecoded));
      if(qrData.owner !== myUsername) {
        setIsKeyError('This QR code is not for your account');
        setOpenQRScanner(false);
        return;
      }else {
        //Remove owner from the qrData
        delete qrData.owner;
        localStorage.setItem(myUsername + '_encryptionKey', JSON.stringify(qrData));        
        decryptPrivateKey().then((decryptedKeyPair) => {
          if(decryptedKeyPair === null) {
            setIsKeyError('The imported secret key cannot be decrypted. Please generate a new secret key or scan the correct secret key QR code.')
            setOpenQRScanner(false);
          }else {
            setKeyUploaded(true);
            setEncryptionKey(qrData);
            setOpenQRScanner(false);
          }
        });
      }
    }
  }

  const submitPrivateKey = () => {
    try {
      JSON.parse(privateKeyString);
      localStorage.setItem(myUsername + '_encryptionKey', privateKeyString);
      console.log('data', privateKeyString);

      decryptPrivateKey().then((decryptedKeyPair) => {
        if(decryptedKeyPair === null) {
          setIsKeyError('The imported secret key cannot be decrypted. Please generate a new secret key or paste the correct secret key.')
          setKeyUploaded(false);
          setShowPasteField(false);
          return;
        }else {
          setKeyUploaded(true);
          setShowPasteField(false);
          setEncryptionKey(privateKeyString);
          setShowImportOptions(false);
        }
      });
    }catch(e) {
      console.log(e);
      setIsKeyError('The imported secret key cannot be decrypted. Please generate a new secret key or paste the correct secret key.')
      setKeyUploaded(false);
      setShowPasteField(false);
    }
  }

  const [privateKeyString, setPrivateKeyString] = useState(null);
  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="xs" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="modal-title-wrap">
            <p className="title">Hello {myUsername}, welcome to Pathfinder!</p>
          </div>
          
        </DialogHeader>
        <DialogBody className="grid grid-cols-12 grid-rows-flow ">
          {
            !encryptionKey ?
              showImportOptions ?
                showPasteField ?
                  <>
                    <div className="col-span-12 row-span-2">
                      <p className="subtitle">Paste your encryption key below:</p>
                    </div>
                    <div className="col-span-12 row-span-1 info-wrap mt-5">
                      <input type="text" className="paste-key" placeholder="Paste key here" onChange={(e) => setPrivateKeyString(e.target.value)} />
                    </div>
                    <div className="col-span-12 row-span-4 mt-5 info-wrap">
                      <button className="btn-primary mr-3" onClick={() => setShowPasteField(false)}>
                        Back
                      </button>
                      <button className="btn-primary" onClick={() => submitPrivateKey()}>
                        Submit
                      </button>
                    </div>
                  </>
                
                : openQRScanner ?
                <>
                  <div className="col-span-12 row-span-2 mb-5">
                    <p className="subtitle">Scan the QR code generated on another device to import it!</p>
                    <Scanner
                      onResult={(text,result) => scanResults(text,result)}
                      onError={(err) => {
                        console.error(err)
                      }}
                    />
                  </div>
                  <div className="col-span-12 row-span-2 info-wrap mt-5">
                    <button className="btn-primary" onClick={() => setOpenQRScanner(false)}>
                      Back
                    </button>
                  </div>
                </>
                : openUploadSection ?
                <>
                  <div className="col-span-12 row-span-2 mb-5">
                    <p className="title mb-5">Upload encryption file</p>
                    <p className="content">
                      Please upload your generated secret key, that you downloaded during the initial setup of your Pathfinder account. The file should be named in the following format: 
                      <br />
                      <b>your_account_id_pathfinder.txt</b>
                    </p>

                    <div className="info-wrap mt-5">
                      <Dropzone onDrop={(acceptedFiles) => uploadEncryptionKey(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                          <section className="seed-phrase" {...getRootProps()}>
                            <div className="text-wrapper">
                              <input {...getInputProps()} />
                              {
                                <>
                                  <p className="input-text"><b>Upload file</b></p>
                                  <p className="input-text">Drag'n'drop file here or click to upload.</p>
                                </>
                              }
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="col-span-12 row-span-4 mt-5 info-wrap">
                      <button className="btn-primary" onClick={() => setOpenUploadSection(false)}>
                        Back
                      </button>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-span-12 row-span-2 mb-5">
                    <p className={isKeyError ? "error subtitle" : "subtitle"}>{isKeyError ? isKeyError : 'You should already have encryption key.'}</p>
                  </div>
                  <div className="col-span-12 row-span-2 info-wrap mt-5">
                    <button className="btn-primary mb-3 mr-3" onClick={() => setOpenUploadSection(true)}>
                      Upload key
                    </button>
                    <button className="btn-primary mb-3 mr-3" onClick={() => setOpenQRScanner(true)}>
                      Scan QR code
                    </button>
                    <button className="btn-primary mb-3 mr-3" onClick={() => setShowPasteField(true)}>
                      Paste key
                    </button>
                    <button className="btn-primary mb-3 mr-3" onClick={() => setShowImportOptions(false)}>
                      Generate new key
                    </button>
                  </div>
                </>
              :
              <>
                <div className="col-span-12 row-span-1 mb-5">
                  <p className="subtitle">To ensure secure file transfers with other users, you will need to generate a secret key for encrypting and decrypting files.</p>
                  <br />
                  <p className="subtitle">This generated secret key will be stored only on your device, so it is crucial to keep it safe.</p>
                  <div className="col-span-12 row-span-2 info-wrap mt-5">
                    <button className="btn-primary mr-3" disabled={processing} onClick={() => setShowImportOptions(true)}>
                      Import key
                    </button>
                    <button className="btn-primary" disabled={processing} onClick={() => generateKeyPair()}>
                      Generate key
                    </button>
                  </div>
                </div>
              </>
            :
            <>
              <div className="col-span-12 row-span-2">
                <p className="title mb-5">{!keyUploaded ? 'Your encryption key has been generated!' : 'You have succesfully uploaded your encryption key!'}</p>
              </div>
              <div className="col-span-12 row-span-3 grid grid-flow-row">
                <p className="content">{!keyUploaded ? 'Once you click "Finish," the key will be securely stored on your device. We don\'t keep copies of your key, so be careful not to lose it!' : 'To proceed, select one of the following options below.'}</p>
              </div>
              <div className="col-span-12 row-span-4 mt-5 info-wrap">
                {
                  !keyUploaded &&
                    <>
                      <button className="btn-primary mb-3 mr-3" onClick={() => downloadKey()}>
                        Download key
                      </button>
                      <button className="btn-primary mb-3 mr-3" onClick={() => copyToClipBoard()}>
                        Copy key
                      </button> 
                    </>
                }
                <button className="btn-primary mb-3" onClick={() => finishProcess()}>
                  Finish
                </button>
              </div>
            </>
          }
        </DialogBody>
      </ModalWrapper>
    </Dialog>
  )
}