import nacl from "tweetnacl";
import { decryptMessage } from "./encryption";

export const loginWithMachina = async () => {
  const credentials = 'eyJhY2NvdW50IjoiZXlKcFpDSTZJbUZ0WlhOMGNtOTJMblJsYzNSdVpYUWlMQ0p1YjI1alpTSTZJakUxT1RJek1EY3pPVEF3TURBd01DSjkiLCJwdWJsaWNLZXkiOiJaV1F5TlRVeE9UcElSVTFUVXpsWlUzVklPV28zU0c1VFpHZDVSRE5qYVhaMWVsUllRVWRXU204emVXdHVOR1psY3pKM2RBPT0iLCJzaWduYXR1cmUiOiJjOUNPNHpyQ1VtZmFPbmhJVGd3VWdyMnExZ0hnU3FzNFI0RzYyODQraUg1cU45ZVVrUHJIdGttT1pnUGgvR3dnWVlVaW5WY2JaOXlpRWM3Y01YOFRCZz09In0=';
  const accountId = 'amestrov.testnet';
  const response = await fetch('https://auth.testnet.onmachina.io/auth/v1', {
    method: 'GET',
    headers: {
      'x-auth-user': accountId,
      'x-auth-key': credentials,
    },
  });

  // Retrieve the x-auth-token
  const x_auth_token = response.headers.get('x-auth-token');
  // console.log('x_auth_token', x_auth_token);
  return x_auth_token;
}

export const createContainer = async (token, username) => {
  const containerName = username.toLowerCase().replace(/[\W_]+/g, '-');
  const res = await fetch('https://api.testnet.onmachina.io/v1/amestrov.testnet/' + containerName, {
    method: 'PUT',
    headers: {
      'x-auth-token': token,
    },
  });

  // console.log('res', res);
  if (!res.ok) throw res;
  return containerName;
}

export const uploadFileToMachina  = async (file, username, fileId) => {
  const token = await loginWithMachina();
  const container = await createContainer(token ,username);
  // console.log('uploading file to Machina', file);
  
  const res = await fetch(`https://api.testnet.onmachina.io/v1/amestrov.testnet/${container}/${fileId}`, {
    // Your POST endpoint
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'x-auth-token': token,
      'X-Object-Meta-Description': file.type + '|' + username + '|' + fileId + '|' + file.size + '|' + file.name,
    },
    body: file,
  });
  // console.log('res', res);
  if (!res.ok) throw res;
  return fileId;
};

export const deleteFileFromMachina = async (username, fileId) => {
  try {
    const token = await loginWithMachina();
    const containerName = username.toLowerCase().replace(/[\W_]+/g, '-');;
    const res = await fetch(`https://api.testnet.onmachina.io/v1/amestrov.testnet/${containerName}/${fileId}`, {
      method: 'DELETE',
      headers: {
        'x-auth-token': token,
      },
    });
    if (!res.ok) throw res;
    return { ok: true };
  }catch (error) {
    console.log('error', error);
    return { ok: false };
  }
  
}

export const getonMachinaMetadata = async (fileId, username) => {
  const token = await loginWithMachina();
  const container = await createContainer(token, username);
  const response = await fetch(`https://api.testnet.onmachina.io/v1/amestrov.testnet/${container}/${fileId}`, {
    method: 'HEAD',
    headers: {
      'x-auth-token': token,
    },
  });
  const metadata = response.headers.get('x-object-meta-description');
  return metadata;
}

export const downloadFromOnMachina = async (fileId, username, setDownloads = null, anonymous) => {
  const token = await loginWithMachina();
  const container = await createContainer(token, username);
  let progressRes = {};
  const response = await fetch(`https://api.testnet.onmachina.io/v1/amestrov.testnet/${container}/${fileId}`, {
    method: 'GET',
    headers: {
      'x-auth-token': token,
    },
  });
  const metadata = response.headers.get('x-object-meta-description');

  console.log('metadata', metadata);

  const reader = response.body.getReader();

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  const contentLength = response.headers.get('content-length');
  let total = parseInt(contentLength, 10);
  if(!contentLength) {
    total = metadata.description.split('|')[3];
  }
  
  let loaded = 0;
  const chunks = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    chunks.push(value);
    loaded += value.length;
    let currentProgress = Math.round((loaded / total) * 100);
    let progress = {};
    progress['fileId'] = fileId;
    progress['fileName'] = metadata.split('|')[2];
    progress['percentage'] = currentProgress;
    progress['downloading'] = true;
    progress['verified'] = false;
    progress['anonymous'] = anonymous;
    progress['user'] = metadata.split('|')[1];
    progress['statusMessage'] = 'Downloading...';
    progressRes = progress;
  
    if(setDownloads) {
      setDownloads((prevState) => {
        let newState = [...prevState];
        if(newState.length > 0) {
          const index = newState.findIndex(object => object.fileId === progress.fileId);
          if (index === -1) {
            newState = [...prevState, progress];
          } else {
            newState[index] = progress;
          }
        }else {
          newState = [...prevState, progress];
        }
        return [...newState];
      });
    }
  }

  const blob = new Blob(chunks);

  const file = new File([blob], metadata.split('|')[4], { type: metadata.split('|')[0] });
  console.log('DOWNLOADED file WITH PROGRESS', file);
  console.log('progressRes', progressRes);
  return { file, progressRes };

}