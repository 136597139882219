import styled from "styled-components";

import choiceBackground from "../../assets/images/choice-background.png";

const ChoiceDiv = styled.div`
  padding-top: 125px;
  padding-bottom: 125px;
  background: linear-gradient(180deg, #042C25 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #042C25 100%), url(${choiceBackground});
  background-size: cover;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .content-wrap {
    border-radius: 24px;
    background: rgba(28, 33, 41, 0.60);
    backdrop-filter: blur(10px);
    padding: 32px 16px;

    @media (max-width: 768px) {
      padding: 32px;
    }

    .info-title {
      color: #FFF;
      text-align: center;
      font-family: Nexa;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 26px;
      }
        
    }

    .info-content {
      color: #FFF;
      text-align: center;
      font-family: Nexa;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 18px;
        text-align: left;
      }

      b {
        font-weight: 800;
      }
    }
  }
`;

export default function Choice() {

  return (
    <ChoiceDiv>
      <div className="container mx-auto grid grid-cols-12 gap-3">
        <div className="col-span-12 md:col-span-8 md:col-start-3 row-start-1 content-wrap">
          <p className="info-title">Is Pathfinder for me?</p>
          <p className="info-content">
            If you care about your <b>privacy - yes</b>
          </p>
          <p className="info-content">
            If you care about controlling your <b>data - yes</b>
          </p>
          <p className="info-content">
            If your company has strict data sharing <b>policies - yes</b>
          </p>
        </div>
      </div>
    </ChoiceDiv>
  );
}