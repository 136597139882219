import styled from "styled-components";
import downloadIcon from "../assets/images/download-icon.png";
import { Fragment, useEffect, useState } from "react";
import { useAnonymousTransferFileContext } from "../context/AnonymousTransferFileProvider";
import { formatBytes } from "../inc/functions";
import { saveAs } from 'file-saver';
import AnonymousEmailModal from "../modals/AnonymousEmailModal";
import WalletSelector from "../modals/WalletSelector";
import arrowIcon from "../assets/images/arrow-icon.png";
import { useParams } from "react-router-dom";

const SectionWrapper = styled.div`
  padding: 24px 24px 24px 16px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(38px);

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .title {
      color: #FFFFFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;

    .text {
      color: #F1F3F5;
      font-family: Nexa;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%; 
      margin-bottom: 8px;
    }

    .input {
      width: 100%;
      padding: 12px 16px;
      border-radius: 8px;
      background: #FFF;
      gap: 8px;
    }
  }

  .progress-bar-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;

  
    .progress-bar {
      width: 100%;
      height: 10px;
      border-radius: 24px;
      background: var(--Bg_6, #404B51);

    }
  
    .percentage {
      color: rgba(255, 255, 255, 0.90);
      font-family: Nexa;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      margin-left: 5px !important;
    }
  }

  .file-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;

    .file-name {
      color: rgba(255, 255, 255, 0.90);
      font-family: Nexa;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }
    .file-owner {
      color: var(--Gray_2, #C0CDCF);
      font-family: Nexa;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .file-size {
      color: rgba(255, 255, 255, 0.90);
      font-family: Nexa;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%; /* 18.6px */
    }
  }

  .hash-form {
    width: 100%;
  }

  .transfer-status {
    color: rgba(255, 255, 255, 0.90);
    font-family: Nexa;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .status-message {
    color: var(--Red_3, #FA9C9C);
    font-family: Nexa;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-top: 8px;
  }

  .login-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
    margin-top: 24px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .title {
      color: #FFFFFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
    }
  }
`;

export default function AnonymousDownload() {
  
  const [fileId, setFileId] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [ fileHash, setFileHash ] = useState(null);

  const [openWalletSelector, setOpenWalletSelector] = useState(false);
  
  //use this if URL was inserted in the browser
  const { fileHashURL } = useParams();

  const { anonymousFile } = useAnonymousTransferFileContext();

  const addFile = async (e) => {
    e.preventDefault();
    if(!fileId) {
      setStatusMessage('Please enter a file URL');
      return;
    }
    console.log('FILE ID: ', fileId);

    const hash = fileId.split(process.env.REACT_APP_SHARE_URL + '/')[1];
    console.log(hash);

    if(hash) {
      fetch(process.env.REACT_APP_SERVER_URL + '/users/check-file-encryption',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({hash: hash})
        }).then((res) => {
          return res.json();
        }).then(async (res) => {
          setFileHash(hash);
          setOpenEmailModal(true);          
        });
    }else {
      setStatusMessage('Invalid URL');
    }
  };

  // console.log('fileHashURL', fileHashURL, fileHashURL.length)
  useEffect(() => {
    if(fileHashURL && fileHashURL.length === 64) {
      setFileHash(fileHashURL)
      setOpenEmailModal(true);
    }
  }, []);

  const fillerStyles = {
    height: '100%',
    width: `${anonymousFile.length > 0 ? anonymousFile[0].percentage : 0}%`,
    background: '#06C5C5',
    borderRadius: '24px',

  }

  const downloadFile = (file) => {
    saveAs(file, file.name);
  };

  return (
      <SectionWrapper>
        {
          openEmailModal && (
            <AnonymousEmailModal handleClose={() => setOpenEmailModal(false)} fileHash={fileHash} />
          )
        }
        {
          openWalletSelector && <WalletSelector handleClose={() => setOpenWalletSelector(false)}/>
        }
        <div className="title-wrapper">
          <img className="icon" src={downloadIcon} alt="download-icon" />
          <h3 className="title">Download file</h3>
        </div>
        <div className="content-wrapper">
          <p className="text">Received Pathfinder link? Paste link here:</p>
          <form className="hash-form" onSubmit={(e) => addFile(e)}>
            <input className="input" type="text" placeholder="https://pathfinderapp.xyz/12312312313" value={fileId} onChange={e => setFileId(e.target.value)} />
            <input type="submit" hidden />
          </form>
          <p className="status-message">{statusMessage}</p>
        </div>
        <button className="btn-primary" onClick={(e) => addFile(e)} >Download</button>

        {
          anonymousFile.length > 0 && (
            <Fragment>
              <div className="file-wrap">
                <div className="file-info-wrap">
                  <p className="file-name">{anonymousFile[0].fileName}</p>
                  <p className="file-owner">{anonymousFile[0].user}</p>
                </div>
                {
                  anonymousFile[0].file && (
                    <p className="file-size">{formatBytes(anonymousFile[0].file.size)}</p>
                  )
                }
                <div className="btn-wrap">
                  {
                    anonymousFile[0].percentage !== 100 ? 
                      (
                        <p className="transfer-status">Transfering file...</p>
                      )
                    :
                      <button className="btn-secondary" onClick={() => downloadFile(anonymousFile[0].file)}>Save as</button>
                  }
                </div>
              </div>
              <div className="progress-bar-wrap">
                <div className="progress-bar">
                  <p style={fillerStyles}></p>
                </div>
                <p className="percentage">
                  {`${anonymousFile[0].percentage}%`}
                </p>
              </div>
            </Fragment>
          )
        }
      <div className="login-wrapper">
        <img className="icon" src={arrowIcon} alt="download-icon" />
        <h3 className="title">Do you want to Send Files?</h3>
      </div>
      <button className="btn-primary" onClick={() => setOpenWalletSelector(true)} >Login/Register</button>
      </SectionWrapper>
      
      
  );
}