import React, { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { markPdf } from '../../inc/functions';
import { Dialog, DialogBody } from '@material-tailwind/react';
import cancelButton from '../../assets/images/cancel-x.png';
import { blurPage, disablePrint, disableRightClick, toBlur, toClear } from '../../inc/viewerFunctions';
import styled from 'styled-components';


const ViewerWrapper = styled.div`

  .viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--BG_8, #283134);
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #FFF;

    .close-btn {
      width: 24px;
      height: 24px;
    }
  }

  .react-pdf__Document {
    max-height: 700px;
    overflow: auto;
  }

  #image-img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export default function PdfViewer({file, handleClose}) {

  const [fileView, setViewFile] = useState([]);
  const [fileTitle, setFileTitle] = useState('');

  useEffect(() => { 
    disableRightClick();
    disablePrint();
    blurPage();
    console.log('file', file);
    console.log('file.name', file.name)
    // console.log('file uri', URL.createObjectURL(file));
    if(file.file.type === 'application/pdf') {
      markPdf(file.file, file.watermarkPermissions, file.trackPermissions, file.fileOwner).then((pdf) => {
        setFileTitle(file.file.name);
        setViewFile([{ uri: URL.createObjectURL(pdf), fileName: file.file.name}]);
      });
    }else {
      setFileTitle(file.file.name);
      setViewFile([{ uri: URL.createObjectURL(file.file), fileName: file.file.name}]);
    }
    
  }, []);



  return (
    <Dialog open={true} size="lg" className="bg-transparent shadow-none file-viewer" onMouseEnter={() => toClear()} onMouseLeave={() => toBlur()}>
      <ViewerWrapper>
        <DialogBody>
          <div className="viewer-header">
            <p className="file-title">{fileTitle}</p>
            <button className="close-btn" onClick={() => handleClose()} >
              <img className="cancel-icon" src={cancelButton} alt="cancel button" />
            </button>
          </div>
          {
            fileView && fileView.length > 0 && <DocViewer documents={fileView} pluginRenderers={DocViewerRenderers} config={{
              header: {
                disableHeader: true,
                disableFileName: false,
                retainURLParams: true,
              },
              csvDelimiter: ",", // "," as default,
              pdfZoom: {
                defaultZoom: 1, // 1 as default,
                zoomJump: 0.2, // 0.1 as default,
              },
              pdfVerticalScrollByDefault: true, // false as default
            }} />
          }
        </DialogBody>
        </ViewerWrapper>
      </Dialog>
  );
}