import styled from "styled-components";
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import CurrentDownloads from "./current-transfers/CurrentDownloads";
import CurrentActiveTransfers from "./current-transfers/CurrentActiveTransfers";

const CurrentTransfersWrap = styled.div`
  .tab-header {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .underline-border {
    border-color: #D9D9D9;
  }

  .title {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      padding-left: 12px;
    }
  }

  .tabs-wrapper {

    @media (min-width: 768px) {
      max-width: 50%;
    }
  }

`;


export default function CurrentTransfers({handleClose}) {

  return (
    <CurrentTransfersWrap>
      <h2 className="title row-start-1">Current transfers</h2>
      <CurrentActiveTransfers />
    </CurrentTransfersWrap>
  )
}