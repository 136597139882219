import styled from "styled-components";

import infographic1 from "../../assets/images/infographic1.png";
import infographic2 from "../../assets/images/infographic2.png";


const InfographicDiv = styled.div`
  padding-bottom: 100px;
  background: var(--Dark_green, #042C25);

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }

  .section-title {
    color: #FFF;
    text-align: center;
    font-family: Nexa;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 56px;
  }

  .first-row {
    margin-bottom: 40px;
  }

  .infographic-wrap {

    @media (max-width: 768px) {
      margin-bottom: 24px;
    }

    .infographic-img {
      max-width: 40px;
      height: auto;
      margin: auto;
    }

    .infographic-title {
      color: #FFF;
      font-family: Nexa;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px;
      padding-bottom: 6px;

      @media (max-width: 768px) {
        font-size: 15px;
        font-weight: 400;
      }
    }

    .infographic-subtitle {
      color: #FFF;
      font-family: Nexa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 768px) {
        font-size: 20px;
        font-weight: 800;
      }
    }
  }
`;

export default function Infographic() {

  return (
    <InfographicDiv>
     <div className="container mx-auto grid grid-cols-12 gap-3">
          <div className="col-span-8 col-start-3 md:col-span-6 md:col-start-4 row-start-1">
            <h2 className="section-title">Why decentralised file sharing?</h2>
          </div>
          <div className="row-start-2 grid grid-cols-12 col-span-12 gap-3">
            <div className="col-span-12 md:col-span-4 infographic-wrap grid grid-cols-4 gap-3">
              <div className="col-span-4 infographic-wrap grid grid-cols-4 row-start-1 first-row">
                <img src={infographic2} alt="Infographic" className="infographic-img col-span-1" />
                <div className="col-span-3">
                  <h3 className="infographic-title">WEB2 File sharing</h3>
                  <p className="infographic-subtitle">Storing the information on the sharing service servers</p>
                </div>
              </div>
              <div className="col-span-4 infographic-wrap grid grid-cols-4 row-start-2">
                <img src={infographic1} alt="Infographic" className="infographic-img col-span-1" />
                <div className="col-span-3">
                  <h3 className="infographic-title">WEB3 Pathfinder file sharing</h3>
                  <p className="infographic-subtitle">Your data remain yours</p>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 infographic-wrap grid grid-cols-4">
              <div className="col-span-4 infographic-wrap grid grid-cols-4 row-start-1 first-row">
                <img src={infographic2} alt="Infographic" className="infographic-img col-span-1" />
                <div className="col-span-3">
                  <h3 className="infographic-title">WEB2 File sharing</h3>
                  <p className="infographic-subtitle">Vendor has access and control over sender’s uploaded files</p>
                </div>
              </div>
              <div className="col-span-4 infographic-wrap grid grid-cols-4 row-start-2">
                <img src={infographic1} alt="Infographic" className="infographic-img col-span-1" />
                <div className="col-span-3">
                  <h3 className="infographic-title">WEB3 Pathfinder file sharing</h3>
                  <p className="infographic-subtitle">You and only you decide who gets access to the file </p>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 infographic-wrap grid grid-cols-4">
              <div className="col-span-4 infographic-wrap grid grid-cols-4 row-start-1 first-row">
                <img src={infographic2} alt="Infographic" className="infographic-img col-span-1" />
                <div className="col-span-3">
                  <h3 className="infographic-title">WEB2 File sharing</h3>
                  <p className="infographic-subtitle">There is no additional file encryption security</p>
                </div>
              </div>
              <div className="col-span-4 infographic-wrap grid grid-cols-4 row-start-2">
                <img src={infographic1} alt="Infographic" className="infographic-img col-span-1" />
                <div className="col-span-3">
                  <h3 className="infographic-title">WEB3 Pathfinder file sharing</h3>
                  <p className="infographic-subtitle">Sharing is fully encrypted</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </InfographicDiv>
  );
}