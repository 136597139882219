import styled from "styled-components";
import { useUserFilesContext } from "../../../context/UserFilesProvider";
import { officeRestrictions, rawFormats } from "../../../inc/constants";
import { Fragment, useState } from "react";
import cancelButton from '../../../assets/images/cancel-x.png';
import { useGlobalContext } from "../../../context/GlobalProvider";
import { decryptMessage } from "../../../inc/encryption";
import { db } from "../../../inc/database";
import { markPdf, sendMetrics } from "../../../inc/functions";
import { Typography } from "@material-tailwind/react";
import PdfViewer from "../../viewers/PdfViewer";
import { saveAs } from "file-saver";
import VideoViewer from "../../viewers/VideoViewer";
import ModelViewer from "../../viewers/ModelViewer";
import ExcelViewer from "excel-viewer";

const CurrentTransfersWrap = styled.div`
   .tab-header {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
   }

   .border-bottom {
      height: 1px;
      background: #D9D9D9;
   }

   .title {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    margin-bottom: 32px;
  }

  .progress-bar-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;

  
    .progress-bar {
      width: 100%;
      height: 10px;
      border-radius: 24px;
      background: var(--Bg_6, #404B51);

    }
  
    .percentage {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;
      display: flex;
      align-items: center;
      margin-left: 5px !important;
    }
  }

  table {
    .table-header {
      color: #C0CDCF;
      font-family: Open Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 8px 12px 0 0;
      padding-bottom: 8px;
    }

    tr {
      
      td {
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;
        padding: 8px;

        .file-info-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          color: rgba(255, 255, 255, 0.90);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 155%;
          
          .owner {
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-primary { 
          margin-right: 16px
        }
      }
    }
  }

  .no-transactions {
      color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .close-btn {
    width: 24px;
    height: 24px;
  }
`;

export default function CurrentDownloads({}) {

  const { downloads, setDownloads, peerInstanceReceiver, peerInstanceHost } = useUserFilesContext();

  const { socket, myUsername } = useGlobalContext();

  const [currentViewingFile, setCurrentViewingFile] = useState(null);
  const [openFileViewer, setOpenFileViewer] = useState(false);

  

  const cancelTransfer = (isDownloading, username, fileId) => {
    console.log('cancelTransfer', isDownloading, username, fileId);
    socket.emit('cancel_download', {username: username, isDownloading: isDownloading, myUsername: myUsername, fileId: fileId});
    if(isDownloading) {
      //CHECK IF TRANSFER ALREADY STARTED
      if(peerInstanceReceiver.current[fileId]) {
        console.log('destroying peer');
        peerInstanceReceiver.current[fileId].destroy();
      }
    }else {
      //CHECK IF TRANSFER ALREADY STARTED
      if(peerInstanceHost.current[username + fileId]) {
        peerInstanceHost.current[username + fileId].destroy();
      }
    }
    //TODO Change currentPercentage to filter by FILE ID
    setDownloads((prevState) => {
      return prevState.filter(el => el.fileId !== fileId);
    });
  };

  const downloadFileSolo = (file) => {
    saveAs(file.file, file.file.name);
  };

  const removeFile = async (fileId) => {
    console.log('removeFile', fileId);
    //Check if anonymous download
    if(myUsername) {
      await db.downloadedFiles.where({fileId: fileId}).delete();
    }
    
    setDownloads((prevState) => {
      console.log('prevState', prevState);
      const newState = prevState.filter( el => {
        return el.fileId !== fileId;
      })
      console.log('newState', newState);
      return [...newState];
    });
  };

  const viewFile = async (file) => {
    console.log('OPEN FILE viewFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(file.file, file.fileName, myUsername);
    }

    let permissions = ['view'];
    if(file.watermarkPermissions) {
      permissions.push('watermark');
    }
    const data = {
      name: fileData.fileName,
      size: fileData.file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.fileOwner,
      date: new Date().getTime(),
      permissions: permissions,
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('file');
  };

  const viewVideoFile = async (file) => {
    console.log('OPEN FILE viewFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    let permissions = ['view'];
    if(fileData.watermarkPermissions) {
      permissions.push('watermark');
    }
    const data = {
      name: fileData.fileName,
      size: file.file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.fileOwner,
      date: new Date().getTime(),
      permissions: permissions,
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('file');
  };

  const viewModelFile = async (file) => {
    console.log('OPEN FILE ModelFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    let permissions = ['view'];
    if(fileData.watermarkPermissions) {
      permissions.push('watermark');
    }
    const data = {
      name: fileData.fileName,
      size: file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.fileOwner,
      date: new Date().getTime(),
      permissions: permissions,
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('model');
  };

  const viewExcelFile = async (file) => {
    console.log('OPEN FILE viewExcelFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    const data = {
      name: fileData.fileName,
      size: file.size,
      sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
      sharedBy: fileData.fileOwner,
      date: new Date().getTime(),
      permissions: ['view'],
      transferType: '/',
      action: 'View'
    }
    sendMetrics(data);

    setCurrentViewingFile(fileData)
    setOpenFileViewer('excel');
    // setExcelFile(rawFile);
    // setFileOwner(fileOwner);
    // setOpenExcelViewer(true);
  };

  const downloadFile = async (file) => {
    console.log('downloadFile', file);
    const fileData = {...file};
    //Check if anonymous download
    if(myUsername) {
      fileData.file = await decryptMessage(fileData.file, fileData.fileName, myUsername);
    }

    if(fileData.file.type === 'application/pdf') {
      markPdf(fileData.file, fileData.watermarkPermissions, fileData.trackPermissions, fileData.fileOwner).then((pdf) => {
        const data = {
          name: fileData.file.name,
          size: fileData.file.size,
          sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousUsername'),
          sharedBy: fileData.fileOwner,
          date: new Date().getTime(),
          permissions: ['raw'],
          transferType: '/',
          action: 'Download'
        }
        sendMetrics(data);
        //DOWNLOAD FILE
        saveAs(pdf, fileData.file.name);
      });
    }else {
      const data = {
        name: fileData.fileName,
        size: file.size,
        sharedTo: myUsername ? myUsername : localStorage.getItem('anonymousEmail'),
        sharedBy: fileData.fileOwner,
        date: new Date().getTime(),
        permissions: ['raw'],
        transferType: '/',
        action: 'Download'
      }
      sendMetrics(data);
      //DOWNLOAD FILE
      saveAs(fileData.file, fileData.fileName);
    }
  };

  const TABLE_HEAD = ["Name", "Search"];

console.log('downloads', downloads)
  return (
    <CurrentTransfersWrap>
      {
        openFileViewer === 'file' && <PdfViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
      {
        openFileViewer === 'video' && <VideoViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
      {
        openFileViewer === 'model' && <ModelViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
       {
        openFileViewer === 'excel' && <ExcelViewer file={currentViewingFile} handleClose={() => setOpenFileViewer(false)} />
      }
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
          {TABLE_HEAD.map((head, index) => (
              <th
                key={head}
                className=""
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="table-header"
                >
                  {head}{" "}
                  {/* {index !== TABLE_HEAD.length - 1 && (
                    <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                  )} */}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {
          downloads.length > 0 ?
            <Fragment>
            {
            downloads.map((progress, index) => {
              const username = progress['user'].length >= 32 ? progress['user'].slice(0,6) + '...' + progress['user'].slice(-4) : progress['user'];
              const percentage = progress['percentage'];
              const isDownloading = progress.downloading;
              const fileId = progress.fileId;
              const fillerStyles = {
                height: '100%',
                width: `${percentage}%`,
                background: '#06C5C5',
                borderRadius: '24px',

              }
              const fileExtension = progress.fileName.split('.').pop();
              let viewDisabled = false;
              if(rawFormats.includes(fileExtension)) {
                viewDisabled = true;
              }
              if(officeRestrictions.includes(fileExtension) && progress['rawPermissions']) {
                viewDisabled = true;
              }

              let isModelFile = false;
              if (progress['fileName'].split('.').pop() === 'obj' || progress['fileName'].split('.').pop() === 'glb') {
                isModelFile = true;
              }

              const trClass = index % 2 === 0 ? 'background-gray' : '';
              const classes = "p-4 ml-auto";

              return (
                <tr key={index} className={trClass}>
                  <td className={classes}>
                    <div className="file-info-wrap">
                      <p className="name">{ progress['fileName'].split('.').slice(0, -1).join('.') }</p>
                      {
                        percentage !== 100 ? 
                          <div className="progress-bar-wrap">
                            <div className="progress-bar">
                              <p style={fillerStyles}></p>
                            </div>
                            <p className="percentage">
                              {`${percentage}%`}
                            </p>
                          </div>
                        :
                          <p className="owner">{username}</p> 
                      }
                    </div>
                  </td>
                  <td className={classes}>
                    {
                      isDownloading ?
                        percentage === 100 ?
                          progress['verified'] ?
                            <Fragment>
                              <div className="btn-wrap">
                              {
                                progress['anonymous'] ?
                                    <div className="btn-wrap">
                                      <button onClick={() => downloadFileSolo(progress)} className="btn-primary">Download</button>
                                    </div>
                                  :
                                  <Fragment>
                                    {
                                      <Fragment>
                                          {
                                            !viewDisabled 
                                            && progress['file'].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                            && progress['file'].type.search('video') >= 0
                                            && !isModelFile
                                              && <button onClick={() => viewVideoFile(progress)} className="btn-primary">View</button>
                                          }
                                          {
                                            !viewDisabled 
                                            && progress['file'].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                            && isModelFile
                                              && <button onClick={() => viewModelFile(progress)} className="btn-primary">View</button>
                                          }
                                          {
                                            !viewDisabled 
                                            && progress['file'].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                            && progress['file'].type.search('video') < 0
                                            && !isModelFile
                                              && <button onClick={() => viewFile(progress)} className="btn-primary">View</button>
                                          }
                                          {
                                            !viewDisabled 
                                            && progress['file'].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                            && progress['file'].type.search('video') < 0
                                            && !isModelFile
                                              && <button onClick={() => viewExcelFile(progress['file'], progress['fileName'], progress['user'])} className="btn-primary">View</button>
                                          }
                                          {
                                            progress['rawPermissions'] &&
                                              <button onClick={() => downloadFile(progress)} className="btn-primary">Download</button>
                                          }
                                      </Fragment>
                                    }
                                  </Fragment>
                              }
                                <button className="close-btn" onClick={() => removeFile(fileId)} >
                                  <img className="cancel-icon" src={cancelButton} alt="remove button" />
                                </button>
                              </div>
                            </Fragment>
                          :
                          <Fragment>
                            <p className="verify-error">File couldn't be verified</p>
                            <button className="close-btn" onClick={() => removeFile(fileId)} >
                              <img className="cancel-icon" src={cancelButton} alt="remove button" />
                            </button>
                          </Fragment>
                        :
                        <div className="btn-wrap">
                          <button  onClick={() => cancelTransfer(isDownloading, username, fileId)} className="close-btn">
                            <img className="cancel-icon" src={cancelButton} alt="remove button" />
                          </button>
                        </div>
                      :
                      ''
                    }
                     
                    </td>
                </tr>
                );
              })
            }
            </Fragment>
            :
            <p className="no-transactions">Currently you don't have any downloaded files</p>
          }
        </tbody>
      </table>
    </CurrentTransfersWrap>
  )
}