import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw } from 'draft-js'
import React, { useEffect, useRef, useState } from 'react';

import { decryptMessage, downloadFileFromIPFS } from "../utility/functions";

import { saveAs } from 'file-saver';
// import { Accordion, Col, Row } from "react-bootstrap";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import Reply from "./Reply";
import styled from "styled-components";
import { downloadFromOnMachina } from "../../inc/storageOnMachina";

const EmailThreadWrap = styled.div`

.thread-single-wrap {
  padding: 0 16px;

  .thread-subject {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 30px !important;
    color: #FFF;
    margin-left: 0 !important;
  }

  .inbox-item {
    background: transparent !important;
    background-color: transparent !important;
    color: #FFF;
    border: none;
    padding-bottom: 10px;
  }
  .accordion-item {
    border-radius: unset !important;
  }

  .inbox-list-header {
    color: #FFF;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .inbox-list-item-sender {
      font-weight: 700;
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      margin-bottom: 5px !important;
    }

    .inbox-list-item-recipients {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #FFF;
      word-break: break-all;
    }

    .inbox-item-action-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      .inbox-list-item-date {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #FFF;
        margin-left: 15px !important;
      }

      .reply-btn,
      .attachment-icon {
        width: 20px;
        height: 20px;
        margin-left: 15px;
        color: #FFF;
      }
    }
  }

  .attachment-wrap {
    border-top: 1px solid #FFF;
    padding-top: 15px;

    .attachment-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #FFF;
      margin-bottom: 16px;
    }

    .file-list {
      list-style: none;
      text-align: left;
      padding-left: 0;
      cursor: pointer;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%; /* 18.6px */

        .download-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          color: #FFF;
        }
      }
    }
  }
 
  .inbox-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 30px;

    .selectall-input {
      width: 20px;
      height: 20px;
    }

    .refresh-btn {
      margin-left: 20px;
      cursor: pointer;

      .refresh-icon {
        text-decoration: none;
        height: 25px;
        width: 25px;
        color: #FFF;
      }
    }

    .back-btn {
      margin-left: 0;
      cursor: pointer;
    }
  } 

  .email-viewer {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
  }
}
`;


const ThreadSingle = (props) => {
  console.log('props THREAD', props.thread);
  const [activeKey, setActiveKey] = useState((props.thread.emails.length - 1));
  const [openReplyWindow, setOpenReplyWindow] = useState(false);

  const downloadAttachment = async (attachment, sender) => {
    console.log('attachment', attachment)
    let file;
    if(/[a-zA-Z]/g.test(attachment)) {
      file = await downloadFileFromIPFS(attachment);
    }else {
      file = await downloadFromOnMachina(attachment, sender);
      file = file.file;
    }
    const decryptedFile = await decryptMessage(file, file.name, window.accountId);

    console.log('file', decryptedFile);
    saveAs(decryptedFile, decryptedFile.name);
  }

  const backToHome = () => {
    props.setActiveThread(null);
  }

  const showReplyWindow = (e) => {
    e.stopPropagation();
    setOpenReplyWindow(!openReplyWindow)
  }

  const [open, setOpen] = useState((props.thread.emails.length - 1));
  const handleOpen = (value) => setOpen(open === value ? 0 : value)
  return (
    <EmailThreadWrap className="grid grid-cols-12 grid-flow-row">
      <div className="col-span-12 thread-single-wrap">
        <div className="row-start-1 grid grid-cols-12">
          <div className="col-span-12 md:col-span-6 lg:col-span-2" >
            <div className="inbox-toolbar">
              <a className="refresh-btn back-btn" onClick={() => backToHome()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                </svg>
              </a>
              <a className="refresh-btn">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="row-start-2">
          <h1 className="thread-subject">{props.thread.subject}</h1>
        </div>
          {
            props.thread && props.thread.emails.map((email, key) => {
              let editorState = EditorState.createEmpty();
              if(email.content) {
                editorState = EditorState.createWithContent(convertFromRaw(email.content));
              }
              
              console.log('email', email);
              let date = new Date(email.date);
              date = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ', ' + date.getHours() + ':' + date.getMinutes();
              const recipients = email.recipients.join(', ');
              return (
                <Accordion open={open === key} key={key} className="inbox-item row-start-3 col-span-12">
                  <AccordionHeader onClick={() => handleOpen(key)} >
                    <div className="inbox-list-header">
                      <div className="inbox-list-item-wrap">
                        <p className="inbox-list-item-sender">{email.sender}</p>
                        {
                          // activeKey !== key ? (
                          //   <p className="inbox-list-item-subject">{content}</p>
                          // )
                          // :
                          // (
                            <p className="inbox-list-item-recipients">to  {recipients}</p>
                          // )
                        }
                      </div>
                      <div className="inbox-item-action-wrap">
                        {
                          email.attachments.length > 0 && (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="attachment-icon">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                            </svg>
                          )
                        }
                        <p className="inbox-list-item-date">{date}</p>
                        <a href="#" className="reply-btn" onClick={(e) => showReplyWindow(e)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="reply-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody>
                    <Editor
                      toolbarClassName="toolbar-class"
                      editorClassName="email-viewer"
                      defaultEditorState={editorState}
                      readOnly={true}
                      toolbarHidden={true}
                    />
                    {
                      email.attachments.length > 0 && (
                        <div className="attachment-wrap">
                          <p className="attachment-text">Attachments:</p>
                          <div className="file-list-wrap">
                            <ul className="file-list">
                              {email.attachments.map((file) => (
                                <li key={file.name} onClick={() => downloadAttachment(file, email.sender)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="download-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                  </svg>
                                  {file.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )
                    }
                    {
                      openReplyWindow && <Reply thread={props.thread} encryptedThread={props.encryptedThread} email={email} />
                    }
                  </AccordionBody>
                </Accordion>
              );
            })
          }
      </div>
    </EmailThreadWrap>
  );
}

export default ThreadSingle;
