import Dexie from 'dexie';
import { createStore } from 'state-pool';

// import { NFTStorage } from 'nft.storage';

// import * as bs58 from 'bs58';
// import * as nacl from 'tweetnacl';
// nacl.util = require('tweetnacl-util');

export const peerConfig = {
  iceServers: [
    {
      urls: "stun:stun.relay.metered.ca:80",
    },
    {
      urls: "turn:a.relay.metered.ca:80",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
    {
      urls: "turn:a.relay.metered.ca:80?transport=tcp",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
    {
      urls: "turn:a.relay.metered.ca:443",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
    {
      urls: "turn:a.relay.metered.ca:443?transport=tcp",
      username: "51118a71a0a074939d8625ee",
      credential: "KXBjmyuEGthsfLOm",
    },
  ],
};


//INDEXED DB - DEXIE - BROWSER DATABASE
export const dbEmails = new Dexie('pathfinder-emails');

dbEmails.version(2).stores({
  readEmails: '++id, cid, status',
  drafts: '++id, subject, recipients, content, attachments, date',
});

export const globalState = createStore();

//SOCKET
globalState.setState('socket', null);


//CURRENT USER
globalState.setState('myUsername', localStorage.getItem('username'));

//NOTIFICATION BUBBLE FOR CURRENT DOWNLOADS/UPLOADS
globalState.setState('activeFiles', 0); 

//CURRENT DOWNLOADS/UPLOADS STATUS OBJECT
globalState.setState('currentPercentage', []);

globalState.setState('jwtToken', localStorage.getItem('jwtToken'));

//Anonimous user email address
globalState.setState('anonymousEmail', null);

//File cid for anonimous user
globalState.setState('anonymousFileCid', null);

//CURRENT FILES IN LOCAL DB
globalState.setState('myFiles', []);

//CURRENT SHARED FILES IN LOCAL DB
globalState.setState('currentSharedFiles', []);

globalState.setState('openProgressBar', false);

globalState.setState('openDownloadModal', false);

globalState.setState('fileDownload', null);

globalState.setState('progressBarData', {message: '', percentage: 0});

globalState.setState('myFilesArchive', []);

globalState.setState('myFilesDownloads', []);