import { NFTStorage } from 'nft.storage';
import { db } from './database';
import nacl from 'tweetnacl';
import { sendMetrics } from './functions';
import { downloadFromOnMachina } from './storageOnMachina';


const client = new NFTStorage({ token: process.env.REACT_APP_NFTSTORAGE_TOKEN })

export const uploadFileToIPFS = async (file, owner, fileId) => {
  console.log('uploading file to IPFS', file);
  const cid = await client.store({
    name: file.name,
    description: file.type + '|' + owner + '|' + fileId + '|' + file.size,
    image: new File(['<DATA>'], 'pinpie.jpg', { type: 'image/jpg' }),
    properties: {
      file: file,
    }
  });
  console.log('stored files with cid:', cid);
  return cid;
};

export const deleteFileFromIPFS = async (cid) => {
  try {
    const metadata = await getIPFSMetadata(cid);
    console.log('metadata', metadata);
    const res = await client.delete(cid);
    console.log('res', res);
    return res;
  }catch (error) {
    console.log('error', error);
    return;
  }
}

export const getIPFSMetadata = async (cid) => {

  const metadata = await fetch('https://nftstorage.link/ipfs/' + cid + '/metadata.json', {
    method: 'GET',
  }).then(function(res) {
    return res.json();
  }).then(function(response) {
    console.log('METADATA Response: ', response);
    return response;
  });

  const file_cid = metadata.properties.file.split("ipfs://").pop();

  console.log('file_cid', file_cid);
  metadata.file_cid = file_cid;

  return metadata;

};

export const downloadFileFromIPFS = async (cid, setDownloads = null, anonymous) => {
  const metadata = await getIPFSMetadata(cid);

  console.log('metadata', metadata);
  
  let progressRes = {};
  const response = await fetch('https://nftstorage.link/ipfs/' + metadata.file_cid);
  const reader = response.body.getReader();

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  const contentLength = response.headers.get('content-length');
  let total = parseInt(contentLength, 10);
  if(!contentLength) {
    total = metadata.description.split('|')[3];
  }
  
  let loaded = 0;
  const chunks = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    chunks.push(value);
    loaded += value.length;
    let currentProgress = Math.round((loaded / total) * 100);
    let progress = {};
    progress['fileId'] = cid;
    progress['fileName'] = metadata.name;
    progress['percentage'] = currentProgress;
    progress['downloading'] = true;
    progress['verified'] = false;
    progress['anonymous'] = anonymous;
    progress['user'] = metadata.description.split('|')[1];
    progress['statusMessage'] = 'Downloading...';
    progressRes = progress;
  
    if(setDownloads) {
      setDownloads((prevState) => {
        let newState = [...prevState];
        if(newState.length > 0) {
          const index = newState.findIndex(object => object.fileId === progress.fileId);
          if (index === -1) {
            newState = [...prevState, progress];
          } else {
            newState[index] = progress;
          }
        }else {
          newState = [...prevState, progress];
        }
        return [...newState];
      });
    }
  }

  const blob = new Blob(chunks);

  const file = new File([blob], metadata.name, { type: metadata.description.split('|')[0] });
  console.log('DOWNLOADED file WITH PROGRESS', file);
  console.log('progressRes', progressRes);
  return { file, progressRes };
}

export const downloadDirectlyFromIpfs = async (cid, setDownloads = null, availableFiles, myUsername, owner) => {
  
  console.log('ipfsDownload', cid)
  let resData;
  if(cid && /[a-zA-Z]/g.test(cid)) {
    resData = await downloadFileFromIPFS(cid, setDownloads, false);
  }else if(cid) {
    resData = await downloadFromOnMachina(cid, owner, setDownloads, false);    
  }
  const {file, progressRes} = resData;
  console.log('currentSharedFiles', availableFiles);
  const fileData = availableFiles.filter(el => el.cid_pdf === progressRes.fileId || el.cid_private === progressRes.fileId);
  console.log('fileData', fileData);
  const progress = {...progressRes};
  progress.file = file;
  progress.fileName = file.name;
  progress.verified = true;
  progress.rawPermissions = fileData[0].rawPermissions;
  progress.readPermissions = fileData[0].readPermissions;
  progress.watermarkPermissions = fileData[0].watermarkPermissions;
  progress.statusMessage = 'Downloaded and Verified!';

  // console.log('progress', progress);

  const fileExists = await db.downloadedFiles.get({fileId: progress.fileId});
  console.log('fileExists', fileExists);
  
  //SAVE FILE TO LOCAL DB SO THAT IT CAN BE ACCESS LATER
  if(fileExists) {  
    await db.downloadedFiles.update(fileExists.id, {
      file: file,
      fileName: file.name,
      size: file.size,
      owner: progress.user,
      fileId: progress.fileId,
      date: new Date().toISOString(),
      rawPermissions: progress.rawPermissions,
      readPermissions: progress.readPermissions,
      watermarkPermissions: progress.watermarkPermissions,
    });
  }else {
    await db.downloadedFiles.add({
      file: file,
      fileName: file.name,
      size: file.size,
      owner: progress.user,
      fileId: progress.fileId,
      date: new Date().toISOString(),
      rawPermissions: progress.rawPermissions,
      readPermissions: progress.readPermissions,
      watermarkPermissions: progress.watermarkPermissions,
    });
  }
  let metricPermissions = [];
  if(progress.rawPermissions) {
    metricPermissions.push('raw');
  }
  if(progress.readPermissions) {
    metricPermissions.push('read');
  }
  if(progress.watermarkPermissions) {
    metricPermissions.push('watermark');
  }
  const data = {
    name: progress['fileName'],
    size: file.size,
    sharedTo: myUsername,
    sharedBy: progress.user,
    date: new Date().getTime(),
    permissions: metricPermissions,
    transferType: 'IPFS',
    action: 'Transfer'
  }
  sendMetrics(data);
  
  setDownloads((prevState) => {
    let newState = [...prevState];
    if(newState.length > 0) {
      const index = newState.findIndex(object => object.fileId === progress.fileId);
      console.log('index', prevState[index]);
      if (index === -1) {
        newState = [...prevState, progress];
      } else {
        newState[index] = progress;
      }
    }
    console.log('newState', newState);
    return [...newState];
  });
};

export const downloadFileAnonymously = async (cid, setAnonymousFile, nonce, key, userEmail) => {
  let data;
  if(cid && /[a-zA-Z]/g.test(cid)) {
    data = await downloadFileFromIPFS(cid, setAnonymousFile, true);
  }else if(cid) {
    data = await downloadFromOnMachina(cid, userEmail.owner, setAnonymousFile, false);
  }
  const {file, progressRes} = data;
  
  const progress = {...progressRes};
  console.log('progress anon', progress);
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  const fileContent = await new Promise((resolve) => {
    reader.onload = () => resolve(reader.result);
  });
  const decryptedMessage = nacl.secretbox.open(new Uint8Array(fileContent), nacl.util.decodeBase64(nonce), nacl.util.decodeBase64(key));
  if(decryptedMessage) {
    progress.verified = true;
    const newFile = new File([decryptedMessage], file.name, {type: file.type});
    progress.file = newFile;
    setAnonymousFile((prevState) => {
      let newState = [...prevState];
      if(newState.length > 0) {
        const index = newState.findIndex(object => object.fileId === progress.fileId);
        if (index === -1) {
          newState = [...prevState, progress];
        } else {
          newState[index] = progress;
        }
      }
      return [...newState];
    });

    const data = {
      name: progress['fileName'],
      size: file.size,
      sharedTo: userEmail.email,
      sharedBy: progress.user,
      date: new Date().getTime(),
      permissions: ['raw'],
      transferType: 'IPFS',
      action: 'Transfer'
    }
    sendMetrics(data);
    return newFile;
  }else {
    console.log('could not decrypt file');
  }
}