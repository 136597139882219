import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useGlobalContext } from "./context/GlobalProvider";
import PageWrapper from "./layout/PageWrapper";
import { UserFilesProvider } from "./context/UserFilesProvider";

const Main = () => {
  const { isLoggedIn } = useGlobalContext();

  return (
    <div className={isLoggedIn ? "log-in-background" : ""}>
      <UserFilesProvider>
        <BrowserRouter basename="/">
          <Routes>
            {/* <Route path="/:fileHashURL?" render={(props) => <PageWrapper {...props} /> } /> */}
            <Route path="/:fileHashURL?" element={<PageWrapper />} />
          </Routes>
        </BrowserRouter>
      </UserFilesProvider>
    </div>
  );
}

export default Main;
