import React, { useEffect, useState } from 'react';
import { serverAuth } from '../utility/functions';

// import Compose from '../components/Compose';
import Inbox from '../components/Inbox';
import ThreadSingle from '../components/ThreadSingle';
// import Drafts from '../components/Drafts';
import styled from 'styled-components';
import Inbox2 from '../components/Inbox2';


const EmailArchiveWrap = styled.div`
  padding-top: 15px;

  .inbox-search {
    position: relative;

    .search-emails {
      display: flex;
      height: 44px;
      padding: 0px 15px 0px 30px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 8px;
      background: #EAF1FB;
      border: none;

      @media screen and (max-width: 820px) {
        width: 100%;
      }
    }

    .search-icon {
      position: absolute;
      top: 0;
      left: 5px;
      width: 20px;
      height: 100%;
    }
  }

  .inbox-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    padding-left: 10px;

    .inbox-toolbar-btns {
      display: flex;
      align-items: center;
      justify-content: center;

      .selectall-input {
        width: 20px;
        height: 20px;
      }

      .email-amount {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-left: 30px !important;
        color: #FFF;
      }

      .refresh-btn {
        margin-left: 20px;

        .refresh-icon {
          text-decoration: none;
          height: 25px;
          width: 25px;
          color: #FFF;
          cursor: pointer;
        }
      }
    }

  
  }

  .inbox-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    cursor: pointer;

    .inbox-list-item-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;

      @media screen and (max-width: 820px) {
        flex-direction: column;
      }

      .desktop {
        display: initial;
        @media screen and (max-width: 820px) {
          display: none;
        }
      }

      .inbox-list-item-mobile-wrap {
        @media screen and (max-width: 820px) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
        }
      }

      .mobile {
        display: none;
        @media screen and (max-width: 820px) {
          display: initial;
        }
      }
    }

    .selectitem-input {
      width: 20px;
      height: 20px;
    }

    .inbox-list-item-sender {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 30px !important;
      color: #FFF;

      @media screen and (max-width: 820px) {
        font-size: 15px;
        margin-left: 10px !important;
      }
    }

    .inbox-list-item-subject {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 30px !important;
      color: #FFF;

      @media screen and (max-width: 820px) {
        margin-left: 10px !important;
        margin-top: 10px !important;
        font-size: 15px;
        margin-right: auto !important;
      }
    }

    .inbox-list-item-date {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: auto !important;
      color: #FFF;

      @media screen and (max-width: 820px) {
        font-size: 15px;
      }
    }
  }
`;

const EmailArchive = ({ setCurrentActivePage }) => {

  //email state
  const [activeThread, setActiveThread] = useState(null);
  const [encryptedThread, setEncryptedThread] = useState(null);

  useEffect(() => {
    console.log('triggered main.jsx');
    localStorage.setItem('logedIn', false);
    if(localStorage.getItem('near-email_wallet_auth_key') && window.walletConnection.isSignedIn()) {
      localStorage.setItem('logedIn', true);
      serverAuth();
    }
  }, []);


  return (
    <EmailArchiveWrap>
      <button className="btn-secondary" onClick={() => setCurrentActivePage('compose')}>Compose</button>
        {
          activeThread ? (
            <ThreadSingle thread={activeThread} setActiveThread={setActiveThread} encryptedThread={encryptedThread} />
          ) 
            : 
          (
            <Inbox2 setActiveThread={setActiveThread} setEncryptedThread={setEncryptedThread} />    
          )
        }
    </EmailArchiveWrap>
  );
}

export default EmailArchive;
