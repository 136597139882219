import React, { useEffect, useState } from 'react';
import { decryptMessage, downloadFileFromIPFS, getIPFSMetadata, readJsonFile } from '../utility/functions';
// import Compose from './Compose';
// import EmailDetails from './ThreadSingle';
import { dbEmails } from '../utility/Utility';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/GlobalProvider';
import { decryptPrivateKey } from '../../inc/encryption';
import { downloadFromOnMachina } from '../../inc/storageOnMachina';


const InboxWrapper = styled.div`

  .unread-email {
    background: var(--Bg_07, #353C40);
    border-radius: 8px;

    .inbox-list-item-sender,
    .inbox-list-item-date,
    .inbox-list-item-subject {
      font-weight: 700 !important;
      line-height: 155% !important;
    }
  }

  .thread-wrap {
    margin-bottom: 8px;
  }

  .no-emails {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }
`;


export default function Inbox2({ setActiveThread, setEncryptedThread }) {

  const [paginationParams, setPaginationParams] = useState({page_size: 20, page_number: 1});

  const [tableData, setTableData] = useState([]);
  const [totalEmailNumber, setTotalEmailNumber] = useState(0);

  const [rawEmailThread, setRawEmailThread] = useState([]);
  const [checkedEmails, setCheckedEmails] = useState([]);

  const [fromToEmail, setFromToEmail] = useState({from: '', to: ''});

  const { setNewEmailCount } = useGlobalContext();

  const [loading, setLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const sentEmail = urlParams.get('send_email');
  const userRejected = urlParams.get('errorCode');

  useEffect(() => {
    if(sentEmail && !userRejected) {
      sendNotification(sentEmail);
      window.history.replaceState(null, '', window.location.pathname);
    }
    getEmailThreads();
  }, []);

  const getEmailThreads = async () => {
    //Get emails from smart contract
    const emailThreads = await window.emailContract.get_recieved_emails({account_id: window.accountId, pagination_params: paginationParams});
    console.log('emailThreads inbox', emailThreads);
    setTotalEmailNumber(emailThreads.total);

    // Set visual from to values
    let from;
    if(paginationParams.page_number === 1) {
      from = 0;
    }else {
      from = paginationParams.page_number * paginationParams.page_size;
    }
    const to = from + paginationParams.page_size;
    if(to > emailThreads.total) {
      setFromToEmail({from: from, to: emailThreads.total});
    }else {
      setFromToEmail({from: from, to: to});
    }

    //Get read emails from indexedDB
    const readEmails = await dbEmails.readEmails.toArray();
    let count = 0;

    //Set new email count
    const emailThreadsWithStatus = emailThreads.threads.map((thread) => {
      const matchingItem = readEmails.find(item => item.cid === thread.email && item.owner === window.accountId);
      if(matchingItem) {
        count++;
        return {...thread, status: matchingItem.status};
      }
      return thread;
    });

    setNewEmailCount(emailThreads.total - count);

    emailThreads.threads = emailThreadsWithStatus;

    console.log('emailThreadsWithStatus', emailThreads);
    const hostKeyPair = await decryptPrivateKey();
    for(let i = 0; i < emailThreads.threads.length; i++) {
      let emailContent;
      if(/[a-zA-Z]/g.test(emailThreads.threads[i].email)) {
        emailContent = await downloadFileFromIPFS(emailThreads.threads[i].email);
      }else {
        emailContent = await downloadFromOnMachina(emailThreads.threads[i].email, emailThreads.threads[i].sender);
        emailContent = emailContent.file;
      }
      console.log('emailContent', emailContent);
      if(!emailContent) {
        continue;
      }
      const decryptedEmailContent = await decryptMessage(emailContent, emailContent.name, window.accountId, false, hostKeyPair);
      if(decryptedEmailContent) {
        const rawEmailContent = await readJsonFile(decryptedEmailContent);
        rawEmailContent.status = emailThreads.threads[i].status;
        rawEmailContent.cid = emailThreads.threads[i].email;
        setRawEmailThread((prevEmails) => [...prevEmails, rawEmailContent]);
        
        let decryptedEmails = [];
        for(let j = 0; j < rawEmailContent.emails.length; j++) {
          const decryptedEmailUint8 = await decryptMessage(rawEmailContent.emails[j], rawEmailContent.emails[j].name, window.accountId, true, hostKeyPair);
          if(decryptedEmailUint8) {
            var decryptedEmail = new TextDecoder().decode(decryptedEmailUint8);
            decryptedEmail = JSON.parse(decryptedEmail);
            if(decryptedEmail.attachments.length > 0) {
              for(let k = 0; k < decryptedEmail.attachments.length; k++) {
                const attachment = await getIPFSMetadata(decryptedEmail.attachments[k]);
                if(attachment) {
                  decryptedEmail.attachments[k] = {cid: decryptedEmail.attachments[k], name: attachment.name};
                }
              }
            }
            decryptedEmails.push(decryptedEmail);
          }
        }
        const decryptedEmailThread = {...rawEmailContent};
        decryptedEmailThread.emails = decryptedEmails;
        setTableData((prevEmails) => {
          const unsorted = [...prevEmails, decryptedEmailThread];
          const sorted = unsorted.sort((a, b) => new Date(b.emails[b.emails.length-1].date) - new Date(a.emails[a.emails.length-1].date));
          return sorted;
        });
      }
    }
    setLoading(false);
    console.log('tableData AFTER ', tableData);
  }

  const selectEmails = (event, status, cid) => {
    console.log('event.target.checked', event.target.checked);
    if(event.target.checked) {
      setCheckedEmails((prevEmails) => [...prevEmails, {status: !status, cid: cid}]);
    }else {
      setCheckedEmails(checkedEmails.filter((item) => item.cid !== cid));
    }
  }

  const setEmailStatus = async () => {
    console.log('checkedEmails', checkedEmails);

    const currentEmails = await dbEmails.readEmails.toArray();

    let newEmails = checkedEmails.map((item) => {
      const matchingItem = currentEmails.find((email) => email.cid === item.cid);
      if(matchingItem) {
        return {...matchingItem, status: item.status, owner: window.accountId};
      }
      return {...item, owner: window.accountId};
    });

    await dbEmails.readEmails.bulkPut(newEmails);


    const updatedEmailsWithStatus = tableData.map((thread) => {
      console.log('thread', thread)
      const matchingItem = newEmails.find(item => item.cid === thread.cid);
      console.log('matchingItem', matchingItem);
      if(matchingItem) {
        return {...thread, status: matchingItem.status};
      }
      return thread;
    });
    console.log('updatedEmailsWithStatus', updatedEmailsWithStatus);
    setTableData(updatedEmailsWithStatus);
    setCheckedEmails([]);

    const readEmailCount = await dbEmails.readEmails.toArray();
    console.log('readEmailCount', readEmailCount);
    let count = 0;
    for(let i = 0; i < readEmailCount.length; i++) {
      if(readEmailCount[i].status === true && readEmailCount[i].owner === window.accountId) {
        count++;
      }
    }
    setNewEmailCount(totalEmailNumber - count);
  }

  const selectAllCheckboxes = (event) => {
    const status = event.target.checked;

    console.log('status', status);

    let emailIds = [];
    var allCheckboxes = document.getElementsByClassName("selectitem-input");
    for(let i=0; i< allCheckboxes.length; i++) {
      allCheckboxes[i].checked = status;

      const cid = allCheckboxes[i].getAttribute('data-cid');
      const threadStatus = allCheckboxes[i].getAttribute('data-status');
      const boolStatus = threadStatus === 'true' ? true : false;
      if(status) {
        emailIds.push({cid: cid, status: !boolStatus });
      }
    }

    setCheckedEmails(emailIds);
  }

  const setOpenThread = async (thread) => {
    console.log('thread', thread);
    const findEmail = await dbEmails.readEmails.get({cid: thread.cid, owner: window.accountId});
    console.log('findEmail', findEmail);
    if(!findEmail) {
      await dbEmails.readEmails.put({cid: thread.cid, status: true, owner: window.accountId});
    }else {
      await dbEmails.readEmails.where({cid: thread.cid}).modify({status: true});
    }
    setActiveThread(thread)
    setEncryptedThread(rawEmailThread.find((obj) => obj.cid === thread.cid));
  }

  const refreshEmails = () => {
    setTableData([]);
    setRawEmailThread([]);
    setPaginationParams({...paginationParams, page_number: 1});
    getEmailThreads();
  }

  const previousPage = () => {
    if(paginationParams.page_number === 1) {
      return;
    }
    setPaginationParams((prevParams) => {
      return {...prevParams, page_number: prevParams.page_number - 1}
    });
  }

  const nextPage = () => {
    if(paginationParams.page_number * paginationParams.page_size >= totalEmailNumber) {
      return;
    }
    setPaginationParams((prevParams) => {
      return {...prevParams, page_number: prevParams.page_number + 1}
    });
  }

  const sendNotification = async (users) => {
    const allUsers = users.split(',');
    if(allUsers[0] === 'true') {
      return;
    }
    const recipients = allUsers.filter((item) => item !== window.walletConnection.getAccountId());
    fetch(process.env.REACT_APP_SERVER_URL + '/users/send-notification-email',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
      },
      body: JSON.stringify({
        recipients: recipients,
        sender: window.walletConnection.getAccountId(),
      })
    }).then((res) => {
      return res.json();
    }).then(async (res) => {
      console.log('res', res)  
    });
  }

  return (
    <InboxWrapper className="grid grid-cols-12 grid-flow-row">
      <div className="xs:col-span-12 md:col-span-12 lg:col-span-12 col-span-12  inbox-wrap">
        <div className="row-start-1">
          <div className="col-span-12">
            <div className="inbox-search">
              {/* <input type="search" className="search-emails" name="s" placeholder="Search" value={searchValue} onChange={(event) => handleSearch(event)}/> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="search-icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="row-start-2">
          <div className="col-span-12">
            <div className='row-start-1 grid grid-cols-12 grid-flow-row'>
              <div className="col-span-12">
                <div className="inbox-toolbar">
                  <div className="inbox-toolbar-btns">
                    <input type="checkbox" name="select-all" className="selectall-input" onChange={(event) => selectAllCheckboxes(event)}/>
                    <a className="refresh-btn" onClick={() => refreshEmails()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                    </a>
                    {
                      checkedEmails && checkedEmails.length > 0 &&
                        <a className="refresh-btn" onClick={() => setEmailStatus()}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                          </svg>
                        </a>
                    }
                  </div>
                  <div className="inbox-toolbar-btns">
                    <p className="email-amount">{fromToEmail.from} - {fromToEmail.to} of {totalEmailNumber}</p>
                    <a className="refresh-btn" disabled={paginationParams.page_number === 1 ? true : false} onClick={() => previousPage()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                      </svg>
                    </a>
                    <a className="refresh-btn" disabled={paginationParams.page_number * paginationParams.page_size === totalEmailNumber ? true : false} onClick={() => nextPage()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="refresh-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {
          loading ?
            <div className="row-start-3">
              <div className="col-span-12">
                <div className="inbox-list-item">
                  <div className="inbox-list-item-wrap">
                    <div className="inbox-list-item-mobile-wrap">
                      <p className="inbox-list-item-sender">Loading...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            tableData.length > 0 ? 
              tableData.map((thread, key) => {
                let date = new Date(thread.emails[0].date);
                date = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
                let content = '';
                if(thread.emails[0].content.blocks) {
                  for(let i = 0; i < thread.emails[0].content.blocks.length; i++) {
                    content += thread.emails[0].content.blocks[i].text;
                  }
                }
                content = content.substring(0, 50) + '...';
                const readStyle = thread.status === true ? "thread-wrap" : "thread-wrap unread-email";

                const isChecked = checkedEmails.find((item) => item.cid === thread.cid);
                return (
                  <div className={readStyle} key={key}>
                    <div className="col-span-12">
                      <div className="inbox-list-item">
                        <input type="checkbox" name="select-item" checked={isChecked ? isChecked : false} className="selectitem-input" data-cid={thread.cid} data-status={thread.status} onChange={(event) => selectEmails(event, thread.status, thread.cid)} />
                        <div className="inbox-list-item-wrap grid grid-cols-12 grid-flow-row" onClick={() => setOpenThread(thread)}>
                          <div className="inbox-list-item-mobile-wrap col-span-6">
                            <p className="inbox-list-item-sender col-span-6">{thread.emails[0].sender} ({thread.emails.length})</p>
                            <p className="inbox-list-item-date mobile col-span-2">{date}</p>
                          </div>
                          <p className="inbox-list-item-subject">{thread.subject} - {content}</p>
                          <p className="inbox-list-item-date desktop">{date}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              :
              <p className="no-emails">Inbox is empty</p>
        }
      </div>
    </InboxWrapper>
  );
}